import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody } from 'reactstrap';

import TableContainer from 'components/common/TableContainer';
import { buildColumns } from '@modules/Contract/submodules/Files/components/Table/constants';
import { useMutation, useQuery } from '@tanstack/react-query';
import { create, getAll, deleteByID } from '@modules/Contract/submodules/Files/services';
import { IRouter } from '@apptypes/WithRouter';
import FileDropzone from '@component/common/FileDropzone';
import Spinners from '@component/common/Spinner';
import { CreateContractsFile } from '../types';
import { ColumnFiltersState } from '@tanstack/react-table';

interface ITransactions {
  router: IRouter;
  contractID?: string;
  clientID?: string;
  page: number;
  size: number;
  setSearchParams: (params: any) => void;
}

const ContractFiles = ({ contractID, clientID, page, size, setSearchParams }: ITransactions) => {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const [upload, setUpload] = useState<boolean>(false);
  const [uploadFile, setUploadFile] = useState<any>();
  const [file, setFile] = useState<any>();
  const { user } = useSelector((state: any) => state.Profile);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['transactions', contractID, page, size, filters],
    queryFn: () => getAll(contractID || '', page, size, filters),
    initialData: { items: [], totalItems: 0 },
    retry: 1,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (payload: CreateContractsFile) => create(payload),
    onSuccess: () => {
      toast.success('Upload realizado com sucesso!', {
        theme: 'colored',
        autoClose: 1500,
      });

      refetch();

      setUpload(false);
      setUploadFile(undefined);
    },
    onError: () => {
      toast.error('Não foi possível realizar o upload do arquivo!', {
        theme: 'colored',
        autoClose: 3000,
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: () => deleteByID(deleteFileID || ''),
    onSuccess: () => {
      toast.success('Contrato deletado com sucesso!', {
        theme: 'colored',
        autoClose: 1500,
      });
      refetch();
      setSearchParams({ page: '1' });
      setDeleteModal(false);
    },
  });

  const handleUpload = () => {
    mutate({
      contract_id: contractID,
      ...uploadFile,
    });
  };

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteFileID, setDeleteFileID] = useState<string | undefined>();

  const handleDeleteFileModal = (fileID: string) => {
    setDeleteFileID(fileID);
    setDeleteModal(true);
  };

  return (
    <div className="d-flex clients-content">
      <Helmet>
        <title>Arquivos do contrato | SGI</title>
      </Helmet>
      <TableContainer
        moduleName="contractsFiles"
        columns={buildColumns(user.is_admin, contractID || '', setFile, handleDeleteFileModal)}
        customPageSize={size}
        currentPage={page}
        data={data.items}
        loading={isFetching}
        totalItems={data.totalItems}
        deleteModal={{
          isOpen: deleteModal,
          loading: isFetching,
          onDeleteClick: deleteMutation.mutate,
          onCloseClick: () => setDeleteModal(false),
        }}
        handleFilters={setFilters}
        setSearchParams={setSearchParams}
        refeatch={refetch}
        isPagination
        isShowingPageLength
        isAddRegister
        isDelete
        handleAddRegisterClick={() => {
          setUpload(true);
          setUploadFile(undefined);
        }}
      />

      {upload && (
        <Modal isOpen={upload} toggle={() => setUpload(false)} centered={true}>
          <ModalBody className="px-4 py-5 text-center">
            <button
              type="button"
              onClick={() => setUpload(false)}
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button>

            {uploadFile ? (
              <>
                <div className="font-size-16">{uploadFile.name}</div>

                {isPending ? (
                  <div className="d-flex mt-4 flex-column align-items-center justify-content-center">
                    <Spinners />
                    <p className="mt-2">Realizando upload...</p>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center mt-4">
                    <Button color="danger" outline onClick={() => setUploadFile(undefined)}>
                      Cancelar
                    </Button>
                    <Button color="primary" className="ms-4" onClick={handleUpload}>
                      Realizar upload
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <FileDropzone allowedFiles="PDF" onFileDrop={setUploadFile} />
            )}
          </ModalBody>
        </Modal>
      )}

      {file && (
        <Modal className="pdf-modal" isOpen={file} toggle={() => setFile(null)} centered={true}>
          <ModalBody className="px-4 py-5 text-center">
            <button
              type="button"
              onClick={() => setFile(null)}
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button>
            <iframe title="pdf" src={file} style={{ height: '700px', width: '100%' }}></iframe>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default ContractFiles;
