import React from 'react';
import { Spinner } from 'reactstrap';

const Spinners = () => {
  return (
    <div className="spinner-block">
      <Spinner color="primary" className="top-50 start-50" />
    </div>
  );
};

export default Spinners;
