import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { Portuguese } from 'flatpickr/dist/l10n/pt';

import { IClientDocumentsCreate } from '@modules/Client/types';
import { getAll } from '@modules/Contract/submodules/Transactions/services';
import classNames from 'classnames';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';

interface IDetail {
  identificationNumber: string;
  clientName: string;
  clientDocument: IClientDocumentsCreate[];
  profitabilityGroupDescription: string;
  profitabilityGroupPercentage: string;
  partnerName: string;
  partnerID?: string;
  contractID?: string;
  recurringWithdrawal?: boolean;
  handleGenerateContract: (amount?: string, contractDate?: string) => void;
  generateContractPending: boolean;
}

export const Details = ({
  clientName,
  clientDocument,
  contractID,
  identificationNumber,
  partnerName,
  partnerID,
  profitabilityGroupDescription,
  profitabilityGroupPercentage,
  recurringWithdrawal,
  handleGenerateContract,
  generateContractPending,
}: IDetail) => {
  const [modal, setModal] = useState<boolean>(false);
  const { data: files } = useQuery({
    queryKey: ['buttonContractTransaction', contractID],
    queryFn: () => getAll(contractID || '', 1, 10),
    initialData: {
      items: [],
      totalItems: 0,
    },
  });

  const validation = useFormik({
    enableReinitialize: false,
    initialValues: {
      amount: '',
      contractDate: new Date(),
    },
    validationSchema: Yup.object({
      amount: Yup.string().required('Campo obrigatório'),
      contractDate: Yup.date().required('Campo obrigatório'),
    }),
    onSubmit: async ({ amount, contractDate }: { amount: string; contractDate: Date }) => {
      const date = moment(contractDate).format('YYYY-MM-DD');
      handleGenerateContract(amount, date);
      setModal(false);
      validation.resetForm();
    },
  });

  const handleClick = () => {
    if (files.totalItems > 0) {
      handleGenerateContract();
      return;
    }

    setModal(true);
  };

  function handleContractDate(date: any) {
    validation.setFieldValue('contractDate', moment(date[0]).format('YYYY-MM-DD'));
  }

  function handleAmountChange(data: any) {
    validation.setFieldValue('amount', data.value);
  }

  return (
    <Card className="hidden-print">
      <CardBody>
        <Row className="align-items-center">
          <Col lg={6}>
            <Badge pill color="primary" className="font-size-14">
              #{identificationNumber}
            </Badge>
            <span className="font-size-14 ms-2">{clientName} - </span>
            {clientDocument.length > 0 && (
              <>
                <span className="font-size-14 ms-1">{clientDocument[0].type}</span>
                <span className="font-size-14 ms-1">{clientDocument[0].value}</span>
              </>
            )}
            <span className="font-size-14 ms-1">- {profitabilityGroupDescription}</span>
            <span className="font-size-14 ms-1">{profitabilityGroupPercentage}%</span>

            {recurringWithdrawal && (
              <span className="recurring-withdrawal">
                <i className="mdi mdi-reload ms-2 icon-recurring-withdrawal"></i> Retirada
                Recorrente
              </span>
            )}
          </Col>

          <Col lg={6} className="align-items-center">
            <div className="d-flex justify-content-end align-items-center">
              {partnerName ? (
                <Link
                  to={`/contracts/${contractID}/link-partner/${partnerID}`}
                  className="font-size-14 text-primary"
                >
                  <strong>Acessor vinculado</strong>: {partnerName}
                </Link>
              ) : (
                <Link
                  to={`/contracts/${contractID}/link-partner`}
                  className="font-size-14 text-primary"
                >
                  <i className="mdi mdi-plus-circle-outline me-1"></i> Vincular acessor
                </Link>
              )}

              <Button
                type="button"
                size="sm"
                color="primary"
                className="ms-4"
                outline={generateContractPending}
                onClick={handleClick}
                disabled={generateContractPending}
              >
                {files.totalItems > 0 ? 'Reprocessar contrato' : 'Gerar contrato'}
              </Button>
            </div>
          </Col>
        </Row>
      </CardBody>

      <Modal isOpen={modal} toggle={() => setModal(false)} centered={true} size="md">
        <div className="modal-content">
          <button
            type="button"
            onClick={() => setModal(false)}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
          <ModalHeader>Gerar Contrato</ModalHeader>
          <ModalBody className="px-4 pb-4 pt-4 text-center">
            <Form
              className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}
            >
              <div className="d-flex flex-column">
                <FormGroup className="mb-4">
                  <Label className="col-form-label text-start col-lg-12">Valor</Label>
                  <Col lg={12}>
                    <InputGroup>
                      <NumericFormat
                        id="amount"
                        name="amount"
                        className="form-control"
                        decimalSeparator=","
                        thousandSeparator="."
                        placeholder="Digite um valor"
                        prefix="R$ "
                        value={validation.values.amount ? validation.values.amount : ''}
                        decimalScale={2}
                        onValueChange={handleAmountChange}
                      />
                    </InputGroup>
                    {validation.touched.amount && validation.errors.amount ? (
                      <div className="d-flex pt-2">
                        <p className="text-danger font-size-12 mb-0">{validation.errors.amount}</p>
                      </div>
                    ) : null}
                  </Col>
                </FormGroup>

                <FormGroup className="mb-4">
                  <Label className="col-form-label text-start col-lg-12">
                    Data da assinatura do contrato
                  </Label>
                  <Col lg={12}>
                    <Flatpickr
                      name="contractDate"
                      className={classNames(`form-control d-block`, {
                        'is-invalid': !!(
                          validation.touched.contractDate && validation.errors.contractDate
                        ),
                      })}
                      placeholder="Selecione uma data"
                      onChange={handleContractDate}
                      value={validation.values.contractDate || ''}
                      options={{
                        altInput: true,
                        altFormat: 'F j, Y',
                        dateFormat: 'Y-m-d',
                        locale: Portuguese,
                      }}
                    />
                    {validation.touched.contractDate && validation.errors.contractDate ? (
                      <FormFeedback type="invalid">
                        {validation.errors.contractDate as string}
                      </FormFeedback>
                    ) : null}
                  </Col>
                </FormGroup>
              </div>

              <div className="hstack gap-2 justify-content-center mb-0">
                <Button color="primary" type="submit">
                  Gerar contrato
                </Button>
                <Button color="danger" outline onClick={() => setModal(false)}>
                  Cancelar
                </Button>
              </div>
            </Form>
          </ModalBody>
        </div>
      </Modal>
    </Card>
  );
};
