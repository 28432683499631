import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import withRouter from 'components/common/withRouter';

import { useSelector } from 'react-redux';

const ProfileMenu = (props: any) => {
  const [menu, setMenu] = useState(false);
  const { user } = useSelector((state: any) => state.Profile);

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle
          className="btn header-item header-item__user"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="rounded-circle header-profile-user">
            {user.name ? user.name.charAt(0) : 'A'}
          </div>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href={`/users/${user.id}`}>
            {' '}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t('Meu Perfil')}{' '}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t('Logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(ProfileMenu));
