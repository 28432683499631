import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import TableContainer from 'components/common/TableContainer';
import { buildColumns } from '@modules/Partners/submodules/Payments/components/Table/constants';
import { useQuery } from '@tanstack/react-query';
import { getAll } from '@modules/Partners/submodules/Payments/services';
import { IRouter } from '@apptypes/WithRouter';
import { ColumnFiltersState } from '@tanstack/react-table';

interface IPartnerFiles {
  router: IRouter;
  partnerID?: string;
  page: number;
  size: number;
  setSearchParams: (params: any) => void;
}

const PartnerPayments = ({ partnerID, page, size, setSearchParams }: IPartnerFiles) => {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['payments', partnerID, page, size, filters],
    queryFn: () => getAll(partnerID || '', page, size, filters),
    initialData: { items: [], totalItems: 0 },
    retry: 1,
  });

  return (
    <div className="d-flex clients-content">
      <Helmet>
        <title>Arquivos do acessor | SGI</title>
      </Helmet>
      <TableContainer
        moduleName="partnersFiles"
        columns={buildColumns(partnerID || '')}
        customPageSize={size}
        currentPage={page}
        data={data.items}
        loading={isFetching}
        totalItems={data.totalItems}
        handleFilters={setFilters}
        setSearchParams={setSearchParams}
        refeatch={refetch}
        isPagination
        isShowingPageLength
      />
    </div>
  );
};

export default PartnerPayments;
