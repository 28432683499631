import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import withRouter from '@common/withRouter';
import { REDIRECT_TIMEOUT } from '@helpers/constants';
import { IPartnerCreate } from '@modules/Partners/types';
import PartnersForm from '@modules/Partners/components/PartnersForm';
import { create } from '@modules/Partners/services';
import { NAME_MANDATORY } from '@modules/Client/constants';
import { PERCENTAGE_MANDATORY } from '@modules/ProfitabilityGroup/constants';

const PartnersCreate = ({ router }: IWithRouter) => {
  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IPartnerCreate) => create(payload),
    onSuccess: () => {
      setTimeout(() => {
        router.navigate('/partners');
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      type: '',
      name: '',
      profession: '',
      gender: '',
      percentage: 0,
      active: true,
      observation: '',
    },
    validationSchema: Yup.object({
      type: Yup.string(),
      name: Yup.string().required(NAME_MANDATORY),
      profession: Yup.string(),
      gender: Yup.string(),
      percentage: Yup.number().required(PERCENTAGE_MANDATORY),
      active: Yup.string().required(),
      observation: Yup.string(),
    }),
    onSubmit: async (payload: IPartnerCreate) => mutate(payload),
  });

  return (
    <Page
      title="Cadastrar acessor"
      breadcrumb={{
        title: 'Acessores',
        titleHref: '/partners',
        breadcrumbItem: 'Novo Acessor',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Criar Novo Acessor</CardTitle>
              <div className="p-2 mt-3">
                <PartnersForm
                  loading={isPending}
                  validation={validation}
                  registrationError={error?.message}
                  showAlert={isSuccess}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(PartnersCreate);
