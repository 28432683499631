import { getWithExpiry, setWithExpiry } from '@helpers/localStorage';
import { IClientAdressesCreate, IClientBankDataCreate, IClientContactsCreate, IClientCreate, IClientDocumentsCreate } from '@modules/Client/types';
import { createSlice } from '@reduxjs/toolkit';

interface ClientRegisterItem<T> {
  isFinished: boolean;
  data?: T;
}

interface ClientRegisterState {
  stateLoaded: boolean;
  currentStep: number;
  lastStepFinished: number | null;
  CLIENT?: ClientRegisterItem<IClientCreate>;
  ADDRESSES?: ClientRegisterItem<IClientAdressesCreate[]>;
  BANK_DATAS?: ClientRegisterItem<IClientBankDataCreate[]>;
  CONTACTS?: ClientRegisterItem<IClientContactsCreate[]>;
  DOCUMENTS?: ClientRegisterItem<IClientDocumentsCreate[]>;
}

export const initState: ClientRegisterState = {
  stateLoaded: false,
  currentStep: 1,
  lastStepFinished: null,
  CLIENT: {
    isFinished: false,
    data: undefined,
  },
  DOCUMENTS: {
    isFinished: false,
    data: [],
  },
  CONTACTS: {
    isFinished: false,
    data: [],
  },
  ADDRESSES: {
    isFinished: false,
    data: [],
  },
  BANK_DATAS: {
    isFinished: false,
    data: [],
  },
};

const saveState = (state: ClientRegisterState) => {
  const thirtyMinutes = 1000 * 60 * 30;
  setWithExpiry('clientRegister', JSON.stringify(state), thirtyMinutes);
}

export const getClientRegisterState = (): ClientRegisterState | null => {
  const state = getWithExpiry('clientRegister');

  if (state) {
    return JSON.parse(state) as ClientRegisterState;
  }

  return null;
}

const ClientRegister = createSlice({
  name: 'ClientRegister',
  initialState: initState,
  reducers: {
    setCurrentStep(state, action) {
      const lastStepFinished = Number(state.lastStepFinished) || 1;
      if (lastStepFinished + 1 >= action.payload)  {
        state.currentStep = Number(action.payload) || 0;
      }
    },
    finishStep(state, action) {
      state.currentStep = Number(action.payload.currentStep) + 1
      state.lastStepFinished = Number(action.payload.currentStep);
      state.stateLoaded = true;
      state[action.payload.step] = {
        ...state[action.payload.step],
        isFinished: true,
      };

      saveState(state);
    },
    setStepData(state, action) {
      state[action.payload.step] = {
        ...state[action.payload.step],
        data: action.payload.data
      };
      state.stateLoaded = true;

      saveState(state);
    },
    setClientRegisterState(state, action) {
      state.stateLoaded = true;
      state.currentStep = action.payload.currentStep;
      state.lastStepFinished = action.payload.lastStepFinished;

      state.CLIENT = action.payload.CLIENT;
      state.DOCUMENTS = action.payload.DOCUMENTS;
      state.CONTACTS = action.payload.CONTACTS;
      state.ADDRESSES = action.payload.ADDRESSES;
      state.BANK_DATAS = action.payload.BANK_DATAS;

    },
    resetData(state) {
      state.stateLoaded = true;
      state.currentStep = initState.currentStep;
      state.lastStepFinished = initState.lastStepFinished;

      state.CLIENT = initState.CLIENT;
      state.DOCUMENTS = initState.DOCUMENTS;
      state.CONTACTS = initState.CONTACTS;
      state.ADDRESSES = initState.ADDRESSES;
      state.BANK_DATAS = initState.BANK_DATAS;

      localStorage.removeItem('clientRegister');
    }
  },
});

export const { setCurrentStep, finishStep, setStepData, resetData, setClientRegisterState } = ClientRegister.actions;
export default ClientRegister.reducer;
