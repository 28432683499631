import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import withRouter from '@common/withRouter';
import { CONTACT_TYPE_MANDATORY, REDIRECT_TIMEOUT, VALUE_MANDATORY } from '@helpers/constants';
import { create } from '@modules/Partners/submodules/Contacts/services';
import { IPartnerContactsCreate } from '@modules/Partners/types';
import PartnersContactsForm from '@modules/Partners/submodules/Contacts/components/PartnersContactForm';

const PartnersContactCreate = ({ router }: IWithRouter) => {
  const { partnerID } = useParams();
  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IPartnerContactsCreate) => create(partnerID as string, payload),
    onSuccess: () => {
      setTimeout(() => {
        router.navigate(`/partners/${partnerID}/contacts`);
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: '',
      value: '',
      primary_contact: false,
    },
    validationSchema: Yup.object({
      type: Yup.string().required(CONTACT_TYPE_MANDATORY),
      value: Yup.string().required(VALUE_MANDATORY),
      primary_contact: Yup.boolean(),
    }),
    onSubmit: async (payload: IPartnerContactsCreate) => mutate(payload),
  });

  return (
    <Page
      title="Cadastrar contato do acessor"
      breadcrumb={{
        title: 'Contatos',
        titleHref: `/partners/${partnerID}/contacts`,
        breadcrumbItem: 'Cadastrar contato',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Criar novo contato</CardTitle>
              <div className="p-2 mt-3">
                <PartnersContactsForm
                  loading={isPending}
                  validation={validation}
                  registrationError={error?.message}
                  showAlert={isSuccess}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(PartnersContactCreate);
