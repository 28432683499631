import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

interface TableActionsProps {
  isAdmin: boolean;
  itemID: string;
  editLink: string;
  onDeleteModal: (value: string) => void;
}

const TableActions = ({ isAdmin, itemID, editLink, onDeleteModal }: TableActionsProps) => {
  const navigate = useNavigate();

  if (!isAdmin) return null;

  return (
    <UncontrolledDropdown>
      <DropdownToggle href="#" className="card-drop" tag="a">
        <i className="mdi mdi-dots-vertical font-size-18" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem href="" onClick={() => navigate(editLink)}>
          <i className="mdi mdi-pencil font-size-16 text-success me-1" /> Editar
        </DropdownItem>
        <DropdownItem href="" onClick={() => onDeleteModal(itemID)}>
          <i className="mdi mdi-trash-can font-size-16 text-danger me-1" /> Deletar
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default TableActions;
