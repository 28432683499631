import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';

import Breadcrumbs, { BreadcrumbProps } from '@common/Breadcrumb';

interface IPage {
  title: string;
  children: React.ReactNode;
  breadcrumb: BreadcrumbProps;
}

export const Page = ({ breadcrumb, children, title }: IPage) => (
  <>
    <Helmet>
      <title>{title} | SGI</title>
    </Helmet>
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={breadcrumb.title}
          titleHref={breadcrumb.titleHref}
          breadcrumbItem={breadcrumb.breadcrumbItem}
          breadcrumbItemHref={breadcrumb.breadcrumbItemHref}
        />
        {children}
      </Container>
    </div>
  </>
);
