import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, BreadcrumbItem } from 'reactstrap';

export interface BreadcrumbProps {
  title: string;
  titleHref?: string;
  breadcrumbItem: string;
  breadcrumbItemHref?: string;
}

const Breadcrumb = (props: BreadcrumbProps) => {
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0 font-size-18">{props.breadcrumbItem}</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to={props.titleHref ?? '#'}>{props.title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link to={props.breadcrumbItemHref ?? '#'}>{props.breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Breadcrumb;
