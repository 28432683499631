import { InstanceError } from '@apptypes/instance';
import { IPagination } from '@apptypes/pagination';
import { generateToQueryString } from '@helpers/queryString';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/instance';
import { IPartnerBankDataCreate } from '@modules/Partners/types';
import { ColumnFiltersState } from '@tanstack/react-table';

export const getAll = async (
  partnerID: string,
  page: number,
  size: number,
  filterItems?: ColumnFiltersState,
): Promise<IPagination<IPartnerBankDataCreate>> => {
  try {
    const queryString = generateToQueryString({
      page,
      size,
      filterItems,
      otherParams: partnerID ? `&partner_id=${partnerID}` : '',
    });
    const { data } = await instance.get(`/partner-bank-data${queryString}`);
    return data;
  } catch (err) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Dados bancários não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(err as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getByID = async (partnerBankDataID: string): Promise<IPartnerBankDataCreate> => {
  try {
    const { data } = await instance.get(`/partner-bank-data/${partnerBankDataID}`);
    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Dados bancário não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const create = async (
  partnerID: string,
  payload: IPartnerBankDataCreate,
): Promise<IPartnerBankDataCreate> => {
  try {
    const { data } = await instance.post('/partner-bank-data', {
      ...payload,
      partner_id: partnerID,
    });

    return data;
  } catch (err) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar os dados bancários',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Dados bancários não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(err as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const update = async (
  partnerBankDataID: string,
  payload: IPartnerBankDataCreate,
): Promise<IPartnerBankDataCreate> => {
  try {
    const { data } = await instance.put(`/partner-bank-data/${partnerBankDataID}`, payload);

    return data;
  } catch (err) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível atualizar os dados bancários',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Dados bancários não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(err as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const deleteByID = async (partnerBankDataID: string): Promise<void> => {
  try {
    await instance.delete(`/partner-bank-data/${partnerBankDataID}`);
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Dados bancários não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
