import React from 'react';
import classnames from 'classnames';
import { Nav, NavItem, NavLink } from 'reactstrap';

interface TabsProps {
  activeTab: string;
  handleClick: () => void;
  toggleTab: (tab: string) => void;
}

const Tabs = ({ activeTab, handleClick, toggleTab }: TabsProps) => (
  <Nav tabs className="nav-tabs-custom justify-content-center pt-2" role="tablist">
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'client',
        })}
        onClick={handleClick}
      >
        Dados do Cliente
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'contracts',
        })}
        onClick={() => toggleTab('contracts')}
      >
        Contratos
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'contracts-transactions',
        })}
        onClick={() => toggleTab('contracts-transactions')}
      >
        Transações
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'contracts-files',
        })}
        onClick={() => toggleTab('contracts-files')}
      >
        Arquivos
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'contracts-extracts',
        })}
        onClick={() => toggleTab('contracts-extracts')}
      >
        Extrato
      </NavLink>
    </NavItem>
  </Nav>
);

export default Tabs;
