import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import SidebarContent from './SidebarContent';

const Sidebar = (props: any) => {
  return (
    <>
      <div
      className={classnames("vertical-menu", {
        'layout-hml': process.env.REACT_APP_ENV !== 'production'
      })}>
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <h1>SGI</h1>
            <h2>Sistema de Gestão Interno</h2>
          </Link>

          <Link to="/" className="logo logo-light">
            <h1>SGI</h1>
            <h2>Sistema de Gestão Interno</h2>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </>
  );
};

export default Sidebar;
