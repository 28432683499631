export const percentageBadge = (percentage: number) => {
  if (percentage > 0 && percentage <= 0.5) {
    return 'warning';
  }

  if (percentage > 0.5) {
    return 'success';
  }

  return 'danger';
};
