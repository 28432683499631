import React from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import Spinners from '@common/Spinner';
import withRouter from '@common/withRouter';
import { DOCUMENT_TYPE_MANDATORY, REDIRECT_TIMEOUT, VALUE_MANDATORY } from '@helpers/constants';
import { getByID, update } from '@modules/Partners/submodules/Documents/services';
import { getByID as getPartnerByID } from '@modules/Partners/services';
import PartnersDocumentForm from '@modules/Partners/submodules/Documents/components/PartnerDocumentForm';
import { IPartnerDocumentsCreate } from '@modules/Partners/types';
import { TYPE_VALID_CNPJ, TYPE_VALID_CPF } from '@modules/Users/constants';
import { yupInvalidCNPJ, yupInvalidCPF } from '@helpers/validations';

const PartnersDocumentUpdate = ({ router }: IWithRouter) => {
  yupInvalidCPF();
  yupInvalidCNPJ();

  const { partnerID, documentID } = useParams();

  const { data: partnerData } = useQuery({
    queryKey: ['client', partnerID],
    queryFn: () => getPartnerByID(partnerID || ''),
  });

  const { data, isFetching } = useQuery({
    queryKey: ['partnerDocument', { documentID }],
    queryFn: () => getByID(documentID || ''),
    retry: 1,
  });

  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IPartnerDocumentsCreate) => update(documentID as string, payload),
    onSuccess: () => {
      setTimeout(() => {
        router.navigate(`/partners/${partnerID}/documents`);
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: data?.type || '',
      value: data?.value || '',
    },
    validationSchema: Yup.object({
      type: Yup.string().required(DOCUMENT_TYPE_MANDATORY),
      value: Yup.string()
        .ensure()
        .when('type', {
          is: 'CPF',
          then: (Yup.string() as any).isValidCPF(TYPE_VALID_CPF),
        })
        .when('type', {
          is: 'CNPJ',
          then: (Yup.string() as any).isValidCNPJ(TYPE_VALID_CNPJ),
        })
        .required(VALUE_MANDATORY),
    }),
    onSubmit: async (payload: IPartnerDocumentsCreate) => mutate(payload),
  });

  return (
    <Page
      title="Editar documento do acessor"
      breadcrumb={{
        title: 'Documentos',
        titleHref: `/partners/${partnerID}/documents`,
        breadcrumbItem: 'Editar documento',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Editar documento</CardTitle>
              {isFetching ? (
                <Spinners />
              ) : (
                <div className="p-2 mt-3">
                  <PartnersDocumentForm
                    loading={isPending}
                    validation={validation}
                    registrationError={error?.message}
                    type="edit"
                    showAlert={isSuccess}
                    partnerType={partnerData?.type}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(PartnersDocumentUpdate);
