import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import moment from 'moment';

import TableActions from '@common/TableActions';

export const buildColumns = (
  isAdmin: boolean,
  partnerID: string,
  partnerType: string,
  onDeleteModal: (data: string) => void,
) => {
  const columnHelper = createColumnHelper();

  let documents = [
    { value: 'CPF', label: 'CPF' },
    { value: 'RG', label: 'RG' },
  ];

  if (partnerType === 'J') {
    documents = [
      { value: 'CNPJ', label: 'CNPJ' },
      { value: 'IE', label: 'Inscrição Estadual' },
      { value: 'IM', label: 'Inscrição Municipal' },
    ];
  }

  return [
    columnHelper.accessor('type', {
      header: 'Tipo de documento',
      size: 5,
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/partners/${partnerID}/documents/${row.original.id}`} className="text-dark">
            {getValue()}
          </Link>
        </h5>
      ),
      meta: {
        filterType: 'select',
        filterOptions: [{ value: '', label: 'Selecione...' }, ...documents],
      },
    }),
    columnHelper.accessor('value', {
      header: 'Número do documento',
      size: 66,
      cell: (data: any) => <p className="text-muted mb-0">{data.getValue()}</p>,
    }),
    columnHelper.accessor('created_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Data de criação',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('updated_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Última atualização',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('actions', {
      header: 'Ação',
      enableSorting: false,
      size: 10,
      cell: ({ row }: any) => (
        <TableActions
          isAdmin={isAdmin}
          editLink={`/partners/${partnerID}/documents/${row.original.id}`}
          itemID={row.original.id}
          onDeleteModal={onDeleteModal}
        />
      ),
      meta: {
        disableFilter: true,
      },
    }),
  ];
};
