import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import withRouter from 'components/common/withRouter';
import { logoutUser } from '@slices/thunk';

const Logout = () => {
  const dispatch = useDispatch<any>();
  const { isUserLogout } = useSelector((state: any) => state.Login);

  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  if (isUserLogout) {
    window.location.href = '/login';
    return;
  }

  return <></>;
};

export default withRouter(Logout);
