import Spinners from '@component/common/Spinner';
import { moneyTransform } from '@helpers/transforms/money';
import { getEarnings } from '@modules/Partners/services';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Button, Card, CardBody, CardTitle, Col } from 'reactstrap';

const EarningMonth = ({ partnerID }: { partnerID: string }) => {
  const [{ initialDate, lastDate }, setDate] = useState({
    initialDate: moment().startOf('month').format('YYYY-MM-DD'),
    lastDate: moment().endOf('month').format('YYYY-MM-DD'),
  });

  const { data, isFetching } = useQuery({
    queryKey: ['earnings', partnerID, initialDate, lastDate],
    queryFn: () =>
      getEarnings({
        partnerID,
        period: 'week',
        initialDate,
        finalDate: lastDate,
      }),
  });

  const handleDate = (type: 'increase' | 'decrement') => {
    if (type === 'increase') {
      setDate({
        initialDate: moment(initialDate).add(1, 'month').startOf('month').format('YYYY-MM-DD'),
        lastDate: moment(initialDate).add(1, 'month').endOf('month').format('YYYY-MM-DD'),
      });
    } else {
      setDate({
        initialDate: moment(initialDate).subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        lastDate: moment(initialDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      });
    }
  };

  function getWeekOfMonth(date) {
    const day = date.getDate();
    return Math.floor(day / 7);
  }

  let weeksArray = new Array(3).fill(null);

  data?.forEach((item) => {
    const startDate = new Date(item.period_start.replace(/T\d{2}:00:00\.000Z/g, 'T03:00:00.000Z'));

    if (moment(startDate).isSameOrAfter(initialDate)) {
      const weekIndex = getWeekOfMonth(startDate);
      weeksArray[weekIndex] = parseFloat(item.period_gain);
    }
  });

  data
    ?.filter((item) => {
      const startDate = new Date(
        item.period_start.replace(/T\d{2}:00:00\.000Z/g, 'T03:00:00.000Z'),
      );
      return moment(startDate).isBefore(initialDate);
    })
    ?.forEach((item) => {
      weeksArray = [parseFloat(item.period_gain), ...weeksArray];
    });

  return (
    <Col>
      <Card>
        <CardTitle>Mensal</CardTitle>
        <CardBody style={{ padding: 0 }}>
          {isFetching ? (
            <Spinners />
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <Button color="primary" onClick={() => handleDate('decrement')}>
                  <i className="mdi mdi-chevron-left me-0"></i>
                </Button>
                <strong>{moment(initialDate).format('MMM')}</strong>
                <Button color="primary" onClick={() => handleDate('increase')}>
                  <i className="mdi mdi-chevron-right me-0"></i>
                </Button>
              </div>
              <ReactApexChart
                options={{
                  chart: { height: 280, type: 'line', stacked: !1, toolbar: { show: !1 } },
                  stroke: { width: [0, 2, 5], curve: 'smooth' },
                  plotOptions: { bar: { columnWidth: '20%' } },
                  colors: ['#556ee6'],
                  fill: {
                    gradient: {
                      inverseColors: !1,
                      shade: 'light',
                      type: 'vertical',
                      opacityFrom: 0.85,
                      opacityTo: 0.55,
                      stops: [0, 100, 100, 100],
                    },
                  },
                  labels: ['1 sem', '2 sem', '3 sem', '4 sem', '5 sem'],
                  markers: { size: 0 },
                  yaxis: {
                    min: 0,
                    labels: {
                      formatter: (value) => {
                        return moneyTransform(value);
                      },
                    },
                  },
                }}
                series={[
                  {
                    name: 'Ganho total',
                    type: 'column',
                    data: weeksArray || [],
                  },
                ]}
                type="line"
                height={280}
                className="apex-charts"
              />
            </>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default EarningMonth;
