import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import TableActions from '@component/common/TableActions';

const getGender = (gender: string) => {
  switch (gender) {
    case 'M':
      return 'Masculino';
    case 'F':
      return 'Feminino';
    default:
      return 'Outro';
  }
};

export const buildColumns = (isAdmin: boolean, onDeleteModal: (data: string) => void) => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('code', {
      cell: ({ row, getValue }: any) => (
        <Badge color="primary" className="font-size-12" pill>
          <Link to={`/partners/${row.original.id}`} className="text-light">
            #{getValue()}
          </Link>
        </Badge>
      ),
      header: 'ID do acessor',
      size: 7,
      meta: {
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('type', {
      cell: ({ row, getValue }: any) => (
        <Badge color="primary" className="font-size-12" pill>
          <Link to={`/partners/${row.original.id}`} className="text-light">
            {getValue() === 'J' ? 'Jurídica' : 'Física'}
          </Link>
        </Badge>
      ),
      header: 'Tipo de pessoa',
      size: 15,
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'J', label: 'Jurídica' },
          { value: 'F', label: 'Física' },
        ],
      },
    }),
    columnHelper.accessor('name', {
      header: 'Nome',
      size: 20,
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/partners/${row.original.id}`} className="text-dark">
            {getValue()}
          </Link>
        </h5>
      ),
    }),
    columnHelper.accessor('profession', {
      header: 'Profissão',
      size: 20,
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/partners/${row.original.id}`} className="text-dark">
            {getValue()}
          </Link>
        </h5>
      ),
    }),
    columnHelper.accessor('gender', {
      header: 'Genêro',
      size: 10,
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/partners/${row.original.id}`} className="text-dark">
            {getGender(getValue())}
          </Link>
        </h5>
      ),
    }),
    columnHelper.accessor('observation', {
      header: 'Observação',
      size: 40,
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/partners/${row.original.id}`} className="text-dark">
            {getValue()}
          </Link>
        </h5>
      ),
    }),
    columnHelper.accessor('active', {
      cell: ({ row, getValue }: any) => (
        <>
          {getValue() ? (
            <Badge color="success" className="font-size-12" pill>
              Ativado
            </Badge>
          ) : (
            <Badge color="danger" className="font-size-12" pill>
              Desativado
            </Badge>
          )}
        </>
      ),
      header: 'Ativo',
      size: 5,
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'true', label: 'Sim' },
          { value: 'false', label: 'Não' },
        ],
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }: any) => (
        <TableActions
          isAdmin={isAdmin}
          editLink={`/partners/${row.original.id}/`}
          itemID={row.original.id}
          onDeleteModal={onDeleteModal}
        />
      ),
      header: 'Ação',
      enableSorting: false,
      size: 15,
      meta: {
        disableFilter: true,
      },
    }),
  ];
};

export const buildContractColumns = () => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('client.code', {
      cell: ({ row, getValue }: any) => (
        <Badge color="primary" className="font-size-12" pill>
          <Link to={`/contracts/${row.original.id}`} className="text-light">
            #{getValue()}
          </Link>
        </Badge>
      ),
      header: 'ID do cliente',
      size: 7,
    }),
    columnHelper.accessor('identification_number', {
      cell: ({ row, getValue }: any) => (
        <Badge color="primary" className="font-size-12" pill>
          <Link to={`/contracts/${row.original.id}`} className="text-light">
            #{getValue()}
          </Link>
        </Badge>
      ),
      header: 'ID do contrato',
      size: 7,
    }),
    columnHelper.accessor('client', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/contracts/${row.original.id}`} className="text-dark">
            {getValue().name}
          </Link>
        </h5>
      ),
      header: 'Cliente',
      size: 20,
    }),
    columnHelper.accessor('profitability_group', {
      cell: ({ row, getValue }: any) => (
        <p className="text-muted mb-0">
          {getValue().description} - <strong>{getValue().percentage?.replace('.', ',')}%</strong>
        </p>
      ),
      header: 'Grupo de rentabilidade',
      size: 10,
    }),
    columnHelper.accessor('active', {
      cell: ({ row, getValue }: any) => (
        <>
          {getValue() ? (
            <Badge color="success" className="font-size-12" pill>
              Ativado
            </Badge>
          ) : (
            <Badge color="danger" className="font-size-12" pill>
              Desativado
            </Badge>
          )}
        </>
      ),
      header: 'Ativo',
      size: 5,
    }),
    columnHelper.accessor('contracts', {
      cell: ({ row }: any) => (
        <div className="d-flex gap-3">
          <Link to={`/contracts/${row.original.id}`} className="btn btn-outline-primary btn-sm">
            <i className="mdi mdi-eye font-size-14" id="edittooltip" /> Visualizar
          </Link>
        </div>
      ),
      header: 'Contratos',
      size: 5,
      meta: {
        disableFilter: true,
      },
    }),
  ];
};

export const buildClientColumns = () => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('type', {
      header: 'Tipo',
      size: 5,
      cell: ({ row, getValue }: any) => (
        <Badge color="primary" className="font-size-14" pill>
          <Link to={`/clients/${row.original.id}`} className="text-light">
            {getValue() === 'J' ? 'Jurídica' : 'Física'}
          </Link>
        </Badge>
      ),
    }),
    columnHelper.accessor('name', {
      header: 'Nome',
      size: 20,
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/clients/${row.original.id}`} className="text-dark">
            {getValue()}
          </Link>
        </h5>
      ),
    }),
    columnHelper.accessor('document', {
      header: 'Documento',
      size: 20,
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/clients/${row.original.id}`} className="text-dark">
            {getValue()}
          </Link>
        </h5>
      ),
    }),
    columnHelper.accessor('clients', {
      cell: ({ row }: any) => (
        <div className="d-flex gap-3">
          <Link to={`/clients/${row.original.id}`} className="btn btn-outline-primary btn-sm">
            <i className="mdi mdi-eye font-size-14" id="edittooltip" /> Visualizar
          </Link>
        </div>
      ),
      header: 'Cliente',
      size: 5,
    }),
  ];
};
