import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import withRouter from '@common/withRouter';
import { REDIRECT_TIMEOUT } from '@helpers/constants';
import TransactionsForm from '@modules/Contract/submodules/Transactions/components/TransactionForm';
import {
  TRANSACTION_TYPE_MANDATORY,
  TYPE_MANDATORY,
  VALUE_MANDATORY,
} from '@modules/Contract/constants';
import { create, getAll } from '@modules/Contract/submodules/Transactions/services';
import { ITransactionsCreate } from '@modules/Contract/types';
import { getTotalValue } from '@modules/Contract/services';
import { moneyTransform } from '@helpers/transforms/money';

const TransitionsCreate = ({ router }: IWithRouter) => {
  const { contractID, clientID } = useParams();

  const [valueEnabled, setValueEnabled] = useState(
    localStorage.getItem('is_total_value_enabled') === 'true',
  );

  const { data, isFetching } = useQuery({
    queryKey: ['contractTotalValue', contractID],
    queryFn: () => getTotalValue(contractID || ''),
    retry: 0,
  });

  const { data: transacations } = useQuery({
    queryKey: ['contractTotalTransactions', contractID],
    queryFn: () => getAll(contractID || '', 1, 10),
    initialData: { items: [], totalItems: 0 },
  });

  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: ITransactionsCreate) =>
      create(contractID as string, clientID as string, payload),
    onSuccess: () => {
      setTimeout(() => {
        router.navigate(`/contracts/${contractID}/contracts-transactions`);
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: '',
      value: 0,
      effective_date: '',
      transaction_date: '',
      payment_type: '',
      receipts: [],
      contract_file: '',
    },
    validationSchema: Yup.object({
      type: Yup.string().required(TRANSACTION_TYPE_MANDATORY),
      value: Yup.string().required(VALUE_MANDATORY),
      payment_type: Yup.string().required(TYPE_MANDATORY),
      effective_date: Yup.date(),
      transaction_date: Yup.date().when('effective_date', (effectiveDate, schema) => {
        return (
          effectiveDate &&
          schema.max(effectiveDate, 'A data de transação deve ser menor que a data de efetiva')
        );
      }),
      receiptFile: Yup.mixed(),
    }),
    onSubmit: async (payload: any) => mutate(payload),
  });

  const toggleEnable = () => {
    localStorage.setItem('is_total_value_enabled', String(!valueEnabled));
    setValueEnabled(!valueEnabled);
  };

  return (
    <Page
      title="Cadastrar transação"
      breadcrumb={{
        title: 'Transações',
        titleHref: `/contracts/${contractID}/contracts-transactions`,
        breadcrumbItem: 'Cadastrar transação',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="d-flex mb-4">
                Criar Nova Transação
                {!isFetching && (
                  <div className="ms-4 contract-total-value" onClick={toggleEnable}>
                    {valueEnabled ? (
                      <>
                        <i className="mdi mdi-eye"></i> {moneyTransform(data?.total_value || 0)}
                      </>
                    ) : (
                      <>
                        <i className="mdi mdi-eye-off"></i> R$ *****,**
                      </>
                    )}
                  </div>
                )}
              </CardTitle>
              <div className="p-2 mt-3">
                <TransactionsForm
                  loading={isPending}
                  validation={validation}
                  registrationError={error?.message}
                  showAlert={isSuccess}
                  totalItems={transacations?.totalItems || 0}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(TransitionsCreate);
