import React from 'react';

import PendingWithdrawals from '@modules/PendingWithdrawals/pages';
import ConfirmedWithdrawals from '@modules/PendingWithdrawals/pages/confirmed';
import { pendingWithdrawalsFilesRoutes } from '@modules/PendingWithdrawals/submodules/Files/routes';

export const pendingWithdrawalsRoutes = [
  // Pending Withdrawals
  { path: '/pending-withdrawals', component: <PendingWithdrawals /> },
  { path: '/confirmed-withdrawals', component: <ConfirmedWithdrawals /> },

  // Pending Withdrawals Files
  ...pendingWithdrawalsFilesRoutes,
];
