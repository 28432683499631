import React, { useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';
import moment from 'moment';

import { fileDownload, fileOpen } from '@modules/Partners/submodules/PaymentFiles/services';

export const buildColumns = (
  isAdmin: boolean,
  setFile: any,
  setFileType: any,
  onDeleteModal: (data: string) => void,
) => {
  const [isDownloadStarted, setIsDownloadStarted] = useState(false);
  const columnHelper = createColumnHelper();

  const handleDownload = async (fileID: string, filename: string) => {
    setIsDownloadStarted(true);
    await fileDownload(fileID, filename);
    setIsDownloadStarted(false);
  };

  const handleOpenFile = async (fileID: string, filename: string, fileType: string) => {
    setIsDownloadStarted(true);
    const file = await fileOpen(fileID, filename);

    setIsDownloadStarted(false);
    setFile(file);
    setFileType(fileType);
  };

  return [
    columnHelper.accessor('type', {
      cell: ({ row, getValue }: any) => (
        <Badge color="danger" className="font-size-12" pill>
          <Link to={`/contracts/${row.original.id}`} className="text-light">
            {getValue()}
          </Link>
        </Badge>
      ),
      header: 'Tipo do arquivo',
      size: 7,
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'PDF', label: 'PDF' },
          { value: 'PNG', label: 'PNG' },
          { value: 'JPG', label: 'JPG' },
        ],
      },
    }),
    columnHelper.accessor('name', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/contracts/${row.original.id}`} className="text-dark">
            {getValue()}
          </Link>
        </h5>
      ),
      header: 'Nome do arquivo',
      size: 20,
    }),
    columnHelper.accessor('created_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Data de criação',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('updated_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Última atualização',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }: any) => {
        if (!isAdmin) {
          return;
        }
        return (
          <div className="d-flex gap-3 justify-content-end">
            <Button
              onClick={() => handleOpenFile(row.original.id, row.original.name, row.original.type)}
              color="primary"
              outline
              size="sm"
              disabled={isDownloadStarted}
            >
              <i className="mdi mdi-eye font-size-14" id="edittooltip" /> Abrir
            </Button>

            <Button
              onClick={() => handleDownload(row.original.id, row.original.name)}
              color="success"
              size="sm"
              disabled={isDownloadStarted}
            >
              <i className="mdi mdi-download font-size-14" id="edittooltip" /> Baixar
            </Button>

            <Button onClick={() => onDeleteModal(row.original.id)} color="danger" size="sm">
              <i className="mdi mdi-trash-can font-size-16 me-1" /> Apagar
            </Button>
          </div>
        );
      },
      header: '',
      enableSorting: false,
      size: 15,
      meta: {
        disableFilter: true,
      },
    }),
  ];
};
