import React from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import Spinners from '@common/Spinner';
import withRouter from '@common/withRouter';
import {
  CITY_MANDATORY,
  COUNTRY_MANDATORY,
  NEIGHBOURHOOD_MANDATORY,
  NUMBER_MANDATORY,
  REDIRECT_TIMEOUT,
  STATE_MANDATORY,
  STREET_MANDATORY,
  ZIPCODE_MANDATORY,
} from '@helpers/constants';
import { getByID, update } from '@modules/Client/submodules/Addresses/services';
import ClientAddressesForm from '@modules/Client/submodules/Addresses/components/ClientAddressesForm';
import { IClientAdressesCreate } from '@modules/Client/types';

const ClientDocumentUpdate = ({ router }: IWithRouter) => {
  const { clientID, addressID } = useParams();
  const { data, isFetching } = useQuery({
    queryKey: ['clientAddress', { addressID }],
    queryFn: () => getByID(addressID || ''),
    retry: 1,
  });

  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IClientAdressesCreate) => update(addressID as string, payload),
    onSuccess: () => {
      setTimeout(() => {
        router.navigate(`/clients/${clientID}/addresses`);
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      zipcode: data?.zipcode || '',
      country: data?.country || '',
      state: data?.state || '',
      city: data?.city || '',
      neighbourhood: data?.neighbourhood || '',
      complement: data?.complement || '',
      street: data?.street || '',
      number: data?.number || '',
    },
    validationSchema: Yup.object({
      zipcode: Yup.string().required(ZIPCODE_MANDATORY),
      state: Yup.string().required(STATE_MANDATORY),
      country: Yup.string().required(COUNTRY_MANDATORY),
      city: Yup.string().required(CITY_MANDATORY),
      neighbourhood: Yup.string().required(NEIGHBOURHOOD_MANDATORY),
      complement: Yup.string(),
      street: Yup.string().required(STREET_MANDATORY),
      number: Yup.number().required(NUMBER_MANDATORY),
    }),
    onSubmit: async (payload: IClientAdressesCreate) => mutate(payload),
  });

  return (
    <Page
      title="Endereços"
      breadcrumb={{
        title: 'Endereços',
        titleHref: `/clients/${clientID}/addresses`,
        breadcrumbItem: 'Editar endereço',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Editar endereço</CardTitle>
              {isFetching ? (
                <Spinners />
              ) : (
                <div className="p-2 mt-3">
                  <ClientAddressesForm
                    loading={isPending}
                    validation={validation}
                    registrationError={error?.message}
                    type="edit"
                    showAlert={isSuccess}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(ClientDocumentUpdate);
