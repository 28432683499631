import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateMetadata } from '@slices/users/thunk';

export const useColumnVisibility = (moduleName?: string) => {
  const { user }: any = useSelector((state: any) => state.Profile);
  const dispatch: any = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState<Record<any, any>>();

  const updateMetadatByUserID = async (columnVisibility?: Record<any, any>) => {
    if (!moduleName || !columnVisibility) {
      return;
    }

    const metadata = user.metadata || {};
    const response = await updateMetadata(user.id, {
      ...metadata,
      [moduleName]: {
        ...(metadata[moduleName] || {}),
        columnVisibility,
      },
    });

    dispatch(response);
  };

  useEffect(() => {
    if (user?.id) {
      updateMetadatByUserID(columnVisibility);
    }
  }, [columnVisibility]);

  useEffect(() => {
    if (user?.id && user?.metadata && moduleName) {
      setColumnVisibility(user?.metadata[moduleName]?.columnVisibility);
    }
  }, [user?.id]);

  return {
    columnVisibility,
    dropdownOpen,
    setDropdownOpen,
    setColumnVisibility,
  };
};
