import { InstanceError } from '@apptypes/instance';
import { IPagination } from '@apptypes/pagination';
import { generateToQueryString } from '@helpers/queryString';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/instance';
import { IPartnerContactsCreate } from '@modules/Partners/types';
import { ColumnFiltersState } from '@tanstack/react-table';

export const getAll = async (
  partnerID: string,
  page = 1,
  size = 10,
  filterItems?: ColumnFiltersState,
): Promise<IPagination<IPartnerContactsCreate>> => {
  try {
    const queryString = generateToQueryString({
      page,
      size,
      filterItems,
      otherParams: partnerID ? `&partner_id=${partnerID}` : '',
    });
    const { data } = await instance.get(`/partner-contacts${queryString}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Contatos não foram encontrados',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getByID = async (partnerContactID: string): Promise<IPartnerContactsCreate> => {
  try {
    const { data } = await instance.get(`/partner-contacts/${partnerContactID}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Contato não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const create = async (
  partnerID: string,
  payload: IPartnerContactsCreate,
): Promise<IPartnerContactsCreate> => {
  try {
    const { data } = await instance.post(`/partner-contacts?partner_id=${partnerID}`, {
      type: payload.type,
      value: payload.value,
      primary_contact: payload.primary_contact,
      partner_id: partnerID,
    });

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar o contato',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Contato não encontrado',
      [ERROR_STATUS_MESSAGE.PRIMARY_CONTACT_ALREADY_EXISTS]:
        'Contato primário já existe para esse acessor e tipo de contato',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const update = async (
  partnerContactID: string,
  partnerID: string,
  payload: IPartnerContactsCreate,
): Promise<IPartnerContactsCreate> => {
  try {
    const { data } = await instance.put(
      `/partner-contacts/${partnerContactID}?partner_id=${partnerID}`,
      payload,
    );

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível editar o contato',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Contato não encontrado',
      [ERROR_STATUS_MESSAGE.PRIMARY_CONTACT_ALREADY_EXISTS]:
        'Contato primário já existe para esse acessor e tipo de contato',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const deleteByID = async (partnerContactID: string) => {
  try {
    await instance.delete(`/partner-contacts/${partnerContactID}`);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível deletar o contato',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
