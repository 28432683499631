export const CLIENT_MANDATORY = 'O campo cliente é obrigatório';
export const PROFITABILITY_GROUP_MANDATORY = 'O campo grupo de rentabilidade é obrigatório';

export const TRANSACTION_TYPE_MANDATORY = 'O campo tipo de transação é obrigatório';
export const VALUE_MANDATORY = 'O campo valor é obrigatório';
export const TYPE_MANDATORY = 'O campo tipo é obrigatório';
export const TOTAL_VALUE_MANDATORY = 'O campo valor total é obrigatório';
export const EFFECTIVE_DATE_MANDATORY = 'O cam data efetiva é obrigatório';
export const ACTIVE_TRANSACTION_MANDATORY = 'O campo transação ativa é obrigatório';

export const VALUE_CANNOT_BE_NEGATIVE_OR_ZERO = 'Saldo não pode ser negativo ou zero';
export const TOTAL_VALUE_CANNOT_BE_NEGATIVE = 'Saldo total não pode ser negativo';
export const TRANSACTION_ALREADY_APPROVED = 'Transação já aprovada';
export const TRANSACTION_DATE_GREATER_THAN_EFFECTIVE_DATE =
  'A data de transação não pode ser maior que a data de efetiva';

export const CLIENT_DOES_NOT_HAVE_CONTACTS = 'Cliente não tem email e/ou telefone cadastrado';
export const CLIENT_DOES_NOT_HAVE_ADDRESSES = 'Cliente não tem endereço cadastrado';
export const CLIENT_DOES_NOT_HAVE_DOCUMENTS = 'Cliente não tem os documentos cadastrados';
export const CLIENT_DOES_NOT_HAVE_EMAIL_OR_PHONE =
  'Cliente deve ter email e/ou telefone cadastrado';
export const CLIENT_DOES_NOT_HAVE_CPF_OR_RG = 'Cliente deve ter CPF e RG cadastrado';
export const CLIENT_DOES_NOT_HAVE_CNPJ_OR_IE_OR_IM = 'Cliente deve ter CNPJ, IE e IM cadastrado';
