import React from 'react';

import TransactionsFiles from '@modules/Contract/submodules/TransactionsFiles/pages';

export const transactionFilesRoutes = [
  {
    path: '/contracts/:contractID/contracts-transactions/:contractTransactionsID/contracts-transactions-files',
    component: <TransactionsFiles />,
  },
];
