export const moneyTransform = (value: number) => {
  const brazilianReal = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return brazilianReal.format(value);
};

export const transformMoneyToNumber = (value: string) => {
  return Number(value.replace(',', '.'));
};

export const transformPercentage = (percentage: number) => {
  const value = String(percentage);

  if (percentage > 0) {
    return `+ ${value.replace('.', ',')}%`;
  }

  return `${value.replace('.', ',')}%`;
};
