import { AUTH_COOKIE_NAME, USER_ID_COOKIE_NAME } from '@helpers/constants';
import { deleteCookie, setCookie } from '@helpers/cookies';
import instance from '@helpers/request/instance';
import { apiError, logoutUserSuccess, resetLoginFlag, setLoading } from './reducer';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import { InstanceError } from '@apptypes/instance';

export const loginUser =
  async (payload: { username: string; password: string }) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const { data } = await instance.post('/sign-in', payload);

      setCookie(USER_ID_COOKIE_NAME, data.user.id, 1);
      setCookie(AUTH_COOKIE_NAME, data.access_token, 1);
      window.location.href = '/dashboard';
    } catch (error) {
      dispatch(setLoading(false));

      const errorMessage: Record<string, string> = {
        [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Usuário e/ou senha inválidos.',
        [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Usuário não encontrado',
        [ERROR_STATUS_MESSAGE.CREDENTIALS_INVALID]: 'Usuário e/ou senha inválidos.',
        [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
      };

      dispatch(
        apiError(
          errorMessage[(error as InstanceError).status] ??
            errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
        ),
      );
    }
  };

export const logoutUser = () => async (dispatch) => {
  try {
    deleteCookie(AUTH_COOKIE_NAME);
    dispatch(logoutUserSuccess(true));
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginMsgFlag = () => {
  try {
    return resetLoginFlag();
  } catch (error) {
    return error;
  }
};
