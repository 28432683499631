import axios from 'axios';
import instance from '@helpers/request/instance';

import { InstanceError } from '@apptypes/instance';
import { IPagination } from '@apptypes/pagination';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import { IPartnerAdressesCreate } from '@modules/Partners/types';
import { ColumnFiltersState } from '@tanstack/react-table';
import { generateToQueryString } from '@helpers/queryString';

export const getAll = async (
  partnerID: string,
  page: number,
  size: number,
  filterItems?: ColumnFiltersState,
): Promise<IPagination<IPartnerAdressesCreate>> => {
  try {
    const queryString = generateToQueryString({
      page,
      size,
      filterItems,
      otherParams: partnerID ? `&partner_id=${partnerID}` : '',
    });
    const { data } = await instance.get(`/partner-addresses${queryString}`);
    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Endereços não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getByID = async (partnerAddressID: string) => {
  try {
    const { data } = await instance.get(`/partner-addresses/${partnerAddressID}`);
    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Endereço não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const create = async (partnerID: string, payload: IPartnerAdressesCreate) => {
  try {
    const { data } = await instance.post('/partner-addresses', {
      ...payload,
      number: Number(payload.number),
      partner_id: partnerID,
    });

    return data;
  } catch (err) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar o endereço',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Endereço não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(err as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const update = async (partnerAddressID: string, payload: IPartnerAdressesCreate) => {
  try {
    const { data } = await instance.put(`/partner-addresses/${partnerAddressID}`, {
      ...payload,
      number: Number(payload.number),
    });
    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível editar o endereço',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Endereço não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const deleteByID = async (partnerAddressID: string) => {
  try {
    await instance.delete(`/partner-addresses/${partnerAddressID}`);
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Endereço não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getZipcode = async (zipcode: string) => {
  try {
    const { data } = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);
    return data;
  } catch (error) {
    return error;
  }
};
