import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody, Col, Row } from 'reactstrap';

interface IDetail {
  identificationNumber: string;
  profitabilityGroupDescription: string;
  profitabilityGroupPercentage: string;
  contractID?: string;
}

export const Detail = ({
  identificationNumber,
  profitabilityGroupDescription,
  profitabilityGroupPercentage,
  contractID,
}: IDetail) => (
  <Card className="hidden-print">
    <CardBody>
      <Row>
        <Col md={10}>
          <Badge pill color="primary" className="font-size-14 me-2">
            #{identificationNumber}
          </Badge>
          <span className="font-size-14 ms-1">{profitabilityGroupDescription}</span>
          <span className="font-size-14 ms-1">- {profitabilityGroupPercentage}%</span>
        </Col>
        <Col md={2} className="d-flex justify-content-end">
          <Link to={`/contracts/${contractID}`} className="text-primary ms-2">
            <i className="mdi mdi-eye"></i> Visualizar Contrato
          </Link>
        </Col>
      </Row>
    </CardBody>
  </Card>
);
