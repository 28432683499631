enum LAYOUT_TYPES {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

enum LAYOUT_MODE_TYPES {
  DARK = 'dark',
  LIGHT = 'light',
}

enum LAYOUT_WIDTH_TYPES {
  FLUID = 'fluid',
  BOXED = 'boxed',
  SCROLLABLE = 'scrollable',
}

enum TOPBAR_THEME_TYPES {
  LIGHT = 'light',
  DARK = 'dark',
  COLORED = 'colored',
}

enum LEFT_SIDEBAR_TYPES {
  DEFAULT = 'default',
  COMPACT = 'small',
  ICON = 'icon',
}

enum LEFT_SIDEBAR_THEME_TYPES {
  LIGHT = 'light',
  COLORED = 'colored',
  DARK = 'dark',
  WINTER = 'winter',
  LADYLIP = 'ladylip',
  PLUMPLATE = 'plumplate',
  STRONGBLISS = 'strongbliss',
  GREATWHALE = 'greatwhale',
}

enum LEFTBAR_THEME_IMAGES_TYPES {
  NONE = 'none',
  IMG1 = 'img1',
  IMG2 = 'img2',
  IMG3 = 'img3',
  IMG4 = 'img4',
}

export {
  LAYOUT_TYPES,
  LAYOUT_MODE_TYPES,
  LAYOUT_WIDTH_TYPES,
  TOPBAR_THEME_TYPES,
  LEFT_SIDEBAR_TYPES,
  LEFT_SIDEBAR_THEME_TYPES,
  LEFTBAR_THEME_IMAGES_TYPES,
};
