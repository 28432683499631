import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import TableContainer from '@common/TableContainer';
import withRouter from '@common/withRouter';
import { deleteByID, exportData, getAll } from '@modules/Partners/services';
import { buildColumns } from '@modules/Partners/components/Table/constants';
import { ColumnFiltersState } from '@tanstack/react-table';

const Partners = ({ router, page, size, setSearchParams }: IWithRouter) => {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const { user }: any = useSelector((state: any) => state.Profile);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['partners', page, size, filters],
    queryFn: () => getAll(page, size, filters),
    initialData: { items: [], totalItems: 0 },
    retry: 1,
  });

  const deleteMutation = useMutation({
    mutationFn: () => deleteByID(deletePartnerID || ''),
    onSuccess: () => {
      toast.success('Acessor deletado com sucesso!', {
        theme: 'colored',
        autoClose: 1500,
      });

      refetch();
      setSearchParams({ page: '1' });
      setDeleteModal(false);
    },
  });

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deletePartnerID, setDeletePartnerID] = useState<string | undefined>();

  const handleDeletePartnerModal = (contractID: string) => {
    setDeletePartnerID(contractID);
    setDeleteModal(true);
  };

  const handleExportData = async () => {
    const fileUrl = await exportData(page, size, filters);

    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = `partners-export.csv`;
    link.click();
  };

  return (
    <Page
      title="Acessores"
      breadcrumb={{
        title: 'Acessores',
        titleHref: '/partners',
        breadcrumbItem: 'Listar acessores',
      }}
    >
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <TableContainer
                moduleName="partners"
                columns={buildColumns(user?.is_admin, handleDeletePartnerModal)}
                customPageSize={size}
                currentPage={page}
                data={data.items}
                loading={isFetching}
                totalItems={data.totalItems}
                customElement={
                  <Button type="button" color="primary" outline onClick={handleExportData}>
                    Exportar dados
                  </Button>
                }
                deleteModal={{
                  isOpen: deleteModal,
                  loading: isFetching,
                  onDeleteClick: deleteMutation.mutate,
                  onCloseClick: () => setDeleteModal(false),
                }}
                handleFilters={setFilters}
                setSearchParams={setSearchParams}
                refetch={refetch}
                isAddRegister
                isPagination
                isShowingPageLength
                isDelete
                handleAddRegisterClick={() => router.navigate('/partners/create')}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(Partners);
