import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import ReactEcharts from 'echarts-for-react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface CustodyContractTypeProps {
  options: any;
  isLoading: boolean;
}

export const CustodyContractType = ({ options, isLoading }: CustodyContractTypeProps) => (
  <Card>
    <CardBody>
      <CardTitle>{isLoading ? <Skeleton width={100} /> : 'Modalidade dos contratos'}</CardTitle>
      <div id="pie-chart" className="e-chart">
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Skeleton circle width={250} height={250} />
          </div>
        ) : (
          <ReactEcharts style={{ height: '350px' }} option={options} />
        )}
      </div>
    </CardBody>
  </Card>
);
