import React from 'react';

import Files from '@modules/PendingWithdrawals/submodules/Files/pages';

export const pendingWithdrawalsFilesRoutes = [
  {
    path: '/pending-withdrawals/:pendingWithdrawalsID/pending-withdrawals-files',
    component: <Files />,
  },
];
