import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';
import { moneyTransform } from '@helpers/transforms/money';
import moment from 'moment';

export const buildColumns = (
  isAdmin: boolean,
  onApprove: (id: string, status: string, type: string) => void,
) => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('contract_client_name', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/contracts/${row.original.id}`} className="text-dark">
            {row.original.contract?.client?.name} /{' '}
            {row.original.contract?.profitability_group?.description} -{' '}
            {row.original.contract?.profitability_group?.percentage.replace('.', ',')}%
          </Link>
        </h5>
      ),
      header: 'Contrato',
      size: 33,
      meta: {
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('type', {
      cell: ({ row, getValue }: any) => (
        <Badge color={getValue() === 'APORTE' ? 'success' : 'danger'} className="font-size-14" pill>
          {getValue()}
        </Badge>
      ),
      header: 'Tipo',
      size: 10,
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'APORTE', label: 'Aporte' },
          { value: 'RETIRADA', label: 'Retirada' },
        ],
      },
    }),
    columnHelper.accessor('value', {
      cell: ({ row, getValue }: any) => {
        const prefix = row.original.type === 'APORTE' ? '+' : '';
        return (
          <h5 className="font-size-14 mb-1">
            <Link
              to="#"
              className={classnames('text-dark', {
                'positive-value': row.original.type === 'APORTE',
                'negative-value': row.original.type === 'RETIRADA',
              })}
            >
              {prefix} {moneyTransform(getValue())}
            </Link>
          </h5>
        );
      },
      header: 'Valor',
      size: 10,
      meta: {
        disableFilter: true,
      },
    }),
    columnHelper.accessor('created_at', {
      cell: ({ row, getValue }: any) => (
        <p className="text-muted mb-0">{moment(getValue()).format('DD/MM/YYYY')}</p>
      ),
      header: 'Data da transação',
      size: 37,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }: any) => {
        if (!isAdmin) {
          return;
        }

        return (
          <div className="d-flex gap-3">
            <Button
              color="success"
              outline
              size="sm"
              onClick={() => onApprove(row.original.id || '', 'accept', row.original.type)}
            >
              <i className="mdi mdi-check font-size-14" id="edittooltip" />
              Aprovar
            </Button>
          </div>
        );
      },
      header: 'Ação',
      enableSorting: false,
      size: 10,
      meta: {
        disableFilter: true,
      },
    }),
  ];
};
