import React from 'react';

import Contracts from '@modules/Contract/pages';
import ContractsCreate from '@modules/Contract/pages/create';
import ContractsUpdate from '@modules/Contract/pages/update';

import { contractExtractRoutes } from '../submodules/Extract/routes';
import { transactionRoutes } from '../submodules/Transactions/routes';
import { linkPartnerRoutes } from '../submodules/Partner/routes';
import { filesRoutes } from '../submodules/Files/routes';
import { transactionFilesRoutes } from '../submodules/TransactionsFiles/routes';

export const contractRoutes = [
  { path: '/contracts', component: <Contracts /> },
  { path: '/contracts/create', component: <ContractsCreate /> },
  { path: '/contracts/:contractID', component: <ContractsUpdate /> },

  // Extracts
  ...contractExtractRoutes,

  // Transaction Files
  ...transactionFilesRoutes,

  // Transactions
  ...transactionRoutes,

  // Link Partner
  ...linkPartnerRoutes,

  // Files
  ...filesRoutes,
];
