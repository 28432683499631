import React from 'react';

import Partners from '@modules/Partners/pages';
import PartnersCreate from '@modules/Partners/pages/create';
import PartnersUpdate from '@modules/Partners/pages/update';
import PartnersRegister from '@modules/Partners/pages/register';

import { partnerAddressesRoutes } from '../submodules/Addresses/routes';
import { partnerBankDatasRoutes } from '../submodules/BankDatas/routes';
import { partnerContactsRoutes } from '../submodules/Contacts/routes';
import { partnerDocumentsRoutes } from '../submodules/Documents/routes';
import { partnerContractsRoutes } from '../submodules/Contracts/routes';
import { partnerClientsRoutes } from '../submodules/Clients/routes';
import { partnersEarningsRoutes } from '../submodules/Earnings/routes';
import { partnersPaymentsRoutes } from '@modules/Partners/submodules/Payments/routes';
import { paymentFilesRoutes } from '@modules/Partners/submodules/PaymentFiles/routes';

export const partnersRoutes = [
  { path: '/partners', component: <Partners /> },
  { path: '/partners/create', component: <PartnersCreate /> },
  { path: '/partners/register', component: <PartnersRegister /> },
  // { path: '/partners/:partnerID', component: <PartnerDetails /> },
  { path: '/partners/:partnerID', component: <PartnersUpdate activePath="details" /> },

  // Addresses
  ...partnerAddressesRoutes,

  // Bank Data
  ...partnerBankDatasRoutes,

  // Contacts
  ...partnerContactsRoutes,

  // Documents
  ...partnerDocumentsRoutes,

  // Contracts
  ...partnerContractsRoutes,

  // Clients
  ...partnerClientsRoutes,

  // Earnings
  ...partnersEarningsRoutes,

  // Payment Files
  ...paymentFilesRoutes,

  // Payments
  ...partnersPaymentsRoutes,
];
