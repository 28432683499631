import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import TableContainer from 'components/common/TableContainer';
import { buildColumns } from '@modules/Contract/submodules/Transactions/components/Table/constants';
import { useQuery } from '@tanstack/react-query';
import { exportData, getAll } from '@modules/Contract/submodules/Transactions/services';
import { IRouter } from '@apptypes/WithRouter';
import { ColumnFiltersState } from '@tanstack/react-table';
import { moneyTransform } from '@helpers/transforms/money';
import { Button } from 'reactstrap';

interface ITransactions {
  router: IRouter;
  contractID?: string;
  clientID?: string;
  page: number;
  size: number;
  setSearchParams: (params: any) => void;
}

const Transactions = ({
  router,
  contractID,
  clientID,
  page,
  size,
  setSearchParams,
}: ITransactions) => {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const { user } = useSelector((state: any) => state.Profile);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['transactions', contractID, page, size, filters],
    queryFn: () => getAll(contractID || '', page, size, filters),
    initialData: { items: [], totalItems: 0 },
    retry: 1,
  });

  const handleExportData = async () => {
    const fileUrl = await exportData(page, size, contractID || '', filters);

    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = `transaction-export.xlsx`;
    link.click();
  };

  return (
    <div className="d-flex clients-content">
      <Helmet>
        <title>Transações do contrato | SGI</title>
      </Helmet>
      <div className="d-flex">
        <p className="text-muted font-size-14 me-2">
          <strong>Valor total:</strong>
        </p>
        <p className="text-success font-size-14">{moneyTransform((data as any).totalValue)}</p>
      </div>
      <TableContainer
        moduleName="contractsTransaction"
        columns={buildColumns(user.is_admin, clientID || '', contractID || '')}
        customPageSize={size}
        currentPage={page}
        customElement={
          <Button type="button" color="primary" outline onClick={handleExportData}>
            Exportar dados
          </Button>
        }
        data={data.items}
        loading={isFetching}
        totalItems={data.totalItems}
        handleFilters={setFilters}
        setSearchParams={setSearchParams}
        refeatch={refetch}
        isAddRegister
        isPagination
        isShowingPageLength
        handleAddRegisterClick={() =>
          router.navigate(`/contracts/${contractID}/contracts-transactions/create/${clientID}`)
        }
      />
    </div>
  );
};

export default Transactions;
