import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';

import FullCalendar from '@fullcalendar/react';
import interaction from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import BootstrapTheme from '@fullcalendar/bootstrap';
import esLocale from '@fullcalendar/core/locales/pt-br';

import { IWithRouter } from '@apptypes/WithRouter';
import Spinners from '@common/Spinner';
import withRouter from '@common/withRouter';
import { Page } from '@component/common/Page';
import { getAll } from '@modules/Calendar/services';

const Calendar = ({ router }: IWithRouter) => {
  const { data, isFetching } = useQuery({
    queryKey: ['getAllCalendar'],
    queryFn: () => getAll(1, 100),
    initialData: { items: [], totalItems: 0 },
    retry: 1,
  });

  const events =
    data?.items.map((calendar: any) => ({
      title: calendar.description,
      date: calendar.calendar_date,
      backgroundColor: `rgba(244, 106, 106, ${calendar.recurrent ? 0.7 : 1})`,
      url: `/calendar/${calendar.id}`,
    })) || [];

  const handleDateClick = (e) => {
    router.navigate(`/calendar/create?date=${e.dateStr}`);
  };

  return (
    <Page
      title="Calendário"
      breadcrumb={{
        title: 'Calendário',
        titleHref: '/calendar',
        breadcrumbItem: 'Calendário',
      }}
    >
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              {isFetching ? (
                <Spinners />
              ) : (
                <FullCalendar
                  plugins={[BootstrapTheme, dayGridPlugin, multiMonthPlugin, interaction]}
                  themeSystem="bootstrap"
                  handleWindowResize={true}
                  initialView="dayGridMonth"
                  headerToolbar={{
                    left: 'prev,next',
                    center: 'title',
                    right: 'dayGridWeek,dayGridMonth,multiMonthYear', // user can switch between the two
                  }}
                  locale={esLocale}
                  events={events}
                  editable
                  dateClick={handleDateClick}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(Calendar);
