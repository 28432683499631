import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Button, Card, CardBody, CardTitle, Col } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';

import { getEarnings } from '@modules/Partners/services';
import Spinners from '@component/common/Spinner';
import moment from 'moment';
import { moneyTransform } from '@helpers/transforms/money';

const EarningYear = ({ partnerID }: { partnerID: string }) => {
  const [{ initialDate, lastDate }, setDate] = useState({
    initialDate: moment().startOf('year').format('YYYY-MM-DD'),
    lastDate: moment().endOf('year').format('YYYY-MM-DD'),
  });

  const { data, isFetching } = useQuery({
    queryKey: ['earnings', partnerID, initialDate, lastDate],
    queryFn: () =>
      getEarnings({
        partnerID,
        period: 'month',
        initialDate,
        finalDate: lastDate,
      }),
  });

  const handleDate = (type: 'increase' | 'decrement') => {
    if (type === 'increase') {
      setDate({
        initialDate: moment(initialDate).add(1, 'year').startOf('year').format('YYYY-MM-DD'),
        lastDate: moment(initialDate).add(1, 'year').endOf('year').format('YYYY-MM-DD'),
      });
    } else {
      setDate({
        initialDate: moment(initialDate).subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
        lastDate: moment(initialDate).subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
      });
    }
  };

  const gainsArray = new Array(12).fill(null);
  data?.forEach((item) => {
    const startDate = new Date(item.period_start.replace(/T\d{2}:00:00\.000Z/g, 'T03:00:00.000Z'));
    const startMonth = startDate.getMonth();
    gainsArray[startMonth] = parseFloat(item.period_gain);
  });

  return (
    <Col>
      <Card>
        <CardTitle>Anual</CardTitle>
        <CardBody style={{ padding: 0 }}>
          {isFetching ? (
            <Spinners />
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <Button color="primary" onClick={() => handleDate('decrement')}>
                  <i className="mdi mdi-chevron-left me-0"></i>
                </Button>
                <strong>{moment(initialDate).format('YYYY')}</strong>
                <Button color="primary" onClick={() => handleDate('increase')}>
                  <i className="mdi mdi-chevron-right me-0"></i>
                </Button>
              </div>
              <ReactApexChart
                options={{
                  chart: { height: 280, type: 'line', stacked: !1, toolbar: { show: !1 } },
                  stroke: { width: [0, 2, 5], curve: 'smooth' },
                  plotOptions: { bar: { columnWidth: '20%' } },
                  colors: ['#556ee6'],
                  fill: {
                    gradient: {
                      inverseColors: !1,
                      shade: 'light',
                      type: 'vertical',
                      opacityFrom: 0.85,
                      opacityTo: 0.55,
                      stops: [0, 100, 100, 100],
                    },
                  },
                  labels: [
                    'Jan',
                    'Fev',
                    'Mar',
                    'Abr',
                    'Mai',
                    'Jun',
                    'Jul',
                    'Ago',
                    'Set',
                    'Out',
                    'Nov',
                    'Dez',
                  ],
                  markers: { size: 0 },
                  yaxis: {
                    min: 0,
                    labels: {
                      formatter: (value) => {
                        return moneyTransform(value);
                      },
                    },
                  },
                }}
                series={[
                  {
                    name: 'Ganho total',
                    type: 'column',
                    data: gainsArray || [],
                  },
                ]}
                type="line"
                height={280}
                className="apex-charts"
              />
            </>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default EarningYear;
