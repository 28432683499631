import React from 'react';
import Users from '@modules/Users/pages';
import UserCreate from '@modules/Users/pages/create';
import UserUpdate from '@modules/Users/pages/update';

export const userRoutes = [
  { path: '/users', component: <Users /> },
  { path: '/users/create', component: <UserCreate /> },
  { path: '/users/:userID', component: <UserUpdate /> },
];
