import React from 'react';
import { Button } from 'reactstrap';

interface IAddRegister {
  isAddRegister?: boolean;
  handleAddRegisterClick?: () => void;
}

export const AddRegister = ({ isAddRegister, handleAddRegisterClick }: IAddRegister) => (
  <>
    {isAddRegister && (
      <div className="d-flex justify-content-end align-items-end">
        <Button type="button" color="primary" onClick={handleAddRegisterClick}>
          <i className="mdi mdi-plus-circle-outline me-1" />
          Adicionar registro
        </Button>
      </div>
    )}
  </>
);
