import React from 'react';

import PartnersUpdate from '@modules/Partners/pages/update';
import PartnersContactsCreate from '@modules/Partners/submodules/Contacts/pages/create';
import PartnersContactsUpdate from '@modules/Partners/submodules/Contacts/pages/update';

export const partnerContactsRoutes = [
  { path: '/partners/:partnerID/contacts', component: <PartnersUpdate activePath="contacts" /> },
  {
    path: '/partners/:partnerID/contacts/create',
    component: <PartnersContactsCreate activePath="contacts" />,
  },
  {
    path: '/partners/:partnerID/contacts/:contactID',
    component: <PartnersContactsUpdate activePath="contacts" />,
  },
];
