export const banks: Array<Record<string, any>> = [
  {
    ISPB: '00000000',
    short_name: 'BCO DO BRASIL S.A.',
    number: '001',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco do Brasil S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '00000208',
    short_name: 'BRB - BCO DE BRASILIA S.A.',
    number: '070',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BRB - BANCO DE BRASILIA S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '00204963',
    short_name: 'CCR SEARA',
    number: 430,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'COOPERATIVA DE CREDITO RURAL SEARA - CREDISEARA',
    start_of_operation: '27/08/2021',
  },
  {
    ISPB: '00250699',
    short_name: 'AGK CC S.A.',
    number: 272,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'AGK CORRETORA DE CAMBIO S.A.',
    start_of_operation: '21/11/2018',
  },
  {
    ISPB: '00315557',
    short_name: 'CONF NAC COOP CENTRAIS UNICRED',
    number: 136,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASI',
    start_of_operation: '28/07/2014',
  },
  {
    ISPB: '00329598',
    short_name: 'ÍNDIGO INVESTIMENTOS DTVM LTDA.',
    number: 407,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'ÍNDIGO INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    start_of_operation: '22/02/2021',
  },
  {
    ISPB: '00360305',
    short_name: 'CAIXA ECONOMICA FEDERAL',
    number: 104,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'CAIXA ECONOMICA FEDERAL',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '00416968',
    short_name: 'BANCO INTER',
    number: '077',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Inter S.A.',
    start_of_operation: '15/08/2008',
  },
  {
    ISPB: '00460065',
    short_name: 'COLUNA S.A. DTVM',
    number: 423,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILIÁRIOS',
    start_of_operation: '07/06/2021',
  },
  {
    ISPB: '00517645',
    short_name: 'BCO RIBEIRAO PRETO S.A.',
    number: 741,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BANCO RIBEIRAO PRETO S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '00556603',
    short_name: 'BANCO BARI S.A.',
    number: 330,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.',
    start_of_operation: '22/07/2019',
  },
  {
    ISPB: '00558456',
    short_name: 'BCO CETELEM S.A.',
    number: 739,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Cetelem S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '00795423',
    short_name: 'BANCO SEMEAR',
    number: 743,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Semear S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '00806535',
    short_name: 'PLANNER CV S.A.',
    number: 100,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Planner Corretora de Valores S.A.',
    start_of_operation: '27/04/2011',
  },
  {
    ISPB: '00954288',
    short_name: 'FDO GARANTIDOR CRÉDITOS',
    number: 541,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'FUNDO GARANTIDOR DE CREDITOS - FGC',
    start_of_operation: '08/03/2023',
  },
  {
    ISPB: '00997185',
    short_name: 'BCO B3 S.A.',
    number: '096',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco B3 S.A.',
    start_of_operation: '12/11/2004',
  },
  {
    ISPB: '01023570',
    short_name: 'BCO RABOBANK INTL BRASIL S.A.',
    number: 747,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco Rabobank International Brasil S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '01027058',
    short_name: 'CIELO IP S.A.',
    number: 362,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'CIELO S.A. - INSTITUIÇÃO DE PAGAMENTO',
    start_of_operation: '17/07/2020',
  },
  {
    ISPB: '01073966',
    short_name: 'CCR DE ABELARDO LUZ',
    number: 322,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Cooperativa de Crédito Rural de Abelardo Luz - Sulcredi/Crediluz',
    start_of_operation: '14/06/2019',
  },
  {
    ISPB: '01181521',
    short_name: 'BCO COOPERATIVO SICREDI S.A.',
    number: 748,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BANCO COOPERATIVO SICREDI S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '01330387',
    short_name: 'CREHNOR LARANJEIRAS',
    number: 350,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'COOPERATIVA DE CRÉDITO RURAL DE PEQUENOS AGRICULTORES E DA REFORMA AGRÁRIA DO CE',
    start_of_operation: '27/05/2020',
  },
  {
    ISPB: '01522368',
    short_name: 'BCO BNP PARIBAS BRASIL S A',
    number: 752,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco BNP Paribas Brasil S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '01658426',
    short_name: 'CECM COOPERFORTE',
    number: 379,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'COOPERFORTE - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE FUNCIONÁRIOS DE INSTITU',
    start_of_operation: '01/09/2020',
  },
  {
    ISPB: '01701201',
    short_name: 'KIRTON BANK',
    number: 399,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Kirton Bank S.A. - Banco Múltiplo',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '01852137',
    short_name: 'BCO BRASILEIRO DE CRÉDITO S.A.',
    number: 378,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BANCO BRASILEIRO DE CRÉDITO SOCIEDADE ANÔNIMA',
    start_of_operation: '11/09/2020',
  },
  {
    ISPB: '01858774',
    short_name: 'BCO BV S.A.',
    number: 413,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BANCO BV S.A.',
    start_of_operation: '21/05/2021',
  },
  {
    ISPB: '02038232',
    short_name: 'BANCO SICOOB S.A.',
    number: 756,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '02276653',
    short_name: 'TRINUS CAPITAL DTVM',
    number: 360,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'TRINUS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    start_of_operation: '16/07/2020',
  },
  {
    ISPB: '02318507',
    short_name: 'BCO KEB HANA DO BRASIL S.A.',
    number: 757,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BANCO KEB HANA DO BRASIL S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '02332886',
    short_name: 'XP INVESTIMENTOS CCTVM S/A',
    number: 102,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'XP INVESTIMENTOS CORRETORA DE CÂMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A',
    start_of_operation: '22/11/2011',
  },
  {
    ISPB: '02398976',
    short_name: 'UNIPRIME DO BRASIL - COOP',
    number: '084',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'UNIPRIME DO BRASIL - COOPERATIVA DE CRÉDITO',
    start_of_operation: '24/11/2009',
  },
  {
    ISPB: '02685483',
    short_name: 'CM CAPITAL MARKETS CCTVM LTDA',
    number: 180,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'CM CAPITAL MARKETS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    start_of_operation: '20/01/2017',
  },
  {
    ISPB: '02801938',
    short_name: 'BCO MORGAN STANLEY S.A.',
    number: '066',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BANCO MORGAN STANLEY S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '02819125',
    short_name: 'UBS BRASIL CCTVM S.A.',
    number: '015',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
    start_of_operation: '15/03/2011',
  },
  {
    ISPB: '02992317',
    short_name: 'TREVISO CC S.A.',
    number: 143,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Treviso Corretora de Câmbio S.A.',
    start_of_operation: '24/08/2015',
  },
  {
    ISPB: '03012230',
    short_name: 'HIPERCARD BM S.A.',
    number: '062',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Hipercard Banco Múltiplo S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '03017677',
    short_name: 'BCO. J.SAFRA S.A.',
    number: '074',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco J. Safra S.A.',
    start_of_operation: '26/08/2004',
  },
  {
    ISPB: '03046391',
    short_name: 'UNIPRIME COOPCENTRAL LTDA.',
    number: '099',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'UNIPRIME CENTRAL NACIONAL - CENTRAL NACIONAL DE COOPERATIVA DE CREDITO',
    start_of_operation: '11/01/2011',
  },
  {
    ISPB: '03215790',
    short_name: 'BCO TOYOTA DO BRASIL S.A.',
    number: 387,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco Toyota do Brasil S.A.',
    start_of_operation: '21/07/2020',
  },
  {
    ISPB: '03311443',
    short_name: 'PARATI - CFI S.A.',
    number: 326,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
    start_of_operation: '07/01/2020',
  },
  {
    ISPB: '03323840',
    short_name: 'BCO ALFA S.A.',
    number: '025',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Alfa S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '03532415',
    short_name: 'BCO ABN AMRO S.A.',
    number: '075',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco ABN Amro S.A.',
    start_of_operation: '11/11/2005',
  },
  {
    ISPB: '03609817',
    short_name: 'BCO CARGILL S.A.',
    number: '040',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco Cargill S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '03751794',
    short_name: 'TERRA INVESTIMENTOS DTVM',
    number: 307,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.',
    start_of_operation: '25/10/2018',
  },
  {
    ISPB: '03844699',
    short_name: 'CECM DOS TRAB.PORT. DA G.VITOR',
    number: 385,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS TRABALHADORES PORTUARIOS DA GRANDE V',
    start_of_operation: '08/03/2022',
  },
  {
    ISPB: '03881423',
    short_name: 'SOCINAL S.A. CFI',
    number: 425,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'SOCINAL S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    start_of_operation: '27/07/2021',
  },
  {
    ISPB: '03973814',
    short_name: 'SERVICOOP',
    number: 190,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'SERVICOOP - COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS E MUNICIPAI',
    start_of_operation: '16/10/2017',
  },
  {
    ISPB: '04062902',
    short_name: 'OZ CORRETORA DE CÂMBIO S.A.',
    number: 296,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'OZ CORRETORA DE CÂMBIO S.A.',
    start_of_operation: '11/02/2019',
  },
  {
    ISPB: '04184779',
    short_name: 'BANCO BRADESCARD',
    number: '063',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Bradescard S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '04257795',
    short_name: 'NOVA FUTURA CTVM LTDA.',
    number: 191,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.',
    start_of_operation: '22/06/2017',
  },
  {
    ISPB: '04307598',
    short_name: 'FIDUCIA SCMEPP LTDA',
    number: 382,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE L',
    start_of_operation: '31/07/2020',
  },
  {
    ISPB: '04332281',
    short_name: 'GOLDMAN SACHS DO BRASIL BM S.A',
    number: '064',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '04546162',
    short_name: 'CCM SERV. PÚBLICOS SP',
    number: 459,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'COOPERATIVA DE CRÉDITO MÚTUO DE SERVIDORES PÚBLICOS DO ESTADO DE SÃO PAULO - CRE',
    start_of_operation: '04/11/2021',
  },
  {
    ISPB: '04632856',
    short_name: 'CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.',
    number: '097',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Credisis - Central de Cooperativas de Crédito Ltda.',
    start_of_operation: '08/02/2011',
  },
  {
    ISPB: '04715685',
    short_name: 'CCM DESP TRÂNS SC E RS',
    number: '016',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI',
    start_of_operation: '08/05/2012',
  },
  {
    ISPB: '04814563',
    short_name: 'BCO AFINZ S.A. - BM',
    number: 299,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BANCO AFINZ S.A. - BANCO MÚLTIPLO',
    start_of_operation: '03/12/2018',
  },
  {
    ISPB: '04831810',
    short_name: 'CECM SERV PUBL PINHÃO',
    number: 471,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS SERVIDORES PUBLICOS DE PINHÃO - CRES',
    start_of_operation: '18/07/2022',
  },
  {
    ISPB: '04862600',
    short_name: 'PORTOSEG S.A. CFI',
    number: 468,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'PORTOSEG S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO',
    start_of_operation: '09/02/2022',
  },
  {
    ISPB: '04866275',
    short_name: 'BANCO INBURSA',
    number: '012',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco Inbursa S.A.',
    start_of_operation: '04/05/2017',
  },
  {
    ISPB: '04902979',
    short_name: 'BCO DA AMAZONIA S.A.',
    number: '003',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BANCO DA AMAZONIA S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '04913129',
    short_name: 'CONFIDENCE CC S.A.',
    number: '060',
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'Confidence Corretora de Câmbio S.A.',
    start_of_operation: '21/09/2010',
  },
  {
    ISPB: '04913711',
    short_name: 'BCO DO EST. DO PA S.A.',
    number: '037',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco do Estado do Pará S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '05192316',
    short_name: 'VIA CERTA FINANCIADORA S.A. - CFI',
    number: 411,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Via Certa Financiadora S.A. - Crédito, Financiamento e Investimentos',
    start_of_operation: '22/02/2021',
  },
  {
    ISPB: '05351887',
    short_name: 'ZEMA CFI S/A',
    number: 359,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A',
    start_of_operation: '10/06/2020',
  },
  {
    ISPB: '05442029',
    short_name: 'CASA CREDITO S.A. SCM',
    number: 159,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor',
    start_of_operation: '04/09/2017',
  },
  {
    ISPB: '05463212',
    short_name: 'COOPCENTRAL AILOS',
    number: '085',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Cooperativa Central de Crédito - Ailos',
    start_of_operation: '17/05/2010',
  },
  {
    ISPB: '05491616',
    short_name: 'COOP CREDITAG',
    number: 400,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'COOPERATIVA DE CRÉDITO, POUPANÇA E SERVIÇOS FINANCEIROS DO CENTRO OESTE - CREDIT',
    start_of_operation: '24/02/2021',
  },
  {
    ISPB: '05676026',
    short_name: 'CREDIARE CFI S.A.',
    number: 429,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Crediare S.A. - Crédito, financiamento e investimento',
    start_of_operation: '08/09/2021',
  },
  {
    ISPB: '05684234',
    short_name: 'PLANNER SOCIEDADE DE CRÉDITO DIRETO',
    number: 410,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'PLANNER SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '17/02/2021',
  },
  {
    ISPB: '05790149',
    short_name: 'CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO',
    number: 114,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP',
    start_of_operation: '27/10/2011',
  },
  {
    ISPB: '05841967',
    short_name: 'CECM FABRIC CALÇADOS SAPIRANGA',
    number: 328,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FABRICANTES DE CALÇADOS DE SAPIRANGA',
    start_of_operation: '22/03/2021',
  },
  {
    ISPB: '06271464',
    short_name: 'BCO BBI S.A.',
    number: '036',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Bradesco BBI S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '07138049',
    short_name: 'LIGA INVEST DTVM LTDA.',
    number: 469,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'LIGA INVEST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    start_of_operation: '09/03/2022',
  },
  {
    ISPB: '07207996',
    short_name: 'BCO BRADESCO FINANC. S.A.',
    number: 394,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Bradesco Financiamentos S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '07237373',
    short_name: 'BCO DO NORDESTE DO BRASIL S.A.',
    number: '004',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco do Nordeste do Brasil S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '07253654',
    short_name: 'HEDGE INVESTMENTS DTVM LTDA.',
    number: 458,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'HEDGE INVESTMENTS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    start_of_operation: '08/09/2021',
  },
  {
    ISPB: '07450604',
    short_name: 'BCO CCB BRASIL S.A.',
    number: 320,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'China Construction Bank (Brasil) Banco Múltiplo S/A',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: '07512441',
    short_name: 'HS FINANCEIRA',
    number: 189,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS',
    start_of_operation: '05/06/2017',
  },
  {
    ISPB: '07652226',
    short_name: 'LECCA CFI S.A.',
    number: 105,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Lecca Crédito, Financiamento e Investimento S/A',
    start_of_operation: '27/12/2011',
  },
  {
    ISPB: '07656500',
    short_name: 'BCO KDB BRASIL S.A.',
    number: '076',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco KDB do Brasil S.A.',
    start_of_operation: '10/04/2006',
  },
  {
    ISPB: '07679404',
    short_name: 'BANCO TOPÁZIO S.A.',
    number: '082',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BANCO TOPÁZIO S.A.',
    start_of_operation: '12/06/2009',
  },
  {
    ISPB: '07693858',
    short_name: 'HSCM SCMEPP LTDA.',
    number: 312,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'HSCM - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LT',
    start_of_operation: '09/02/2021',
  },
  {
    ISPB: '07799277',
    short_name: 'VALOR SCD S.A.',
    number: 195,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'VALOR SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '09/08/2021',
  },
  {
    ISPB: '07945233',
    short_name: 'POLOCRED SCMEPP LTDA.',
    number: '093',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'PÓLOCRED   SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT',
    start_of_operation: '09/11/2010',
  },
  {
    ISPB: '08240446',
    short_name: 'CCR DE IBIAM',
    number: 391,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM',
    start_of_operation: '10/07/2020',
  },
  {
    ISPB: '08253539',
    short_name: 'CCR DE SÃO MIGUEL DO OESTE',
    number: 273,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Cooperativa de Crédito Rural de São Miguel do Oeste - Sulcredi/São Miguel',
    start_of_operation: '24/04/2018',
  },
  {
    ISPB: '08357240',
    short_name: 'BCO CSF S.A.',
    number: 368,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco CSF S.A.',
    start_of_operation: '25/05/2020',
  },
  {
    ISPB: '08561701',
    short_name: 'PAGSEGURO INTERNET IP S.A.',
    number: 290,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'PAGSEGURO INTERNET INSTITUIÇÃO DE PAGAMENTO S.A.',
    start_of_operation: '07/11/2018',
  },
  {
    ISPB: '08609934',
    short_name: 'MONEYCORP BCO DE CÂMBIO S.A.',
    number: 259,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'MONEYCORP BANCO DE CÂMBIO S.A.',
    start_of_operation: '27/01/2020',
  },
  {
    ISPB: '08673569',
    short_name: 'F D GOLD DTVM LTDA',
    number: 395,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: "F.D'GOLD - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
    start_of_operation: '01/12/2020',
  },
  {
    ISPB: '09089356',
    short_name: 'EFÍ S.A. - IP',
    number: 364,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'EFÍ S.A. - INSTITUIÇÃO DE PAGAMENTO',
    start_of_operation: '16/03/2020',
  },
  {
    ISPB: '09105360',
    short_name: 'ICAP DO BRASIL CTVM LTDA.',
    number: 157,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.',
    start_of_operation: '12/02/2016',
  },
  {
    ISPB: '09210106',
    short_name: 'SOCRED SA - SCMEPP',
    number: 183,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'SOCRED S.A. - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO P',
    start_of_operation: '12/06/2017',
  },
  {
    ISPB: '09274232',
    short_name: 'STATE STREET BR S.A. BCO COMERCIAL',
    number: '014',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'STATE STREET BRASIL S.A. - BANCO COMERCIAL',
    start_of_operation: '08/08/2008',
  },
  {
    ISPB: '09313766',
    short_name: 'CARUANA SCFI',
    number: 130,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'CARUANA S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    start_of_operation: '12/08/2013',
  },
  {
    ISPB: '09464032',
    short_name: 'MIDWAY S.A. - SCFI',
    number: 358,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'MIDWAY S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    start_of_operation: '01/02/2021',
  },
  {
    ISPB: '09512542',
    short_name: 'CODEPE CVC S.A.',
    number: 127,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'Codepe Corretora de Valores e Câmbio S.A.',
    start_of_operation: '09/11/2012',
  },
  {
    ISPB: '09516419',
    short_name: 'PICPAY BANK - BANCO MÚLTIPLO S.A',
    number: '079',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'PICPAY BANK - BANCO MÚLTIPLO S.A',
    start_of_operation: '25/07/2008',
  },
  {
    ISPB: '09554480',
    short_name: 'SUPERDIGITAL I.P. S.A.',
    number: 340,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'SUPERDIGITAL INSTITUIÇÃO DE PAGAMENTO S.A.',
    start_of_operation: '25/09/2019',
  },
  {
    ISPB: 10264663,
    short_name: 'BANCOSEGURO S.A.',
    number: '081',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BancoSeguro S.A.',
    start_of_operation: '24/10/2008',
  },
  {
    ISPB: 10371492,
    short_name: 'BCO YAMAHA MOTOR S.A.',
    number: 475,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco Yamaha Motor do Brasil S.A.',
    start_of_operation: '05/09/2022',
  },
  {
    ISPB: 10398952,
    short_name: 'CRESOL CONFEDERAÇÃO',
    number: 133,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E',
    start_of_operation: '02/05/2016',
  },
  {
    ISPB: 10573521,
    short_name: 'MERCADO PAGO IP LTDA.',
    number: 323,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'MERCADO PAGO INSTITUIÇÃO DE PAGAMENTO LTDA.',
    start_of_operation: '24/06/2019',
  },
  {
    ISPB: 10664513,
    short_name: 'BCO AGIBANK S.A.',
    number: 121,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco Agibank S.A.',
    start_of_operation: '04/04/2012',
  },
  {
    ISPB: 10690848,
    short_name: 'BCO DA CHINA BRASIL S.A.',
    number: '083',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco da China Brasil S.A.',
    start_of_operation: '14/07/2009',
  },
  {
    ISPB: 10853017,
    short_name: 'GET MONEY CC LTDA',
    number: 138,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'Get Money Corretora de Câmbio S.A.',
    start_of_operation: '14/07/2014',
  },
  {
    ISPB: 10866788,
    short_name: 'BCO BANDEPE S.A.',
    number: '024',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco Bandepe S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 11165756,
    short_name: 'GLOBAL SCM LTDA',
    number: 384,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO',
    start_of_operation: '13/10/2020',
  },
  {
    ISPB: 11285104,
    short_name: 'NEON FINANCEIRA - CFI S.A.',
    number: 426,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'NEON FINANCEIRA - CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
    start_of_operation: '21/05/2021',
  },
  {
    ISPB: 11476673,
    short_name: 'BANCO RANDON S.A.',
    number: '088',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BANCO RANDON S.A.',
    start_of_operation: '03/08/2020',
  },
  {
    ISPB: 11495073,
    short_name: 'OM DTVM LTDA',
    number: 319,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    start_of_operation: '01/03/2019',
  },
  {
    ISPB: 11581339,
    short_name: 'BMP SCMEPP LTDA',
    number: 274,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BMP SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LTDA.',
    start_of_operation: '10/09/2019',
  },
  {
    ISPB: 11703662,
    short_name: 'TRAVELEX BANCO DE CÂMBIO S.A.',
    number: '095',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Travelex Banco de Câmbio S.A.',
    start_of_operation: '22/06/2010',
  },
  {
    ISPB: 11758741,
    short_name: 'BANCO FINAXIS',
    number: '094',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco Finaxis S.A.',
    start_of_operation: '23/08/2010',
  },
  {
    ISPB: 11760553,
    short_name: 'GAZINCRED S.A. SCFI',
    number: 478,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'GAZINCRED S.A. SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    start_of_operation: '21/03/2022',
  },
  {
    ISPB: 11970623,
    short_name: 'BCO SENFF S.A.',
    number: 276,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BANCO SENFF S.A.',
    start_of_operation: '12/06/2018',
  },
  {
    ISPB: 12392983,
    short_name: 'MIRAE ASSET CCTVM LTDA',
    number: 447,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'MIRAE ASSET WEALTH MANAGEMENT (BRAZIL) CORRETORA DE CÂMBIO, TÍTULOS E VALORES MO',
    start_of_operation: '27/09/2021',
  },
  {
    ISPB: 13009717,
    short_name: 'BCO DO EST. DE SE S.A.',
    number: '047',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco do Estado de Sergipe S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 13059145,
    short_name: 'BEXS BCO DE CAMBIO S.A.',
    number: 144,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BEXS BANCO DE CÂMBIO S/A',
    start_of_operation: '23/05/2016',
  },
  {
    ISPB: 13140088,
    short_name: 'ACESSO SOLUÇÕES DE PAGAMENTO S.A. - INSTITUIÇÃO DE PAGAMENTO',
    number: 332,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'ACESSO SOLUÇÕES DE PAGAMENTO S.A. - INSTITUIÇÃO DE PAGAMENTO',
    start_of_operation: '16/08/2019',
  },
  {
    ISPB: 13203354,
    short_name: 'FITBANK IP',
    number: 450,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'FITBANK INSTITUIÇÃO DE PAGAMENTOS ELETRÔNICOS S.A.',
    start_of_operation: '30/06/2021',
  },
  {
    ISPB: 13220493,
    short_name: 'BR PARTNERS BI',
    number: 126,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BR Partners Banco de Investimento S.A.',
    start_of_operation: '04/01/2013',
  },
  {
    ISPB: 13293225,
    short_name: 'ÓRAMA DTVM S.A.',
    number: 325,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Órama Distribuidora de Títulos e Valores Mobiliários S.A.',
    start_of_operation: '31/05/2019',
  },
  {
    ISPB: 13370835,
    short_name: 'DOCK IP S.A.',
    number: 301,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'DOCK INSTITUIÇÃO DE PAGAMENTO S.A.',
    start_of_operation: '26/09/2018',
  },
  {
    ISPB: 13486793,
    short_name: 'BRL TRUST DTVM SA',
    number: 173,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
    start_of_operation: '28/11/2016',
  },
  {
    ISPB: 13673855,
    short_name: 'FRAM CAPITAL DTVM S.A.',
    number: 331,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.',
    start_of_operation: '27/09/2019',
  },
  {
    ISPB: 13720915,
    short_name: 'BCO WESTERN UNION',
    number: 119,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco Western Union do Brasil S.A.',
    start_of_operation: '10/10/2011',
  },
  {
    ISPB: 13884775,
    short_name: 'HUB IP S.A.',
    number: 396,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'HUB INSTITUIÇÃO DE PAGAMENTO S.A.',
    start_of_operation: '13/08/2020',
  },
  {
    ISPB: 13935893,
    short_name: 'CELCOIN IP S.A.',
    number: 509,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'CELCOIN INSTITUICAO DE PAGAMENTO S.A.',
    start_of_operation: '23/01/2023',
  },
  {
    ISPB: 14190547,
    short_name: 'CAMBIONET CC LTDA',
    number: 309,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'CAMBIONET CORRETORA DE CÂMBIO LTDA.',
    start_of_operation: '21/11/2018',
  },
  {
    ISPB: 14388334,
    short_name: 'PARANA BCO S.A.',
    number: 254,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'PARANÁ BANCO S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 14511781,
    short_name: 'BARI CIA HIPOTECÁRIA',
    number: 268,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'BARI COMPANHIA HIPOTECÁRIA',
    start_of_operation: '21/12/2017',
  },
  {
    ISPB: 15111975,
    short_name: 'IUGU IP S.A.',
    number: 401,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'IUGU INSTITUIÇÃO DE PAGAMENTO S.A.',
    start_of_operation: '06/05/2021',
  },
  {
    ISPB: 15114366,
    short_name: 'BCO BOCOM BBM S.A.',
    number: 107,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Bocom BBM S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 15124464,
    short_name: 'BANCO BESA S.A.',
    number: 334,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BANCO BESA S.A.',
    start_of_operation: '22/02/2023',
  },
  {
    ISPB: 15173776,
    short_name: 'SOCIAL BANK S/A',
    number: 412,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'SOCIAL BANK BANCO MÚLTIPLO S/A',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 15357060,
    short_name: 'BCO WOORI BANK DO BRASIL S.A.',
    number: 124,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Woori Bank do Brasil S.A.',
    start_of_operation: '09/08/2012',
  },
  {
    ISPB: 15581638,
    short_name: 'FACTA S.A. CFI',
    number: 149,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Facta Financeira S.A. - Crédito Financiamento e Investimento',
    start_of_operation: '08/07/2015',
  },
  {
    ISPB: 16501555,
    short_name: 'STONE IP S.A.',
    number: 197,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'STONE INSTITUIÇÃO DE PAGAMENTO S.A.',
    start_of_operation: '18/10/2017',
  },
  {
    ISPB: 16695922,
    short_name: 'ID CTVM',
    number: 439,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'ID CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    start_of_operation: '30/09/2021',
  },
  {
    ISPB: 16927221,
    short_name: 'AMAZÔNIA CC LTDA.',
    number: 313,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'AMAZÔNIA CORRETORA DE CÂMBIO LTDA.',
    start_of_operation: '28/08/2020',
  },
  {
    ISPB: 16944141,
    short_name: 'BROKER BRASIL CC LTDA.',
    number: 142,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Broker Brasil Corretora de Câmbio Ltda.',
    start_of_operation: '23/06/2015',
  },
  {
    ISPB: 17079937,
    short_name: 'PINBANK IP',
    number: 529,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'PINBANK BRASIL INSTITUIÇÃO DE PAGAMENTO S.A.',
    start_of_operation: '16/11/2022',
  },
  {
    ISPB: 17184037,
    short_name: 'BCO MERCANTIL DO BRASIL S.A.',
    number: 389,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Mercantil do Brasil S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 17298092,
    short_name: 'BCO ITAÚ BBA S.A.',
    number: 184,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco Itaú BBA S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 17351180,
    short_name: 'BCO TRIANGULO S.A.',
    number: 634,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BANCO TRIANGULO S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 17352220,
    short_name: 'SENSO CCVM S.A.',
    number: 545,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A',
    start_of_operation: '27/09/2010',
  },
  {
    ISPB: 17453575,
    short_name: 'ICBC DO BRASIL BM S.A.',
    number: 132,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'ICBC do Brasil Banco Múltiplo S.A.',
    start_of_operation: '26/09/2013',
  },
  {
    ISPB: 17772370,
    short_name: 'VIPS CC LTDA.',
    number: 298,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: "Vip's Corretora de Câmbio Ltda.",
    start_of_operation: '15/08/2018',
  },
  {
    ISPB: 17826860,
    short_name: 'BMS SCD S.A.',
    number: 377,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BMS SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '01/10/2020',
  },
  {
    ISPB: 18188384,
    short_name: 'CREFAZ SCMEPP LTDA',
    number: 321,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT',
    start_of_operation: '11/06/2019',
  },
  {
    ISPB: 18236120,
    short_name: 'NU PAGAMENTOS - IP',
    number: 260,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'NU PAGAMENTOS S.A. - INSTITUIÇÃO DE PAGAMENTO',
    start_of_operation: '10/10/2017',
  },
  {
    ISPB: 18394228,
    short_name: 'CDC SCD S.A.',
    number: 470,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'CDC SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '15/03/2022',
  },
  {
    ISPB: 18520834,
    short_name: 'UBS BRASIL BI S.A.',
    number: 129,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'UBS Brasil Banco de Investimento S.A.',
    start_of_operation: '12/03/2014',
  },
  {
    ISPB: 19307785,
    short_name: 'MS BANK S.A. BCO DE CÂMBIO',
    number: 128,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'MS Bank S.A. Banco de Câmbio',
    start_of_operation: '27/04/2015',
  },
  {
    ISPB: 19324634,
    short_name: 'LAMARA SCD S.A.',
    number: 416,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'LAMARA SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '08/04/2021',
  },
  {
    ISPB: 19540550,
    short_name: 'ASAAS IP S.A.',
    number: 461,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'ASAAS GESTÃO FINANCEIRA INSTITUIÇÃO DE PAGAMENTO S.A.',
    start_of_operation: '29/10/2021',
  },
  {
    ISPB: 20155248,
    short_name: 'PARMETAL DTVM LTDA',
    number: 194,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    start_of_operation: '25/10/2017',
  },
  {
    ISPB: 20855875,
    short_name: 'NEON PAGAMENTOS S.A. IP',
    number: 536,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'NEON PAGAMENTOS S.A. - INSTITUIÇÃO DE PAGAMENTO',
    start_of_operation: '12/01/2023',
  },
  {
    ISPB: 21018182,
    short_name: 'EBANX IP LTDA.',
    number: 383,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'EBANX INSTITUICAO DE PAGAMENTOS LTDA.',
    start_of_operation: '09/07/2020',
  },
  {
    ISPB: 21332862,
    short_name: 'CARTOS SCD S.A.',
    number: 324,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '16/09/2020',
  },
  {
    ISPB: 22610500,
    short_name: 'VORTX DTVM LTDA.',
    number: 310,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
    start_of_operation: '18/10/2018',
  },
  {
    ISPB: 22896431,
    short_name: 'PICPAY',
    number: 380,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'PICPAY INSTITUIÇÃO DE PAGAMENTO S.A.',
    start_of_operation: '15/10/2020',
  },
  {
    ISPB: 23522214,
    short_name: 'COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO',
    number: 163,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Commerzbank Brasil S.A. - Banco Múltiplo',
    start_of_operation: '10/08/2016',
  },
  {
    ISPB: 23862762,
    short_name: 'WILL FINANCEIRA S.A.CFI',
    number: 280,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'WILL FINANCEIRA S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    start_of_operation: '10/05/2018',
  },
  {
    ISPB: 24074692,
    short_name: 'GUITTA CC LTDA',
    number: 146,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'GUITTA CORRETORA DE CAMBIO LTDA.',
    start_of_operation: '20/05/2015',
  },
  {
    ISPB: 24537861,
    short_name: 'FFA SCMEPP LTDA.',
    number: 343,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.',
    start_of_operation: '12/11/2019',
  },
  {
    ISPB: 26563270,
    short_name: 'COOP DE PRIMAVERA DO LESTE',
    number: 279,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'PRIMACREDI COOPERATIVA DE CRÉDITO DE PRIMAVERA DO LESTE',
    start_of_operation: '05/06/2018',
  },
  {
    ISPB: 27098060,
    short_name: 'BANCO DIGIO',
    number: 335,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco Digio S.A.',
    start_of_operation: '25/11/2019',
  },
  {
    ISPB: 27214112,
    short_name: 'AL5 S.A. CFI',
    number: 349,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    start_of_operation: '24/10/2019',
  },
  {
    ISPB: 27302181,
    short_name: 'CRED-UFES',
    number: 427,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'COOPERATIVA DE CREDITO DOS SERVIDORES DA UNIVERSIDADE FEDERAL DO ESPIRITO SANTO',
    start_of_operation: '26/04/2021',
  },
  {
    ISPB: 27351731,
    short_name: 'REALIZE CFI S.A.',
    number: 374,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
    start_of_operation: '16/09/2020',
  },
  {
    ISPB: 27652684,
    short_name: 'GENIAL INVESTIMENTOS CVM S.A.',
    number: 278,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Genial Investimentos Corretora de Valores Mobiliários S.A.',
    start_of_operation: '14/05/2018',
  },
  {
    ISPB: 27842177,
    short_name: 'IB CCTVM S.A.',
    number: 271,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
    start_of_operation: '26/02/2018',
  },
  {
    ISPB: 28127603,
    short_name: 'BCO BANESTES S.A.',
    number: '021',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 28195667,
    short_name: 'BCO ABC BRASIL S.A.',
    number: 246,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco ABC Brasil S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 28650236,
    short_name: 'BS2 DTVM S.A.',
    number: 292,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BS2 Distribuidora de Títulos e Valores Mobiliários S.A.',
    start_of_operation: '24/10/2018',
  },
  {
    ISPB: 29030467,
    short_name: 'SCOTIABANK BRASIL',
    number: 751,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Scotiabank Brasil S.A. Banco Múltiplo',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 29162769,
    short_name: 'TORO CTVM S.A.',
    number: 352,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    start_of_operation: '29/11/2019',
  },
  {
    ISPB: 30306294,
    short_name: 'BANCO BTG PACTUAL S.A.',
    number: 208,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco BTG Pactual S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 30680829,
    short_name: 'NU FINANCEIRA S.A. CFI',
    number: 386,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'NU FINANCEIRA S.A. - Sociedade de Crédito, Financiamento e Investimento',
    start_of_operation: '12/01/2021',
  },
  {
    ISPB: 30723886,
    short_name: 'BCO MODAL S.A.',
    number: 746,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Modal S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 31597552,
    short_name: 'BCO CLASSICO S.A.',
    number: 241,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BANCO CLASSICO S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 31749596,
    short_name: 'IDEAL CTVM S.A.',
    number: 398,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'IDEAL CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    start_of_operation: '11/01/2021',
  },
  {
    ISPB: 31872495,
    short_name: 'BCO C6 S.A.',
    number: 336,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco C6 S.A.',
    start_of_operation: '31/01/2019',
  },
  {
    ISPB: 31880826,
    short_name: 'BCO GUANABARA S.A.',
    number: 612,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Guanabara S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 31895683,
    short_name: 'BCO INDUSTRIAL DO BRASIL S.A.',
    number: 604,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Industrial do Brasil S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 32062580,
    short_name: 'BCO CREDIT SUISSE S.A.',
    number: 505,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Credit Suisse (Brasil) S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 32402502,
    short_name: 'QI SCD S.A.',
    number: 329,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'QI Sociedade de Crédito Direto S.A.',
    start_of_operation: '30/04/2019',
  },
  {
    ISPB: 32648370,
    short_name: 'FAIR CC S.A.',
    number: 196,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'FAIR CORRETORA DE CAMBIO S.A.',
    start_of_operation: '11/09/2017',
  },
  {
    ISPB: 32997490,
    short_name: 'CREDITAS SCD',
    number: 342,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Creditas Sociedade de Crédito Direto S.A.',
    start_of_operation: '26/07/2019',
  },
  {
    ISPB: 33042151,
    short_name: 'BCO LA NACION ARGENTINA',
    number: 300,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco de la Nacion Argentina',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 33042953,
    short_name: 'CITIBANK N.A.',
    number: 477,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Citibank N.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 33132044,
    short_name: 'BCO CEDULA S.A.',
    number: 266,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BANCO CEDULA S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 33147315,
    short_name: 'BCO BRADESCO BERJ S.A.',
    number: 122,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Bradesco BERJ S.A.',
    start_of_operation: '08/11/2011',
  },
  {
    ISPB: 33172537,
    short_name: 'BCO J.P. MORGAN S.A.',
    number: 376,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BANCO J.P. MORGAN S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 33264668,
    short_name: 'BCO XP S.A.',
    number: 348,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco XP S.A.',
    start_of_operation: '01/11/2019',
  },
  {
    ISPB: 33466988,
    short_name: 'BCO CAIXA GERAL BRASIL S.A.',
    number: 473,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco Caixa Geral - Brasil S.A.',
    start_of_operation: '31/03/2009',
  },
  {
    ISPB: 33479023,
    short_name: 'BCO CITIBANK S.A.',
    number: 745,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Citibank S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 33603457,
    short_name: 'BCO RODOBENS S.A.',
    number: 120,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BANCO RODOBENS S.A.',
    start_of_operation: '06/02/2012',
  },
  {
    ISPB: 33644196,
    short_name: 'BCO FATOR S.A.',
    number: 265,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Fator S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 33657248,
    short_name: 'BNDES',
    number: '007',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL',
    start_of_operation: '30/06/2014',
  },
  {
    ISPB: 33775974,
    short_name: 'ATIVA S.A. INVESTIMENTOS CCTVM',
    number: 188,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CÂMBIO E VALORES',
    start_of_operation: '28/07/2017',
  },
  {
    ISPB: 33862244,
    short_name: 'BGC LIQUIDEZ DTVM LTDA',
    number: 134,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    start_of_operation: '28/07/2014',
  },
  {
    ISPB: 33885724,
    short_name: 'BANCO ITAÚ CONSIGNADO S.A.',
    number: '029',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco Itaú Consignado S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 33886862,
    short_name: 'MASTER S/A CCTVM',
    number: 467,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'MASTER S/A CORRETORA DE CâMBIO, TíTULOS E VALORES MOBILIáRIOS',
    start_of_operation: '16/12/2021',
  },
  {
    ISPB: 33923798,
    short_name: 'BANCO MASTER',
    number: 243,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BANCO MASTER S/A',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 34088029,
    short_name: 'LISTO SCD S.A.',
    number: 397,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'LISTO SOCIEDADE DE CREDITO DIRETO S.A.',
    start_of_operation: '15/10/2020',
  },
  {
    ISPB: 34111187,
    short_name: 'HAITONG BI DO BRASIL S.A.',
    number: '078',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Haitong Banco de Investimento do Brasil S.A.',
    start_of_operation: '31/07/2008',
  },
  {
    ISPB: 34265629,
    short_name: 'INTERCAM CC LTDA',
    number: 525,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'INTERCAM CORRETORA DE CÂMBIO LTDA.',
    start_of_operation: '05/12/2022',
  },
  {
    ISPB: 34335592,
    short_name: 'ÓTIMO SCD S.A.',
    number: 355,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '21/10/2019',
  },
  {
    ISPB: 34711571,
    short_name: 'VITREO DTVM S.A.',
    number: 367,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    start_of_operation: '12/03/2020',
  },
  {
    ISPB: 34829992,
    short_name: 'REAG DTVM S.A.',
    number: 528,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'REAG DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    start_of_operation: '06/03/2023',
  },
  {
    ISPB: 35551187,
    short_name: 'PLANTAE CFI',
    number: 445,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'PLANTAE S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    start_of_operation: '23/06/2021',
  },
  {
    ISPB: 35977097,
    short_name: 'UP.P SEP S.A.',
    number: 373,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.',
    start_of_operation: '25/03/2020',
  },
  {
    ISPB: 36113876,
    short_name: 'OLIVEIRA TRUST DTVM S.A.',
    number: 111,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A.',
    start_of_operation: '24/03/2011',
  },
  {
    ISPB: 36266751,
    short_name: 'FINVEST DTVM',
    number: 512,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'FINVEST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    start_of_operation: '01/08/2022',
  },
  {
    ISPB: 36583700,
    short_name: 'FC FINANCEIRA S.A. - CFI',
    number: 516,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'FC FINANCEIRA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    start_of_operation: '24/10/2022',
  },
  {
    ISPB: 36586946,
    short_name: 'BONUSPAGO SCD S.A.',
    number: 408,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BONUSPAGO SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '14/10/2020',
  },
  {
    ISPB: 36864992,
    short_name: 'MAF DTVM SA',
    number: 484,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'MAF DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    start_of_operation: '20/07/2022',
  },
  {
    ISPB: 36947229,
    short_name: 'COBUCCIO S.A. SCFI',
    number: 402,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'COBUCCIO S/A - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTOS',
    start_of_operation: '03/12/2020',
  },
  {
    ISPB: 37229413,
    short_name: 'SCFI EFÍ S.A.',
    number: 507,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO EFÍ S.A.',
    start_of_operation: '12/12/2022',
  },
  {
    ISPB: 37241230,
    short_name: 'SUMUP SCD S.A.',
    number: 404,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '16/10/2020',
  },
  {
    ISPB: 37414009,
    short_name: 'ZIPDIN SCD S.A.',
    number: 418,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'ZIPDIN SOLUÇÕES DIGITAIS SOCIEDADE DE CRÉDITO DIRETO S/A',
    start_of_operation: '26/08/2021',
  },
  {
    ISPB: 37526080,
    short_name: 'LEND SCD S.A.',
    number: 414,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'LEND SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '25/03/2021',
  },
  {
    ISPB: 37555231,
    short_name: 'DM',
    number: 449,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'DM SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '22/10/2021',
  },
  {
    ISPB: 37679449,
    short_name: 'MERCADO CRÉDITO SCFI S.A.',
    number: 518,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'MERCADO CRÉDITO SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
    start_of_operation: '30/01/2023',
  },
  {
    ISPB: 37715993,
    short_name: 'ACCREDITO SCD S.A.',
    number: 406,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'ACCREDITO - SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '27/11/2020',
  },
  {
    ISPB: 37880206,
    short_name: 'CORA SCD S.A.',
    number: 403,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'CORA SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '29/09/2020',
  },
  {
    ISPB: 38129006,
    short_name: 'NUMBRS SCD S.A.',
    number: 419,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'NUMBRS SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '18/03/2021',
  },
  {
    ISPB: 38224857,
    short_name: 'DELCRED SCD S.A.',
    number: 435,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'DELCRED SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '27/08/2021',
  },
  {
    ISPB: 38429045,
    short_name: 'FÊNIX DTVM LTDA.',
    number: 455,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'FÊNIX DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    start_of_operation: '05/12/2022',
  },
  {
    ISPB: 39343350,
    short_name: 'CC LAR CREDI',
    number: 421,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'LAR COOPERATIVA DE CRÉDITO - LAR CREDI',
    start_of_operation: '21/07/2021',
  },
  {
    ISPB: 39416705,
    short_name: 'CREDIHOME SCD',
    number: 443,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'CREDIHOME SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '18/11/2021',
  },
  {
    ISPB: 39519944,
    short_name: 'MARU SCD S.A.',
    number: 535,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'MARÚ SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '10/02/2023',
  },
  {
    ISPB: 39587424,
    short_name: 'UY3 SCD S/A',
    number: 457,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'UY3 SOCIEDADE DE CRÉDITO DIRETO S/A',
    start_of_operation: '26/10/2021',
  },
  {
    ISPB: 39664698,
    short_name: 'CREDSYSTEM SCD S.A.',
    number: 428,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'CREDSYSTEM SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '20/04/2021',
  },
  {
    ISPB: 39669186,
    short_name: 'HEMERA DTVM LTDA.',
    number: 448,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'HEMERA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    start_of_operation: '17/08/2021',
  },
  {
    ISPB: 39676772,
    short_name: 'CREDIFIT SCD S.A.',
    number: 452,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'CREDIFIT SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '15/09/2021',
  },
  {
    ISPB: 39738065,
    short_name: 'FFCRED SCD S.A.',
    number: 510,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'FFCRED SOCIEDADE DE CRÉDITO DIRETO S.A..',
    start_of_operation: '30/11/2022',
  },
  {
    ISPB: 39908427,
    short_name: 'STARK SCD S.A.',
    number: 462,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'STARK SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '01/10/2021',
  },
  {
    ISPB: 40083667,
    short_name: 'CAPITAL CONSIG SCD S.A.',
    number: 465,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'CAPITAL CONSIG SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '03/12/2021',
  },
  {
    ISPB: 40303299,
    short_name: 'PORTOPAR DTVM LTDA',
    number: 306,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
    start_of_operation: '06/02/2019',
  },
  {
    ISPB: 40434681,
    short_name: 'AZUMI DTVM',
    number: 463,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'AZUMI DISTRIBUIDORA DE TíTULOS E VALORES MOBILIáRIOS LTDA.',
    start_of_operation: '21/12/2021',
  },
  {
    ISPB: 40475846,
    short_name: 'J17 - SCD S/A',
    number: 451,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'J17 - SOCIEDADE DE CRÉDITO DIRETO S/A',
    start_of_operation: '18/01/2022',
  },
  {
    ISPB: 40654622,
    short_name: 'TRINUS SCD S.A.',
    number: 444,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'TRINUS SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '09/09/2021',
  },
  {
    ISPB: 40768766,
    short_name: 'LIONS TRUST DTVM',
    number: 519,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'LIONS TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    start_of_operation: '12/12/2022',
  },
  {
    ISPB: 41592532,
    short_name: 'MÉRITO DTVM LTDA.',
    number: 454,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'MÉRITO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    start_of_operation: '23/11/2021',
  },
  {
    ISPB: 42047025,
    short_name: 'UNAVANTI SCD S/A',
    number: 460,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'UNAVANTI SOCIEDADE DE CRÉDITO DIRETO S/A',
    start_of_operation: '18/01/2022',
  },
  {
    ISPB: 42066258,
    short_name: 'RJI',
    number: 506,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'RJI CORRETORA DE TITULOS E VALORES MOBILIARIOS LTDA',
    start_of_operation: '21/07/2022',
  },
  {
    ISPB: 42259084,
    short_name: 'SBCASH SCD',
    number: 482,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'SBCASH SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '11/10/2022',
  },
  {
    ISPB: 42272526,
    short_name: 'BNY MELLON BCO S.A.',
    number: '017',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BNY Mellon Banco S.A.',
    start_of_operation: '19/11/2012',
  },
  {
    ISPB: 43180355,
    short_name: 'PEFISA S.A. - C.F.I.',
    number: 174,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'PEFISA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
    start_of_operation: '21/11/2017',
  },
  {
    ISPB: 43599047,
    short_name: 'SUPERLÓGICA SCD S.A.',
    number: 481,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'SUPERLÓGICA SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '29/08/2022',
  },
  {
    ISPB: 44019481,
    short_name: 'PEAK SEP S.A.',
    number: 521,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'PEAK SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.',
    start_of_operation: '27/01/2023',
  },
  {
    ISPB: 44077014,
    short_name: 'BR-CAPITAL DTVM S.A.',
    number: 433,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BR-CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    start_of_operation: '21/06/2021',
  },
  {
    ISPB: 44189447,
    short_name: 'BCO LA PROVINCIA B AIRES BCE',
    number: 495,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco de La Provincia de Buenos Aires',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 44292580,
    short_name: 'HR DIGITAL SCD',
    number: 523,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'HR DIGITAL - SOCIEDADE DE CRÉDITO DIRETO S/A',
    start_of_operation: '15/02/2023',
  },
  {
    ISPB: 44478623,
    short_name: 'ATICCA SCD S.A.',
    number: 527,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'ATICCA - SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '06/02/2023',
  },
  {
    ISPB: 44683140,
    short_name: 'MAGNUM SCD',
    number: 511,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'MAGNUM SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '22/08/2022',
  },
  {
    ISPB: 44728700,
    short_name: 'ATF CREDIT SCD S.A.',
    number: 513,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'ATF CREDIT SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '02/12/2022',
  },
  {
    ISPB: 45246410,
    short_name: 'BANCO GENIAL',
    number: 125,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BANCO GENIAL S.A.',
    start_of_operation: '19/11/2012',
  },
  {
    ISPB: 45756448,
    short_name: 'MICROCASH SCMEPP LTDA.',
    number: 537,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'MICROCASH SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE',
    start_of_operation: '06/03/2023',
  },
  {
    ISPB: 45854066,
    short_name: 'WNT CAPITAL DTVM',
    number: 524,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'WNT CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    start_of_operation: '01/03/2023',
  },
  {
    ISPB: 46026562,
    short_name: 'MONETARIE SCD',
    number: 526,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'MONETARIE SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '20/01/2023',
  },
  {
    ISPB: 46518205,
    short_name: 'JPMORGAN CHASE BANK',
    number: 488,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'JPMorgan Chase Bank, National Association',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 47873449,
    short_name: 'SER FINANCE SCD S.A.',
    number: 530,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'SER FINANCE SOCIEDADE DE CRÉDITO DIRETO S.A.',
    start_of_operation: '14/03/2023',
  },
  {
    ISPB: 48795256,
    short_name: 'BCO ANDBANK S.A.',
    number: '065',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco AndBank (Brasil) S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 50579044,
    short_name: 'LEVYCAM CCV LTDA',
    number: 145,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA.',
    start_of_operation: '16/06/2015',
  },
  {
    ISPB: 50585090,
    short_name: 'BCV - BCO, CRÉDITO E VAREJO S.A.',
    number: 250,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BCV - BANCO DE CRÉDITO E VAREJO S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 52937216,
    short_name: 'BEXS CC S.A.',
    number: 253,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'Bexs Corretora de Câmbio S/A',
    start_of_operation: '11/11/2016',
  },
  {
    ISPB: 53518684,
    short_name: 'BCO HSBC S.A.',
    number: 269,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BANCO HSBC S.A.',
    start_of_operation: '07/02/2018',
  },
  {
    ISPB: 54403563,
    short_name: 'BCO ARBI S.A.',
    number: 213,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Arbi S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 55230916,
    short_name: 'INTESA SANPAOLO BRASIL S.A. BM',
    number: 139,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Intesa Sanpaolo Brasil S.A. - Banco Múltiplo',
    start_of_operation: '08/05/2015',
  },
  {
    ISPB: 57839805,
    short_name: 'BCO TRICURY S.A.',
    number: '018',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Tricury S.A.',
    start_of_operation: '05/08/2013',
  },
  {
    ISPB: 58160789,
    short_name: 'BCO SAFRA S.A.',
    number: 422,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Safra S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 58497702,
    short_name: 'BCO LETSBANK S.A.',
    number: 630,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BANCO LETSBANK S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 58616418,
    short_name: 'BCO FIBRA S.A.',
    number: 224,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Fibra S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 59109165,
    short_name: 'BCO VOLKSWAGEN S.A',
    number: 393,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco Volkswagen S.A.',
    start_of_operation: '17/08/2020',
  },
  {
    ISPB: 59118133,
    short_name: 'BCO LUSO BRASILEIRO S.A.',
    number: 600,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Luso Brasileiro S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 59274605,
    short_name: 'BCO GM S.A.',
    number: 390,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BANCO GM S.A.',
    start_of_operation: '22/07/2020',
  },
  {
    ISPB: 59285411,
    short_name: 'BANCO PAN',
    number: 623,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Pan S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 59588111,
    short_name: 'BCO VOTORANTIM S.A.',
    number: 655,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Votorantim S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 60394079,
    short_name: 'BCO ITAUBANK S.A.',
    number: 479,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco ItauBank S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 60498557,
    short_name: 'BCO MUFG BRASIL S.A.',
    number: 456,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco MUFG Brasil S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 60518222,
    short_name: 'BCO SUMITOMO MITSUI BRASIL S.A.',
    number: 464,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco Sumitomo Mitsui Brasileiro S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 60701190,
    short_name: 'ITAÚ UNIBANCO S.A.',
    number: 341,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'ITAÚ UNIBANCO S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 60746948,
    short_name: 'BCO BRADESCO S.A.',
    number: 237,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Bradesco S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 60814191,
    short_name: 'BCO MERCEDES-BENZ S.A.',
    number: 381,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BANCO MERCEDES-BENZ DO BRASIL S.A.',
    start_of_operation: '25/05/2020',
  },
  {
    ISPB: 60850229,
    short_name: 'OMNI BANCO S.A.',
    number: 613,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Omni Banco S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 60889128,
    short_name: 'BCO SOFISA S.A.',
    number: 637,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BANCO SOFISA S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 61024352,
    short_name: 'BANCO VOITER',
    number: 653,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BANCO VOITER S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 61033106,
    short_name: 'BCO CREFISA S.A.',
    number: '069',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Crefisa S.A.',
    start_of_operation: '18/11/2005',
  },
  {
    ISPB: 61088183,
    short_name: 'BCO MIZUHO S.A.',
    number: 370,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco Mizuho do Brasil S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 61182408,
    short_name: 'BANCO INVESTCRED UNIBANCO S.A.',
    number: 249,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Investcred Unibanco S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 61186680,
    short_name: 'BCO BMG S.A.',
    number: 318,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco BMG S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 61348538,
    short_name: 'BCO C6 CONSIG',
    number: 626,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BANCO C6 CONSIGNADO S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 61384004,
    short_name: 'AVENUE SECURITIES DTVM LTDA.',
    number: 508,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'AVENUE SECURITIES DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    start_of_operation: '18/08/2022',
  },
  {
    ISPB: 61444949,
    short_name: 'SAGITUR CC',
    number: 270,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'SAGITUR CORRETORA DE CÂMBIO S.A.',
    start_of_operation: '01/08/2018',
  },
  {
    ISPB: 61533584,
    short_name: 'BCO SOCIETE GENERALE BRASIL',
    number: 366,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BANCO SOCIETE GENERALE BRASIL S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 61723847,
    short_name: 'NEON CTVM S.A.',
    number: 113,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'NEON CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    start_of_operation: '28/11/2011',
  },
  {
    ISPB: 61747085,
    short_name: 'TULLETT PREBON BRASIL CVC LTDA',
    number: 131,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA',
    start_of_operation: '15/07/2013',
  },
  {
    ISPB: 61809182,
    short_name: 'C.SUISSE HEDGING-GRIFFO CV S/A',
    number: '011',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A',
    start_of_operation: '28/06/2011',
  },
  {
    ISPB: 61820817,
    short_name: 'BCO PAULISTA S.A.',
    number: 611,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Paulista S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 62073200,
    short_name: 'BOFA MERRILL LYNCH BM S.A.',
    number: 755,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Bank of America Merrill Lynch Banco Múltiplo S.A.',
    start_of_operation: '09/11/2007',
  },
  {
    ISPB: 62109566,
    short_name: 'CREDISAN CC',
    number: '089',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'CREDISAN COOPERATIVA DE CRÉDITO',
    start_of_operation: '30/08/2010',
  },
  {
    ISPB: 62144175,
    short_name: 'BCO PINE S.A.',
    number: 643,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Pine S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 62169875,
    short_name: 'NU INVEST CORRETORA DE VALORES S.A.',
    number: 140,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'NU INVEST CORRETORA DE VALORES S.A.',
    start_of_operation: '26/05/2015',
  },
  {
    ISPB: 62232889,
    short_name: 'BCO DAYCOVAL S.A',
    number: 707,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Daycoval S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 62237649,
    short_name: 'CAROL DTVM LTDA.',
    number: 288,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
    start_of_operation: '05/07/2018',
  },
  {
    ISPB: 62285390,
    short_name: 'SINGULARE CTVM S.A.',
    number: 363,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
    start_of_operation: '01/06/2020',
  },
  {
    ISPB: 62287735,
    short_name: 'RENASCENCA DTVM LTDA',
    number: 101,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    start_of_operation: '22/04/2010',
  },
  {
    ISPB: 62331228,
    short_name: 'DEUTSCHE BANK S.A.BCO ALEMAO',
    number: 487,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'DEUTSCHE BANK S.A. - BANCO ALEMAO',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 62421979,
    short_name: 'BANCO CIFRA',
    number: 233,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco Cifra S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 65913436,
    short_name: 'GUIDE',
    number: 177,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Guide Investimentos S.A. Corretora de Valores',
    start_of_operation: '08/05/2017',
  },
  {
    ISPB: 67030395,
    short_name: 'TRUSTEE DTVM LTDA.',
    number: 438,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'TRUSTEE DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
    start_of_operation: '04/05/2021',
  },
  {
    ISPB: 68757681,
    short_name: 'SIMPAUL',
    number: 365,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'SIMPAUL CORRETORA DE CAMBIO E VALORES MOBILIARIOS  S.A.',
    start_of_operation: '17/03/2020',
  },
  {
    ISPB: 68900810,
    short_name: 'BCO RENDIMENTO S.A.',
    number: 633,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Rendimento S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 71027866,
    short_name: 'BCO BS2 S.A.',
    number: 218,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco BS2 S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 71590442,
    short_name: 'LASTRO RDV DTVM LTDA',
    number: 293,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.',
    start_of_operation: '10/09/2018',
  },
  {
    ISPB: 71677850,
    short_name: 'FRENTE CC LTDA.',
    number: 285,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Frente Corretora de Câmbio Ltda.',
    start_of_operation: '25/09/2018',
  },
  {
    ISPB: 73622748,
    short_name: 'B&T CC LTDA.',
    number: '080',
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'B&T CORRETORA DE CAMBIO LTDA.',
    start_of_operation: '18/11/2010',
  },
  {
    ISPB: 74828799,
    short_name: 'NOVO BCO CONTINENTAL S.A. - BM',
    number: 753,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Novo Banco Continental S.A. - Banco Múltiplo',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 75647891,
    short_name: 'BCO CRÉDIT AGRICOLE BR S.A.',
    number: 222,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BANCO CRÉDIT AGRICOLE BRASIL S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 76461557,
    short_name: 'CCR COOPAVEL',
    number: 281,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Cooperativa de Crédito Rural Coopavel',
    start_of_operation: '05/11/2018',
  },
  {
    ISPB: 76543115,
    short_name: 'BANCO SISTEMA',
    number: 754,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco Sistema S.A.',
    start_of_operation: '15/07/2015',
  },
  {
    ISPB: 76641497,
    short_name: 'DOURADA CORRETORA',
    number: 311,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'DOURADA CORRETORA DE CÂMBIO LTDA.',
    start_of_operation: '22/01/2021',
  },
  {
    ISPB: 78157146,
    short_name: 'CREDIALIANÇA CCR',
    number: '098',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Credialiança Cooperativa de Crédito Rural',
    start_of_operation: '18/01/2011',
  },
  {
    ISPB: 78626983,
    short_name: 'BCO VR S.A.',
    number: 610,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco VR S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 78632767,
    short_name: 'BCO OURINVEST S.A.',
    number: 712,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'Banco Ourinvest S.A.',
    start_of_operation: '28/01/2014',
  },
  {
    ISPB: 80271455,
    short_name: 'BCO RNX S.A.',
    number: 720,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'BANCO RNX S.A.',
    start_of_operation: '24/02/2021',
  },
  {
    ISPB: 81723108,
    short_name: 'CREDICOAMO',
    number: '010',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'CREDICOAMO CREDITO RURAL COOPERATIVA',
    start_of_operation: '10/12/2012',
  },
  {
    ISPB: 82096447,
    short_name: 'CREDIBRF COOP',
    number: 440,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'CREDIBRF - COOPERATIVA DE CRÉDITO',
    start_of_operation: '02/09/2021',
  },
  {
    ISPB: 87963450,
    short_name: 'MAGNETIS - DTVM',
    number: 442,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'MAGNETIS - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    start_of_operation: '15/09/2021',
  },
  {
    ISPB: 89960090,
    short_name: 'RB INVESTIMENTOS DTVM LTDA.',
    number: 283,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'RB INVESTIMENTOS DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LIMITADA',
    start_of_operation: '05/07/2018',
  },
  {
    ISPB: 90400888,
    short_name: 'BCO SANTANDER (BRASIL) S.A.',
    number: '033',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BANCO SANTANDER (BRASIL) S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 91884981,
    short_name: 'BANCO JOHN DEERE S.A.',
    number: 217,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco John Deere S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 92702067,
    short_name: 'BCO DO ESTADO DO RS S.A.',
    number: '041',
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco do Estado do Rio Grande do Sul S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 92856905,
    short_name: 'ADVANCED CC LTDA',
    number: 117,
    participate_in_compe: 'Não',
    default_access: 'Internet',
    name: 'ADVANCED CORRETORA DE CÂMBIO LTDA',
    start_of_operation: '03/10/2011',
  },
  {
    ISPB: 92874270,
    short_name: 'BCO DIGIMAIS S.A.',
    number: 654,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'BANCO DIGIMAIS S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 92875780,
    short_name: 'WARREN CVMC LTDA',
    number: 371,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.',
    start_of_operation: '13/05/2020',
  },
  {
    ISPB: 92894922,
    short_name: 'BANCO ORIGINAL',
    number: 212,
    participate_in_compe: 'Sim',
    default_access: 'RSFN',
    name: 'Banco Original S.A.',
    start_of_operation: '22/04/2002',
  },
  {
    ISPB: 94968518,
    short_name: 'EFX CC LTDA.',
    number: 289,
    participate_in_compe: 'Não',
    default_access: 'RSFN',
    name: 'EFX CORRETORA DE CÂMBIO LTDA.',
    start_of_operation: '14/03/2019',
  },
];
