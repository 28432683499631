import React from 'react';
import classnames from 'classnames';
import { Row as RowType } from '@tanstack/react-table';
import { Row } from 'reactstrap';
import { Link } from 'react-router-dom';

interface IPagination {
  isPagination?: boolean;
  isShowingPageLength?: boolean;
  rows: Array<RowType<unknown>>;
  customPageSize: number;
  pages: number[];
  currentPage: number;
  totalItems: number;
  previousPage: () => void;
  nextPage: () => void;
  onChangePage: (page: number) => void;
  setPageIndex: (index: number) => void;
}

export const Pagination = ({
  isPagination,
  isShowingPageLength,
  rows,
  customPageSize,
  pages,
  currentPage,
  totalItems,
  previousPage,
  onChangePage,
  setPageIndex,
  nextPage,
}: IPagination) => (
  <>
    {isPagination && (
      <Row className="justify-content-between align-items-center">
        {isShowingPageLength && (
          <div className="col-sm">
            <div className="text-muted">
              Exibindo{' '}
              <span className="fw-semibold">
                {rows.length + customPageSize * (currentPage - 1) ?? 0}
              </span>{' '}
              de <span className="fw-semibold">{totalItems ?? 0}</span> registros
            </div>
          </div>
        )}
        <div className={classnames('col-sm-12 col-md-7')}>
          <ul className={classnames('pagination pagination-rounded justify-content-end mt-4')}>
            <li
              className={classnames('page-item', {
                disabled: currentPage === 1,
              })}
            >
              <Link
                to="#"
                className="page-link"
                onClick={(e) => {
                  previousPage();
                  e.preventDefault();
                  onChangePage(currentPage - 1);
                  setPageIndex(currentPage - 1);
                }}
              >
                <i className="mdi mdi-chevron-left"></i>
              </Link>
            </li>
            {pages.map((item: number, key: number) => (
              <React.Fragment key={key}>
                <li
                  className={classnames('page-item', {
                    active: item === currentPage,
                  })}
                >
                  <Link
                    to="#"
                    className="page-link"
                    onClick={(e) => {
                      e.preventDefault();
                      onChangePage(item);
                      setPageIndex(key);
                    }}
                  >
                    {item}
                  </Link>
                </li>
              </React.Fragment>
            ))}
            <li
              className={classnames('page-item', {
                disabled: pages.length === currentPage,
              })}
            >
              <Link
                to="#"
                className="page-link"
                onClick={(e) => {
                  nextPage();
                  e.preventDefault();
                  onChangePage(currentPage + 1);
                  setPageIndex(currentPage + 1);
                }}
              >
                <i className="mdi mdi-chevron-right"></i>
              </Link>
            </li>
          </ul>
        </div>
      </Row>
    )}
  </>
);
