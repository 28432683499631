import { IPartnerDocumentsCreate } from '@modules/Partners/types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody } from 'reactstrap';

interface IDetail {
  activeTab: string;
  partnerCode: string;
  partnerName: string;
  partnerDocument: IPartnerDocumentsCreate[];
}

export const Details = ({ activeTab, partnerCode, partnerName, partnerDocument }: IDetail) => (
  <>
    {activeTab !== 'details' && activeTab !== 'extract' && (
      <Card>
        <CardBody>
          <Badge color="primary" className="font-size-12" pill>
            <Link to="#" className="text-light">
              #{partnerCode}
            </Link>
          </Badge>
          <span className="font-size-14 ms-2">{partnerName} - </span>
          {partnerDocument.length > 0 && (
            <>
              <span className="font-size-14">{partnerDocument[0].type}</span>
              <span className="font-size-14 ms-1">{partnerDocument[0].value}</span>
            </>
          )}
        </CardBody>
      </Card>
    )}
  </>
);
