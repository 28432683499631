import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import '@assets/scss/theme.scss';

import { authProtectedRoutes, publicRoutes } from '@routes/allRoutes';
import AuthProtected from '@routes/AuthProtected';

import VerticalLayout from './components/Layouts/VerticalLayout';
import NonAuthLayout from './components/Layouts/NonLayout';

const App = () => (
  <Routes>
    {publicRoutes.map((route, idx) => (
      <Route
        path={route.path}
        key={idx}
        element={<NonAuthLayout>{route.component}</NonAuthLayout>}
      />
    ))}
    {authProtectedRoutes.map((route, idx) => (
      <Route
        path={route.path}
        key={idx}
        element={
          <AuthProtected>
            <VerticalLayout>{route.component}</VerticalLayout>
          </AuthProtected>
        }
      />
    ))}
  </Routes>
);

export default App;
