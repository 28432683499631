import React, { ReactElement, useRef, useState } from 'react';
import classnames from 'classnames';
import {
  ColumnFiltersState,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Button, Col, Row, Table, UncontrolledTooltip } from 'reactstrap';

import { PageSize } from './Actions/PageSize';
import { AddRegister } from './Actions/AddRegister';
import { Thead } from './Thead';
import { Tbody } from './Tbody';
import { Pagination } from './Actions/Pagination';
import Spinners from '../Spinner';
import DeleteModal from '../DeleteModal';
import FilterColumns from './FilterColumns';
import { useDragAndDrop } from './hooks/dragAndDrop';
import { useFilters } from './hooks/filters';
import { useColumnVisibility } from './hooks/columnVisibility';
import { getWithExpiry, setWithExpiry } from '@helpers/localStorage';

interface TableContainerProps {
  columns?: any;
  currentPage?: number;
  customPageSize?: number;
  data?: any;
  loading?: boolean;
  moduleName?: string;
  innerJoinColumns?: string[];
  disableColumnFilters?: boolean;
  totalItems?: number;
  customElement: ReactElement;
  isAddRegister?: boolean;
  isPagination?: boolean;
  isShowingPageLength?: boolean;
  isDelete?;
  deleteModal?: {
    isOpen: boolean;
    loading: boolean;
    onDeleteClick: () => void;
    onCloseClick: () => void;
  };
  setSearchParams?: (params: any) => void;
  refetch?: () => void;
  handleAddRegisterClick?: () => void;
  handleFilters?: (data: ColumnFiltersState) => void;
}

const TableContainer: any = ({
  columns,
  currentPage = 1,
  customPageSize = 10,
  data,
  loading,
  moduleName,
  disableColumnFilters,
  innerJoinColumns,
  totalItems,
  customElement,
  isAddRegister,
  isPagination,
  isShowingPageLength,
  isDelete,
  deleteModal,
  setSearchParams,
  refetch,
  handleAddRegisterClick,
  handleFilters,
}: TableContainerProps) => {
  const filterDelay = useRef<any>();
  const [isFirstLoad, setIsFirstLoad] = useState(false);

  const {
    columnBeingDragged,
    columnOrder,
    dragOver,
    enableOrderColumn,
    onDragLeave,
    onDragOver,
    onDragStart,
    onDrop,
    setColumnOrder,
    setOrderColumn,
  } = useDragAndDrop(moduleName);

  const getQueryParams = () => {
    const queryParams = window.location.search.replace('?', '').split('&');
    const params = queryParams.reduce((acc, param) => {
      const [key, value] = param.split('=');
      acc[decodeURIComponent(key)] = value;
      return acc;
    }, {});

    const paramsCopy: any = { ...params };
    delete paramsCopy.page;
    delete paramsCopy.size;

    return {
      params,
      filter: Object.entries(paramsCopy).map(([key, value]) => ({
        id: key,
        value,
      })),
    };
  };

  const getInitialFilter = () => {
    if (isFirstLoad) {
      return null;
    }

    setIsFirstLoad(true);

    const queryParams = window.location.search.replace('?', '').split('&');

    if (queryParams.length <= 2) {
      let filterCache = getWithExpiry(`${moduleName}_filter`);
      if (filterCache) {
        filterCache = JSON.parse(filterCache);

        const filterCacheCopy = { ...filterCache };

        delete filterCacheCopy.page;
        delete filterCacheCopy.size;

        return {
          params: filterCache,
          filter: Object.entries(filterCacheCopy).map(([key, value]) => ({
            id: key,
            value: decodeURIComponent(value as string),
          })),
        };
      }

      return null;
    }

    const params = queryParams.reduce((acc, param) => {
      const [key, value] = param.split('=');
      acc[decodeURIComponent(key)] = value;
      return acc;
    }, {});

    const paramsCopy: any = { ...params };
    delete paramsCopy.page;
    delete paramsCopy.size;

    return {
      params,
      filter: Object.entries(paramsCopy).map(([key, value]) => ({
        id: key,
        value: decodeURIComponent(value as string),
      })),
    };
  };

  const onFilter = (data: any) => {
    clearTimeout(filterDelay.current);

    filterDelay.current = setTimeout(() => {
      if (handleFilters) {
        const pageSize = customPageSize ? customPageSize.toString() : '1';
        const customTotalItem = totalItems || 0;
        const page =
          currentPage && customTotalItem <= customPageSize ? currentPage.toString() : '1';

        // Validate if has initial filter and apply
        const initialFilter = getInitialFilter();
        if (initialFilter) {
          handleFilters(
            initialFilter.filter.map((item) => ({
              id: item.id.replace('.', '_'),
              value: item.value,
            })),
          );

          const patternsToRemove = [
            '_eq',
            '_like',
            '_gt',
            '_gte',
            '_lt',
            '_lte',
            '_startswith',
            '_endswith',
            '_between',
          ];

          const initialColumnFilter = initialFilter.filter.map((item) => {
            let removedPattern: any = null;
            let filteredId = item.id.replaceAll('$', '');

            patternsToRemove.forEach((pattern) => {
              if (filteredId.endsWith(pattern)) {
                removedPattern = pattern;
                filteredId = filteredId.replace(new RegExp(`${pattern}`), '');
              }
            });

            setSearchParams && setSearchParams(initialFilter.params);

            if (removedPattern) {
              setFilterTypes((prevFilterType: any) => ({
                ...prevFilterType,
                [filteredId.replace('.', '_')]: {
                  ...prevFilterType[filteredId.replace('.', '_')],
                  type: removedPattern,
                },
              }));
            }

            return {
              id: filteredId.replaceAll('.', '_'),
              value: item.value,
            };
          });

          setColumnFilters(initialColumnFilter);

          return;
        }

        const reducedObject = data.reduce((acc, item) => {
          acc[item.id] = item.value;
          return acc;
        }, {});

        setWithExpiry(
          `${moduleName}_filter`,
          JSON.stringify({
            page: 1,
            size: pageSize,
            ...reducedObject,
          }),
          1000 * 60 * 30,
        );

        setSearchParams && setSearchParams({ page, size: pageSize, ...reducedObject });
        handleFilters(data);
      }
    }, 300);
  };

  const {
    columnFilters,
    enableFilters,
    sorting,
    setColumnFilters,
    setEnableFilters,
    setFilterTypes,
    setSorting,
  } = useFilters({ handleFilters: onFilter, innerJoinColumns });

  const { columnVisibility, dropdownOpen, setColumnVisibility, setDropdownOpen } =
    useColumnVisibility(moduleName);

  const handleChangePage = (page: number) => {
    const queryParams: any = getQueryParams();

    queryParams.params.page = page.toString();
    queryParams.params.size = customPageSize.toString();

    setWithExpiry(`${moduleName}_filter`, JSON.stringify(queryParams.params), 1000 * 60 * 30);

    setSearchParams && setSearchParams(queryParams.params);
    refetch && refetch();
  };

  const handleChangePageSize = (size: number) => {
    const queryParams: any = getQueryParams();

    queryParams.params.page = '1';
    queryParams.params.size = size.toString();

    setWithExpiry(`${moduleName}_filter`, JSON.stringify(queryParams.params), 1000 * 60 * 30);

    setSearchParams && setSearchParams(queryParams.params);
    refetch && refetch();
  };

  const {
    getHeaderGroups,
    getRowModel,
    setPageIndex,
    nextPage,
    previousPage,
    getToggleAllColumnsVisibilityHandler,
    getIsAllColumnsVisible,
    getVisibleLeafColumns,
    getAllLeafColumns,
  } = useReactTable({
    columns,
    data,
    state: {
      pagination: {
        pageIndex: 0,
        pageSize: customPageSize,
      },
      columnVisibility,
      columnFilters,
      columnOrder,
      sorting,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    onColumnOrderChange: setColumnOrder,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualFiltering: true,
    manualSorting: true,
    enableSorting: true,
    enableMultiSort: true,
    isMultiSortEvent: (e) => true,
  });

  const headers = getHeaderGroups();
  const { rows } = getRowModel();

  const pages: number[] = [];
  for (let i = 0; i < Math.ceil((totalItems ?? 0) / customPageSize); i++) {
    pages.push(i + 1);
  }

  return (
    <>
      {isDelete && (
        <DeleteModal
          show={deleteModal?.isOpen}
          loading={deleteModal?.loading}
          onDeleteClick={deleteModal?.onDeleteClick}
          onCloseClick={deleteModal?.onCloseClick}
        />
      )}
      <Row className="mb-2 align-end">
        <PageSize
          isPagination={isPagination}
          customPageSize={customPageSize}
          onChangePageSize={handleChangePageSize}
        />
        <Col sm={2} className="d-flex justify-content-start align-items-end">
          {customElement && customElement}
        </Col>
        <Col
          sm={isPagination ? 7 : 10}
          className="position-relative d-flex justify-content-end align-items-end"
        >
          <AddRegister
            isAddRegister={isAddRegister}
            handleAddRegisterClick={handleAddRegisterClick}
          />
          {columnFilters.length > 0 && (
            <Button
              color="danger"
              style={{ width: 37, height: 37 }}
              onClick={() => setColumnFilters([])}
              className="align-self-end ms-2"
              id="clearFilter"
            >
              <i className="mdi mdi-close-outline me-0"></i>
              <UncontrolledTooltip placement="top" target="columnSorting">
                Limpar filtros
              </UncontrolledTooltip>
            </Button>
          )}
          <Button
            color="primary"
            style={{ width: 37, height: 37 }}
            outline={!enableOrderColumn}
            onClick={() => setOrderColumn(!enableOrderColumn)}
            className="align-self-end ms-2"
            id="columnSorting"
          >
            <i className="mdi mdi-view-grid-outline me-0"></i>
            <UncontrolledTooltip placement="top" target="columnSorting">
              Ordenar colunas
            </UncontrolledTooltip>
          </Button>
          {!disableColumnFilters && (
            <Button
              color="primary"
              style={{ width: 37, height: 37 }}
              outline={!enableFilters}
              onClick={() => setEnableFilters(!enableFilters)}
              className="align-self-end ms-2"
              id="columnFilter"
            >
              <i className="mdi mdi-filter-variant me-0"></i>
              <UncontrolledTooltip placement="top" target="columnFilter">
                Filtrar dados
              </UncontrolledTooltip>
            </Button>
          )}
          <FilterColumns
            dropdownOpen={dropdownOpen}
            setDropdownOpen={setDropdownOpen}
            getIsAllColumnsVisible={getIsAllColumnsVisible}
            getToggleAllColumnsVisibilityHandler={getToggleAllColumnsVisibilityHandler}
            getAllLeafColumns={getAllLeafColumns}
          />
        </Col>
      </Row>
      <div className="table-responsive">
        <Table
          hover
          role="table"
          className={classnames('table align-middle table-nowrap table-hover table-striped')}
          que
        >
          <Thead
            headers={headers}
            dragActiveColumn={columnBeingDragged}
            dragOver={dragOver}
            enableDragAndDrop={enableOrderColumn}
            enableFilters={enableFilters}
            onDragStart={onDragStart}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            disableColumnFilters={disableColumnFilters}
            onDrop={(e) => onDrop(e, getVisibleLeafColumns)}
            setFilterTypes={setFilterTypes}
          />
          {loading ? (
            <tr>
              <td colSpan={getAllLeafColumns()?.length || 0}>
                <Spinners />
              </td>
            </tr>
          ) : (
            <Tbody dragActiveColumn={columnBeingDragged} dragOver={dragOver} rows={rows} />
          )}
        </Table>
      </div>
      <Pagination
        isPagination={isPagination}
        isShowingPageLength={isShowingPageLength}
        rows={rows}
        customPageSize={customPageSize}
        pages={pages}
        currentPage={currentPage}
        totalItems={totalItems || 0}
        previousPage={previousPage}
        onChangePage={handleChangePage}
        setPageIndex={setPageIndex}
        nextPage={nextPage}
      />
    </>
  );
};

export default TableContainer;
