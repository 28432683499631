export const TYPE_VALID_CPF = 'Por favor digite um CPF válido';
export const TYPE_VALID_CNPJ = 'Por favor digite um CNPJ válido';
export const TYPE_VALID_EMAIL = 'Por favor digite um email válido';
export const TYPE_EMAIL = 'Por favor digite um email';
export const TYPE_USERNAME = 'Por favor digite um nome de usuário';
export const TYPE_CPF = 'Por favor digite um CPF';
export const TYPE_PASSWORD = 'Por favor digite uma senha';
export const INVALID_EMAIL = 'Email inválido';
export const INVALID_PASSWORD =
  'Senha inválida! Deve conter 8 ou mais caracteres, pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.';
export const PASSWORD_MUST_EQUALS = 'As senhas devem ser iguais';
export const CONFIRM_PASSWORD_REQUIRED = 'Confirme sua senha é obrigatório';
