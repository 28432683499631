import React from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

function withRouter(Component: any) {
  function ComponentWithRouterProp(props: any) {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const page = Number(searchParams.get('page') ?? 1);
    const size = Number(searchParams.get('size') ?? 10);

    return (
      <Component
        {...props}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        page={page}
        size={size}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export default withRouter;
