import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import TableContainer from 'components/common/TableContainer';
import { buildColumns } from '@modules/Partners/submodules/Documents/components/Table/constants';
import { deleteByID, getAll } from '@modules/Partners/submodules/Documents/services';
import { IRouter } from '@apptypes/WithRouter';
import { toast } from 'react-toastify';
import { ColumnFiltersState } from '@tanstack/react-table';

interface IPartnersDocument {
  router: IRouter;
  partnerID?: string;
  partnerType?: string;
  page: number;
  size: number;
  setSearchParams: (params: any) => void;
}

const PartnersDocuments = ({
  router,
  partnerID,
  partnerType,
  page,
  size,
  setSearchParams,
}: IPartnersDocument) => {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const { user } = useSelector((state: any) => state.Profile);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['partnerDocuments', partnerID, page, size, filters],
    queryFn: () => getAll(partnerID || '', page, size, filters),
    initialData: { items: [], totalItems: 0 },
    retry: 1,
  });

  const deleteMutation = useMutation({
    mutationFn: () => deleteByID(deleteDocumentPartnersID || ''),
    onSuccess: () => {
      toast.success('Documento do acessor deletado com sucesso!', {
        theme: 'colored',
        autoClose: 1500,
      });

      refetch();
      setSearchParams({ page: '1' });
      setDeleteModal(false);
    },
  });

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteDocumentPartnersID, setDeleteDocumentPartnersID] = useState<string | undefined>();

  const handleDeletePartnersDocumentModal = (partnerID: string) => {
    setDeleteDocumentPartnersID(partnerID);
    setDeleteModal(true);
  };

  return (
    <div className="d-flex clients-content">
      <Helmet>
        <title>Documentos do acessor | SGI</title>
      </Helmet>
      <TableContainer
        moduleName="partnersDocuments"
        columns={buildColumns(
          user.is_admin,
          partnerID || '',
          partnerType || '',
          handleDeletePartnersDocumentModal,
        )}
        customPageSize={size}
        currentPage={page}
        data={data.items}
        loading={isFetching}
        totalItems={data.totalItems}
        deleteModal={{
          isOpen: deleteModal,
          loading: isFetching,
          onDeleteClick: deleteMutation.mutate,
          onCloseClick: () => setDeleteModal(false),
        }}
        handleFilters={setFilters}
        setSearchParams={setSearchParams}
        refeatch={refetch}
        isAddRegister
        isDelete
        isPagination
        isShowingPageLength
        handleAddRegisterClick={() => router.navigate(`/partners/${partnerID}/documents/create`)}
      />
    </div>
  );
};

export default PartnersDocuments;
