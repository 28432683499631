import React from 'react';

import withRouter from '@common/withRouter';
import { Page } from '@component/common/Page';
import { useQuery } from '@tanstack/react-query';
import { getCustodyDetaleidReport } from '../services';
import { CustodyDetails } from '../types';
import { Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import TableContainer from '@component/common/TableContainer';
import { buildColumns } from '../components/Table/contants';
import { moneyTransform } from '@helpers/transforms/money';

const initialData: CustodyDetails = {
  items: [
    {
      contract: {
        id: 0,
        type: '',
        profitability: 0,
      },
      client: {
        code: '',
        name: '',
      },
      current_custody: '',
      projection: {
        monthly: '',
        bimonthly: '',
        quarterly: '',
        biannual: '',
        annual: '',
      },
    },
  ],
  total: {
    monthly: '',
    bimonthly: '',
    quarterlymonthly: '',
    biannual: '',
    annual: '',
    custody: '',
  },
};

const CustodyDetailed = () => {
  const { data, isFetching } = useQuery<CustodyDetails>({
    queryKey: ['custodyReportDetailed'],
    queryFn: () => getCustodyDetaleidReport(),
    initialData,
    retry: 1,
  });

  console.log(data, isFetching);

  return (
    <Page
      title="Relatórios"
      breadcrumb={{
        title: 'Relatório de custódia detailed',
        titleHref: '/reports',
        breadcrumbItem: 'Relatório de custódia',
      }}
    >
      <Row>
        <Col sm={12}>
          <h1 className="font-size-16 mb-4">Custódia total</h1>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>Total</CardTitle>
              <CardText>{moneyTransform(Number(data.total.custody))}</CardText>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>Mensal</CardTitle>
              <CardText>{moneyTransform(Number(data.total.monthly))}</CardText>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>Bimestal</CardTitle>
              <CardText>{moneyTransform(Number(data.total.bimonthly))}</CardText>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>Trimestral</CardTitle>
              <CardText>{moneyTransform(Number(data.total.quarterlymonthly))}</CardText>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>Semestral</CardTitle>
              <CardText>{moneyTransform(Number(data.total.biannual))}</CardText>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>Anual</CardTitle>
              <CardText>{moneyTransform(Number(data.total.annual))}</CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <TableContainer
                moduleName="custody-details"
                columns={buildColumns()}
                customPageSize={1000}
                data={data.items}
                loading={isFetching}
                disableColumnFilters
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(CustodyDetailed);
