export const BANK_DATA_TYPE_MANDATORY = 'O tipo de conta é obrigatório';
export const AGENCY_MANDATORY = 'A agência é obrigatória';
export const AGENCY_MAX_CHARECTERS = 'A agência deve ter no máximo 10 caracteres';
export const ACCOUNT_MANDATORY = 'A conta é obrigatória';
export const ACCOUNT_MAX_CHARECTERS = 'A conta deve ter no máximo 15 caracteres';
export const BANK_NUMBER_MANDATORY = 'O número do banco é obrigatório';
export const BANK_NUMBER_MAX_CHARECTERS = 'O número do banco deve ter no máximo 10 caracteres';
export const INSTITUTION_MANDATORY = 'A instituição é obrigatória';
export const INSTITUTION_MAX_CHARECTERS = 'A instituição deve ter no máximo 100 caracteres';
export const PIX_MANDATORY = 'O PIX é obrigatório';
export const PIX_MAX_CHARECTERS = 'O PIX deve ter no máximo 80 caracteres';
