import { IClientDocumentsCreate } from '@modules/Client/types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody } from 'reactstrap';

interface IDetail {
  activeTab: string;
  clientName: string;
  clientCode: string;
  clientDocument: IClientDocumentsCreate[];
}

export const Details = ({ activeTab, clientName, clientCode, clientDocument }: IDetail) => (
  <>
    {activeTab !== 'clients' && (
      <Card>
        <CardBody>
          <Badge color="primary" className="font-size-12" pill>
            <Link to="#" className="text-light">
              #{clientCode}
            </Link>
          </Badge>
          <span className="font-size-14 ms-2">{clientName} - </span>
          {clientDocument.length > 0 && (
            <>
              <span className="font-size-14">{clientDocument[0].type}</span>
              <span className="font-size-14 ms-1">{clientDocument[0].value}</span>
            </>
          )}
        </CardBody>
      </Card>
    )}
  </>
);
