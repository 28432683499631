import React from 'react';
import {
  Button,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  UncontrolledTooltip,
} from 'reactstrap';

const FilterColumns = ({
  dropdownOpen,
  setDropdownOpen,
  getIsAllColumnsVisible,
  getToggleAllColumnsVisibilityHandler,
  getAllLeafColumns,
}: {
  dropdownOpen: boolean;
  setDropdownOpen: (value: boolean) => void;
  getIsAllColumnsVisible: () => boolean;
  getToggleAllColumnsVisibilityHandler: () => (event: unknown) => void;
  getAllLeafColumns: () => any[];
}) => (
  <div
    className="position-relative d-flex justify-content-end ms-2"
    style={{ width: 39, height: 39 }}
  >
    <Button
      color="primary"
      style={{ width: 37, height: 37 }}
      outline={!dropdownOpen}
      onClick={() => setDropdownOpen(!dropdownOpen)}
      className="align-self-end"
      id="displayColumns"
    >
      <i className="mdi mdi-cog me-0" />
      <UncontrolledTooltip placement="top" target="displayColumns">
        Esconder/Exibir colunas
      </UncontrolledTooltip>
    </Button>
    {dropdownOpen && (
      <ListGroup
        className="position-absolute shadow-lg"
        style={{ zIndex: 9999, width: 300, top: 40 }}
      >
        <ListGroupItem>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={getIsAllColumnsVisible()}
                onChange={getToggleAllColumnsVisibilityHandler()}
              />{' '}
              <span className="ms-1">
                {getIsAllColumnsVisible() ? 'Remover' : 'Selecionar'} todos
              </span>
            </Label>
          </FormGroup>
        </ListGroupItem>
        {getAllLeafColumns().map((column) => {
          return (
            <ListGroupItem key={column.id}>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={column.getIsVisible()}
                    onChange={column.getToggleVisibilityHandler()}
                  />{' '}
                  <span className="ms-1">{column.columnDef.header}</span>
                </Label>
              </FormGroup>
            </ListGroupItem>
          );
        })}
      </ListGroup>
    )}
  </div>
);

export default FilterColumns;
