import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import Breadcrumb from 'components/common/Breadcrumb';
import withRouter from '@component/common/withRouter';
import { IWithRouter } from '@apptypes/WithRouter';

const Dashboard = ({ router }: IWithRouter) => {
  document.title = 'Home | SGI';

  useEffect(() => {
    router.navigate('/reports/custody');
  }, [router.location.pathname]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Home" breadcrumbItem="Home" />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Dashboard);
