import React from 'react';

import ClientUpdate from '@modules/Client/pages/update';
import ClientBankDatasCreate from '@modules/Client/submodules/BankDatas/pages/create';
import ClientBankDatasUpdate from '@modules/Client/submodules/BankDatas/pages/update';

export const clientBankDatasRoutes = [
  // Clients Bank Data
  { path: '/clients/:clientID/bank-data', component: <ClientUpdate activePath="bank-data" /> },
  {
    path: '/clients/:clientID/bank-data/create',
    component: <ClientBankDatasCreate activePath="bank-data" />,
  },
  {
    path: '/clients/:clientID/bank-data/:bankDataID',
    component: <ClientBankDatasUpdate activePath="bank-data" />,
  },
];
