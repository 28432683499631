import React from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import TransactionsForm from '@modules/Contract/submodules/Transactions/components/TransactionForm';
import { ITransactionsCreate } from '@modules/Contract/types';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import Spinners from '@common/Spinner';
import withRouter from '@common/withRouter';
import { REDIRECT_TIMEOUT } from '@helpers/constants';
import { moneyTransform } from '@helpers/transforms/money';
import {
  TRANSACTION_TYPE_MANDATORY,
  TYPE_MANDATORY,
  VALUE_MANDATORY,
} from '@modules/Contract/constants';
import { getByID, update } from '@modules/Contract/submodules/Transactions/services';

const TransactionsUpdate = ({ router }: IWithRouter) => {
  const { contractID, clientID, transactionID } = useParams();
  const { data, isFetching } = useQuery({
    queryKey: ['transaction', { contractID, clientID, transactionID }],
    queryFn: () => getByID(transactionID || ''),
    retry: 1,
  });

  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: ITransactionsCreate) =>
      update(transactionID as string, clientID as string, contractID as string, payload),
    onSuccess: () => {
      setTimeout(() => {
        router.navigate(`/contracts/${contractID}/contracts-transactions`);
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: data?.type || '',
      value: moneyTransform(data?.value || 0),
      payment_type: data?.payment_type || '',
      effective_date: data?.effective_date || '',
      transaction_date: data?.transaction_date || '',
    },
    validationSchema: Yup.object({
      type: Yup.string().required(TRANSACTION_TYPE_MANDATORY),
      value: Yup.string().required(VALUE_MANDATORY),
      payment_type: Yup.string().required(TYPE_MANDATORY),
      effective_date: Yup.date(),
      transaction_date: Yup.date(),
    }),
    onSubmit: async (payload: ITransactionsCreate) => mutate(payload),
  });

  return (
    <Page
      title="Visualizar Transação"
      breadcrumb={{
        title: 'Transações',
        titleHref: `/contracts/${contractID}/contracts-transactions`,
        breadcrumbItem: 'Visualizar Transação',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Visualizar Transação</CardTitle>
              {isFetching ? (
                <Spinners />
              ) : (
                <div className="p-2 mt-3">
                  <TransactionsForm
                    loading={isPending}
                    validation={validation}
                    registrationError={error?.message}
                    type="edit"
                    showAlert={isSuccess}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(TransactionsUpdate);
