import React from 'react';
import { Col } from 'reactstrap';

interface IPageSize {
  isPagination?: boolean;
  customPageSize: number;
  onChangePageSize: (size: number) => void;
}

export const PageSize = ({ isPagination, customPageSize, onChangePageSize }: IPageSize) => (
  <>
    {isPagination && (
      <Col sm={3} className="total-items align-items-end">
        <select
          className="form-select form-control"
          value={customPageSize}
          onChange={(e) => onChangePageSize(Number(e.target.value))}
        >
          {[10, 20, 35, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Exibir {pageSize} registros
            </option>
          ))}
        </select>
      </Col>
    )}
  </>
);
