import { IRouter } from '@apptypes/WithRouter';
import { resetData } from '@slices/clientRegister/reducer';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';

const SuccessStep = ({ router }: { router: IRouter }) => {
  const dispatch: any = useDispatch();

  const handleClick = () => {
    dispatch(resetData());
    router.navigate('/clients');
  };

  return (
    <Row>
      <Col sm={12} className="d-flex justify-content-center mt-4">
        <div className="steps__success">
          <i className="mdi mdi-check-circle"></i>
          <h1>Cliente criado com sucesso!</h1>
          <div className="steps__success-action mt-2">
            <Button color="primary" onClick={handleClick}>
              Listar clientes
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SuccessStep;
