import { InstanceError } from '@apptypes/instance';
import { IPagination } from '@apptypes/pagination';
import { generateToQueryString } from '@helpers/queryString';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/instance';
import { IClientContactsCreate } from '@modules/Client/types';
import { ColumnFiltersState } from '@tanstack/react-table';

export const getAll = async (
  clientID: string,
  page = 1,
  size = 10,
  filterItems?: ColumnFiltersState,
): Promise<IPagination<IClientContactsCreate>> => {
  try {
    const queryString = generateToQueryString({
      page,
      size,
      filterItems,
      otherParams: clientID ? `&client_id=${clientID}` : '',
    });
    const { data } = await instance.get(`/client-contacts${queryString}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Contatos não foram encontrados',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getByID = async (clientContactID: string): Promise<IClientContactsCreate> => {
  try {
    const { data } = await instance.get(`/client-contacts/${clientContactID}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Contato não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const create = async (
  clientID: string,
  payload: IClientContactsCreate,
): Promise<IClientContactsCreate> => {
  try {
    const { data } = await instance.post(`/client-contacts?client_id=${clientID}`, {
      type: payload.type,
      value: payload.value,
      primary_contact: payload.primary_contact,
      client_id: clientID,
    });

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar o contato',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Contato não encontrado',
      [ERROR_STATUS_MESSAGE.PRIMARY_CONTACT_ALREADY_EXISTS]:
        'Contato primário já existe para esse cliente e tipo de contato',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const update = async (
  clientContactID: string,
  clientID: string,
  payload: IClientContactsCreate,
): Promise<IClientContactsCreate> => {
  try {
    const { data } = await instance.put(
      `/client-contacts/${clientContactID}?client_id=${clientID}`,
      payload,
    );

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível editar o contato',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Contato não encontrado',
      [ERROR_STATUS_MESSAGE.PRIMARY_CONTACT_ALREADY_EXISTS]:
        'Contato primário já existe para esse cliente e tipo de contato',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const deleteByID = async (clientContactID: string) => {
  try {
    await instance.delete(`/client-contacts/${clientContactID}`);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível deletar o contato',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
