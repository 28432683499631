import { IUserCreate } from '@modules/Users/types';
import instance from '@helpers/request/instance';
import { apiError, getAll, getByID, setLoading, updateByID } from './reducer';
import { getAuthProfile, updateUserMetadata } from '../auth/profile/reducer';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import { deleteCookie } from '@helpers/cookies';
import { UNEXPECTED_ERROR, USER_ID_COOKIE_NAME } from '@helpers/constants';
import { INVALID_EMAIL, INVALID_PASSWORD } from '@modules/Users/constants';
import { registerUserFailed, registerUserSuccessful } from '@slices/auth/register/reducer';
import { InstanceError } from '@apptypes/instance';

export const createUser = (payload: IUserCreate) => async (dispatch: any) => {
  try {
    const body = {
      cpf: payload.cpf,
      email: payload.email,
      name: payload.name,
      password: payload.password,
    };

    const { data } = await instance.post('/sign-up', body);
    dispatch(registerUserSuccessful(data));
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar o usuário',
      [ERROR_STATUS_MESSAGE.EMAIL_INVALID]: INVALID_EMAIL,
      [ERROR_STATUS_MESSAGE.PASSWORD_INVALID]: INVALID_PASSWORD,
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: UNEXPECTED_ERROR,
    };

    dispatch(setLoading(false));
    dispatch(
      registerUserFailed(
        errorMessage[(error as InstanceError).status] ??
          errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
      ),
    );
  }
};

export const getAuthUser = async (payload: { userID: string }) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const { data } = await instance.get(`/users/${payload.userID}`);

    dispatch(getAuthProfile(data));
    dispatch(setLoading(false));
  } catch (error) {
    if ((error as InstanceError)?.status === ERROR_STATUS_MESSAGE.UNAUTHORIZED) {
      deleteCookie(USER_ID_COOKIE_NAME);
      window.location.href = '/login';
    }

    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Usuários não encontrado',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para visualizar o usuário',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    dispatch(setLoading(false));
    dispatch(
      apiError(
        errorMessage[(error as InstanceError).status] ??
          errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
      ),
    );
  }
};

export const getUserByID = async (payload: { userID: string }) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const { data } = await instance.get(`/users/${payload.userID}`);

    dispatch(getByID(data));
    dispatch(setLoading(false));
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Usuários não encontrado',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para visualizar o usuário',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    dispatch(setLoading(false));
    dispatch(
      apiError(
        errorMessage[(error as InstanceError).status] ??
          errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
      ),
    );
  }
};

export const getAllUsers =
  async (page = 1, size = 10) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const { data } = await instance.get(`/users?page=${page}&size=${size}`);
      dispatch(getAll(data));
    } catch (err) {
      const errorMessage: Record<number, string> = {
        [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Usuários não encontrado',
        [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
      };

      dispatch(setLoading(false));
      dispatch(
        apiError(
          errorMessage[(err as InstanceError).status] ??
            errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
        ),
      );
    }
  };

export const updateMetadata =
  async (userID: string, metadata: unknown) => async (dispatch: any) => {
    try {
      const { data } = await instance.put(`/users/${userID}/metadata`, { metadata });
      dispatch(updateUserMetadata(data.metadata));
    } catch (err) {
      const errorMessage: Record<number, string> = {
        [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível editar o usuários',
        [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Usuários não encontrado',
        [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para editar o usuário',
        [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
      };

      dispatch(
        apiError(
          errorMessage[(err as InstanceError).status] ??
            errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
        ),
      );
    }
  };

export const updateUserByID =
  async (payload: { userID: string; body: IUserCreate }) => async (dispatch: any) => {
    try {
      const body = {
        name: payload.body.name,
        password: payload.body.password,
      };

      const { data } = await instance.put(`/users/${payload.userID}`, body);
      dispatch(updateByID(data));
    } catch (err) {
      const errorMessage: Record<number, string> = {
        [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível editar o usuários',
        [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Usuários não encontrado',
        [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para editar o usuário',
        [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
      };

      dispatch(
        apiError(
          errorMessage[(err as InstanceError).status] ??
            errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
        ),
      );
    }
  };

export const deleteUserByID = async (payload: { userID: string }) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    await instance.delete(`/users/${payload.userID}`);

    const { data } = await instance.get('/users');
    dispatch(getAll(data));
  } catch (err) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível deletar o usuários',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para deletar o usuário',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    dispatch(setLoading(false));
    dispatch(
      apiError(
        errorMessage[(err as InstanceError).status] ??
          errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
      ),
    );
  }
};
