import React from 'react';
import { Alert, Input, Label, Form, FormFeedback, FormGroup, Col, Row } from 'reactstrap';

import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import moment from 'moment';

import { CalendarFormProps } from '@modules/Calendar/types';
import classNames from 'classnames';

const CalendarForm = ({
  loading = false,
  registrationError,
  showAlert,
  type = 'create',
  validation,
  user,
}: CalendarFormProps) => {
  function handleCalendarDate(date: any) {
    validation.setFieldValue('calendarDate', moment(date[0]).format('YYYY-MM-DD'));
  }

  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {showAlert ? (
        <Alert color="success">{type === 'create' ? 'Cadastrado' : 'Editado'} com sucesso.</Alert>
      ) : null}

      {registrationError && registrationError ? (
        <Alert color="danger">{registrationError}</Alert>
      ) : null}

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Descrição</Label>
        <Col lg={10}>
          <Input
            name="description"
            className="form-control"
            placeholder="Digite uma descrição"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.description || ''}
            invalid={!!(validation.touched.description && validation.errors.description)}
          />
          {validation.touched.description && validation.errors.description ? (
            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Data</Label>
        <Col lg={10}>
          <Flatpickr
            name="calendarDate"
            className={classNames(`form-control d-block`, {
              'is-invalid': !!(validation.touched.calendarDate && validation.errors.calendarDate),
            })}
            placeholder="Selecione uma data"
            onChange={handleCalendarDate}
            value={validation.values.calendarDate || ''}
            options={{
              altInput: true,
              altFormat: 'F j, Y',
              dateFormat: 'Y-m-d',
              locale: Portuguese,
            }}
          />
          {validation.touched.calendarDate && validation.errors.calendarDate ? (
            <FormFeedback type="invalid">{validation.errors.calendarDate}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Data recorrente</Label>
        <Col lg={10}>
          <Input
            id="recurrent"
            name="recurrent"
            className="form-control"
            type="select"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.recurrent || ''}
            invalid={!!(validation.touched.recurrent && validation.errors.recurrent)}
          >
            <option value="false">Não</option>
            <option value="true">Sim</option>
          </Input>
          {validation.touched.recurrent && validation.errors.recurrent ? (
            <FormFeedback type="invalid">{validation.errors.recurrent}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      {type === 'edit' && !!user && (
        <FormGroup className="mb-4" row>
          <Label className="col-form-label col-lg-2">Criado por</Label>
          <Col lg={10}>
            <Input name="user" className="form-control" type="text" disabled value={user.name} />
          </Col>
        </FormGroup>
      )}

      <Row className="justify-content-end">
        <Col lg={10}>
          <button className="btn btn-primary btn-block" type="submit" disabled={loading}>
            {loading ? <>Salvando...</> : `${type === 'create' ? 'Cadastrar' : 'Editar'} data`}
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default CalendarForm;
