import React from 'react';

import ClientUpdate from '@modules/Client/pages/update';
import ClientContactsCreate from '@modules/Client/submodules/Contacts/pages/create';
import ClientContactsUpdate from '@modules/Client/submodules/Contacts/pages/update';

export const clientContactsRoutes = [
  { path: '/clients/:clientID/contacts', component: <ClientUpdate activePath="contacts" /> },
  {
    path: '/clients/:clientID/contacts/create',
    component: <ClientContactsCreate activePath="contacts" />,
  },
  {
    path: '/clients/:clientID/contacts/:contactID',
    component: <ClientContactsUpdate activePath="contacts" />,
  },
];
