import { InstanceError } from '@apptypes/instance';
import { IPagination } from '@apptypes/pagination';
import { generateToQueryString } from '@helpers/queryString';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/instance';
import { IClientDocumentsCreate } from '@modules/Client/types';
import { ColumnFiltersState } from '@tanstack/react-table';

export const getAll = async (
  clientID: string,
  page = 1,
  size = 10,
  filterItems?: ColumnFiltersState,
): Promise<IPagination<IClientDocumentsCreate>> => {
  try {
    const queryString = generateToQueryString({
      page,
      size,
      filterItems,
      otherParams: clientID ? `&client_id=${clientID}` : '',
    });
    const { data } = await instance.get(`/client-documents${queryString}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Documentos não encontrados',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getByID = async (clientDocumentID: string): Promise<IClientDocumentsCreate> => {
  try {
    const { data } = await instance.get(`/client-documents/${clientDocumentID}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Documento não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const create = async (
  clientID: string,
  payload: IClientDocumentsCreate,
): Promise<IClientDocumentsCreate> => {
  try {
    const { data } = await instance.post(`/client-documents?client_id=${clientID}`, {
      type: payload.type,
      value: payload.value,
      client_id: clientID,
    });

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar o documento',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Documento não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const documentsIntegrateRegister = async (
  clientID: string,
  payload: any,
  isUpdate: boolean,
): Promise<any> => {
  try {
    const newPayload = payload.filter((item: any) => item.value);
    const requests = newPayload.map((item: any) => {
      if (isUpdate && item.id) {
        return update(item.id, item);
      }

      return create(clientID, item);
    });

    return await Promise.all(requests);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar os documentos',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Documentos não encontrados',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const update = async (
  clientDocumentID: string,
  payload: IClientDocumentsCreate,
): Promise<IClientDocumentsCreate> => {
  try {
    const { data } = await instance.put(`/client-documents/${clientDocumentID}`, payload);

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível atualizar o documento',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Documento não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const deleteByID = async (clientDocumentID: string): Promise<void> => {
  try {
    await instance.delete(`/client-documents/${clientDocumentID}`);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Documento não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
