import React from 'react';
import classnames from 'classnames';
import { Nav, NavItem, NavLink } from 'reactstrap';

interface TabsProps {
  activeTab: string;
  toggleTab: (tab: string) => void;
}

const Tabs = ({ activeTab, toggleTab }: TabsProps) => (
  <Nav tabs className="nav-tabs-custom justify-content-center pt-2" role="tablist">
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'clients',
        })}
        onClick={() => toggleTab('clients')}
      >
        Dados do Cliente
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'documents',
        })}
        onClick={() => toggleTab('documents')}
      >
        Documentos
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'contacts',
        })}
        onClick={() => toggleTab('contacts')}
      >
        Contatos
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'addresses',
        })}
        onClick={() => toggleTab('addresses')}
      >
        Endereços
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'bank-data',
        })}
        onClick={() => toggleTab('bank-data')}
      >
        Dados bancários
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'contracts',
        })}
        onClick={() => toggleTab('contracts')}
      >
        Contratos
      </NavLink>
    </NavItem>
  </Nav>
);

export default Tabs;
