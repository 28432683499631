import React from 'react';
import { Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import * as Yup from 'yup';

import { IClientAdressesCreate, IStep } from '@modules/Client/types';

import {
  CITY_MANDATORY,
  COUNTRY_MANDATORY,
  NEIGHBOURHOOD_MANDATORY,
  NUMBER_MANDATORY,
  STATE_MANDATORY,
  STREET_MANDATORY,
  ZIPCODE_MANDATORY,
} from '@helpers/constants';
import { create } from '@modules/Client/submodules/Addresses/services';
import { StepTable } from '../StepTable';
import ClientAddressesForm from '@modules/Client/submodules/Addresses/components/ClientAddressesForm';

const AddressStep = ({
  data,
  clientID,
  incrementData,
  incrementStep,
}: IStep<IClientAdressesCreate[]>) => {
  const { mutate, error, isPending, isSuccess, reset } = useMutation({
    mutationFn: (payload: IClientAdressesCreate) => create(clientID || '', payload),
    onSuccess: (address: IClientAdressesCreate) => {
      validation.resetForm();
      reset();

      const newAddresses = Array.isArray(data) ? data : [];
      incrementData([...newAddresses, address]);
      incrementStep();
    },
  });

  const newAddresses: string[][] = [];
  for (const documents in data) {
    const { zipcode, country, state, city, neighbourhood, complement, street, number } =
      data[documents];
    newAddresses.push([zipcode, country, state, city, neighbourhood, street, number, complement]);
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      zipcode: '',
      country: '',
      state: '',
      city: '',
      neighbourhood: '',
      complement: '',
      street: '',
      number: 0,
    },
    validationSchema: Yup.object({
      zipcode: Yup.string().required(ZIPCODE_MANDATORY),
      state: Yup.string().required(STATE_MANDATORY),
      country: Yup.string().required(COUNTRY_MANDATORY),
      city: Yup.string().required(CITY_MANDATORY),
      neighbourhood: Yup.string().required(NEIGHBOURHOOD_MANDATORY),
      complement: Yup.string(),
      street: Yup.string().required(STREET_MANDATORY),
      number: Yup.number().required(NUMBER_MANDATORY),
    }),
    onSubmit: async (payload: IClientAdressesCreate) => mutate(payload),
  });

  return (
    <Row>
      <Col sm={12}>
        {data && data.length > 0 && (
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Endereços do cliente</CardTitle>
              <div className="p-2 mt-3">
                <StepTable
                  columns={[
                    'CEP',
                    'País',
                    'Estado',
                    'Cidade',
                    'Bairro',
                    'Rua',
                    'Número',
                    'Complemento',
                  ]}
                  rows={newAddresses}
                />
              </div>
            </CardBody>
          </Card>
        )}
        {data && data?.length < 1 && (
          <Card>
            <CardBody>
              <CardTitle className="pb-4">Adicionar endereços</CardTitle>
              <ClientAddressesForm
                loading={isPending}
                validation={validation}
                registrationError={error?.message}
                showAlert={isSuccess}
              />
            </CardBody>
          </Card>
        )}

        {data && data?.length > 0 && (
          <div className="d-flex justify-content-end mb-4">
            <Button color="primary" onClick={() => incrementStep()}>
              Próxima etapa
              <i className="mdi mdi-chevron-right"></i>
            </Button>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default AddressStep;
