import React, { FormEvent } from 'react';

export const AddRegister = ({
  label = 'registro',
  handleClick,
}: {
  label?: string;
  handleClick: (e: FormEvent<HTMLAnchorElement>) => void;
}) => (
  <div className="steps__add-register">
    <a href="#" onClick={handleClick}>
      <div className="steps__add-register-button">
        <i className="mdi mdi-plus-circle"></i>
        <span className="ps-2">Adicionar {label}</span>
      </div>
    </a>
  </div>
);
