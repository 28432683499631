import React from 'react';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import withRouter from '@common/withRouter';
import Steps from '../components/Steps';
import ClientStep from '../components/Steps/ClientStep';
import {
  IClientAdressesCreate,
  IClientBankDataCreate,
  IClientContactsCreate,
  IClientCreate,
  IClientDocumentsCreate,
} from '../types';
import DocumentStep from '../components/Steps/DocumentStep';
import ContactStep from '../components/Steps/ContactStep';
import AddressStep from '../components/Steps/AddressStep';
import BankDataStep from '../components/Steps/BankDataStep';
import SuccessStep from '../components/Steps/SuccessStep';
import { useSelector, useDispatch } from 'react-redux';
import {
  finishStep,
  getClientRegisterState,
  resetData,
  setClientRegisterState,
  setStepData,
} from '@slices/clientRegister/reducer';
import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap';

const ClientRegister = ({ router }: IWithRouter) => {
  const {
    stateLoaded,
    currentStep,
    CLIENT: client,
    CONTACTS,
    DOCUMENTS,
    ADDRESSES,
    BANK_DATAS,
  } = useSelector((state: any) => state.ClientRegister);
  const dispatch: any = useDispatch();

  const clientRegisterState = getClientRegisterState();

  return (
    <Page
      title="Cadastrar cliente"
      breadcrumb={{
        title: 'Clientes',
        titleHref: '/clients',
        breadcrumbItem: 'Cadastrar cliente',
      }}
    >
      {clientRegisterState && !stateLoaded ? (
        <Modal isOpen={true} centered={true}>
          <div className="modal-content">
            <ModalHeader>Existe um cadastro em andamento, deseja continuar?</ModalHeader>
            <ModalFooter>
              <Button
                color="success"
                onClick={() => dispatch(setClientRegisterState(clientRegisterState))}
              >
                Sim
              </Button>{' '}
              <Button color="secondary" onClick={() => dispatch(resetData())}>
                Não
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      ) : (
        <>
          <Steps />

          {currentStep === 1 && (
            <ClientStep
              data={client.data}
              incrementStep={() => dispatch(finishStep({ currentStep, step: 'CLIENT' }))}
              incrementData={(data: IClientCreate) =>
                dispatch(setStepData({ step: 'CLIENT', data }))
              }
            />
          )}

          {currentStep === 2 && (
            <DocumentStep
              data={DOCUMENTS?.data}
              incrementStep={() => dispatch(finishStep({ currentStep, step: 'DOCUMENTS' }))}
              incrementData={(data: IClientDocumentsCreate[]) =>
                dispatch(setStepData({ step: 'DOCUMENTS', data }))
              }
              clientID={client.data?.id || ''}
              clientType={client.data?.type || ''}
            />
          )}

          {currentStep === 3 && (
            <ContactStep
              data={CONTACTS?.data}
              incrementStep={() => dispatch(finishStep({ currentStep, step: 'CONTACTS' }))}
              incrementData={(data: IClientContactsCreate[]) =>
                dispatch(setStepData({ step: 'CONTACTS', data }))
              }
              clientID={client.data?.id || ''}
            />
          )}

          {currentStep === 4 && (
            <AddressStep
              data={ADDRESSES?.data}
              incrementStep={() => dispatch(finishStep({ currentStep, step: 'ADDRESSES' }))}
              incrementData={(data: IClientAdressesCreate[]) =>
                dispatch(setStepData({ step: 'ADDRESSES', data }))
              }
              clientID={client.data?.id || ''}
            />
          )}

          {currentStep === 5 && (
            <BankDataStep
              data={BANK_DATAS?.data}
              incrementStep={() => dispatch(finishStep({ currentStep, step: 'BANK_DATAS' }))}
              incrementData={(data: IClientBankDataCreate[]) =>
                dispatch(setStepData({ step: 'BANK_DATAS', data }))
              }
              clientID={client?.data?.id || ''}
            />
          )}

          {currentStep === 6 && <SuccessStep router={router} />}
        </>
      )}
    </Page>
  );
};

export default withRouter(ClientRegister);
