import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

export const buildColumns = (isAdmin: boolean, partnerID: string) => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('type', {
      cell: ({ row, getValue }: any) => (
        <Badge color="primary" className="font-size-14" pill>
          <Link to={`/clients/${row.original.id}`} className="text-light">
            {getValue() === 'J' ? 'Jurídica' : 'Física'}
          </Link>
        </Badge>
      ),
      header: 'Tipo de Pessoa',
      size: 5,
    }),
    columnHelper.accessor('name', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/clients/${row.original.id}`} className="text-dark">
            {getValue()}
          </Link>
        </h5>
      ),
      header: 'Nome',
      size: 60,
    }),
  ];
};
