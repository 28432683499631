import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IClientCreate } from '@modules/Client/types';
import Spinners from '@common/Spinner';
import { REDIRECT_TIMEOUT } from '@helpers/constants';
import {
  CLIENT_TYPE_MANDATORY,
  CORPORATE_REASON_MANDATORY,
  CORPORATE_REASON_MAX_CHARACTERS,
  MATIRAL_STATUS_MAX_CHARACTERS,
  NAME_MANDATORY,
  NAME_MAX_CHARACTERS,
  NATIONALITY_MAX_CHARACTERS,
  OBSERVATION_MAX_CHARACTERS,
  PROFESSION_MAX_CHARACTERS,
} from '@modules/Client/constants';
import ClientForm from '@modules/Client/components/ClientForm';
import { update } from '@modules/Client/services';

const ClientInfo = ({
  client,
  clientID,
  isLoading,
}: {
  client: IClientCreate;
  clientID: string;
  isLoading: boolean;
}) => {
  const { mutate, error, isSuccess, isPending } = useMutation({
    mutationFn: (payload: IClientCreate) => update(clientID, payload),
    onSuccess: () => {
      setTimeout(() => {
        window.location.reload();
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: client.type,
      document: client.document,
      state_registration: client.state_registration,
      municipal_registration: client.municipal_registration,
      corporate_reason: client.corporate_reason,
      gender: client.gender,
      marital_status: client.marital_status,
      name: client.name,
      nationality: client.nationality,
      service_type: client.service_type,
      observation: client.observation,
      profession: client.profession,
    },
    validationSchema: Yup.object({
      type: Yup.string().required(CLIENT_TYPE_MANDATORY),
      name: Yup.string().required(NAME_MANDATORY).max(200, NAME_MAX_CHARACTERS),
      corporate_reason: Yup.string()
        .ensure()
        .when('type', {
          is: 'J',
          then: Yup.string()
            .max(200, CORPORATE_REASON_MAX_CHARACTERS)
            .required(CORPORATE_REASON_MANDATORY),
        }),
      marital_status: Yup.string()
        .ensure()
        .when('type', {
          is: 'F',
          then: Yup.string().max(80, MATIRAL_STATUS_MAX_CHARACTERS),
        }),
      profession: Yup.string()
        .ensure()
        .when('type', {
          is: 'F',
          then: Yup.string().max(100, PROFESSION_MAX_CHARACTERS),
        }),
      nationality: Yup.string()
        .ensure()
        .when('type', {
          is: 'F',
          then: Yup.string().max(80, NATIONALITY_MAX_CHARACTERS),
        }),
      gender: Yup.string().ensure().when('type', {
        is: 'F',
        then: Yup.string(),
      }),
      observation: Yup.string().max(250, OBSERVATION_MAX_CHARACTERS),
    }),
    onSubmit: async (payload: IClientCreate) => mutate(payload),
  });

  return (
    <>
      {isLoading ? (
        <Spinners />
      ) : (
        <ClientForm
          loading={isPending}
          validation={validation}
          registrationError={error?.message}
          showAlert={isSuccess}
          type="edit"
        />
      )}
    </>
  );
};

export default ClientInfo;
