import React from 'react';
import { Helmet } from 'react-helmet';
import { Row } from 'reactstrap';

import EarningYear from '@modules/Partners/submodules/Earnings/components/EarningCharts/year';
import EarningMonth from '../components/EarningCharts/month';

export const PartnersEarnings = ({ partnerID }: any) => (
  <>
    <Helmet>
      <title>Ganhos do Acessor | SGI</title>
    </Helmet>
    <Row>
      <EarningYear partnerID={partnerID} />
      <EarningMonth partnerID={partnerID} />
    </Row>
  </>
);
