import React, { useState } from 'react';
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import { IActionModal } from '@modules/PendingWithdrawals/types';
import { getAll } from '@modules/Client/submodules/BankDatas/services';
import Spinners from '@common/Spinner';

const ActionModal = ({
  show,
  clientID,
  actionDescription,
  actionButtonText,
  actionButtonColor = 'danger',
  loading = false,
  onActionClick,
  onCloseClick,
}: IActionModal) => {
  const [receiptFile, setReceiptFile] = useState('');
  const [receiptName, setReceiptName] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [invalidPayment, setInvalidPayment] = useState(false);

  const { data } = useQuery({
    queryKey: ['clientBankDatas', { clientID, page: 1, size: 10 }],
    queryFn: () => getAll(clientID, 1, 10),
    initialData: { items: [], totalItems: 0 },
    retry: 1,
  });

  const bankData = data.items.length > 0 ? data.items[0] : undefined;

  const handleChangePaymentType = (e: any) => setPaymentType(e.target.value);

  const handleClick = () => {
    if (!paymentType) {
      setInvalidPayment(true);
      return;
    }

    onActionClick(paymentType, receiptFile, receiptName);
    setInvalidPayment(false);
    setPaymentType('');
    setReceiptFile('');
    setReceiptName('');
  };

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) {
      return;
    }

    const fileName = file.name;
    const reader = new FileReader();

    reader.onload = () => {
      let base64String = reader.result as string;
      base64String = base64String.split(',')[1];

      setReceiptName(fileName);
      setReceiptFile(base64String);
    };

    reader.readAsDataURL(file);
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>

          {loading ? (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h3 className="mb-4">Realizando upload...</h3>
              <Spinners />
            </div>
          ) : (
            <>
              <p className="text-muted font-size-16 mb-4">{actionDescription}</p>

              {bankData && (
                <div style={{ textAlign: 'left' }}>
                  <p className="font-size-14">Dados bancários</p>
                  <ListGroup className="mb-4" style={{ textAlign: 'left' }}>
                    <ListGroupItem>
                      <strong>Tipo da conta:</strong>{' '}
                      {bankData.type === 'CC' ? 'Conta corrente' : 'Conta poupança'}
                    </ListGroupItem>
                    <ListGroupItem>
                      <strong>Agência:</strong> {bankData.agency}
                    </ListGroupItem>
                    <ListGroupItem>
                      <strong>Número da conta:</strong> {bankData.account}
                    </ListGroupItem>
                    <ListGroupItem>
                      <strong>Número do banco:</strong> {bankData.bank_number}
                    </ListGroupItem>
                    <ListGroupItem>
                      <strong>Instituição:</strong> {bankData.institution}
                    </ListGroupItem>
                    <ListGroupItem>
                      <strong>PIX:</strong> {bankData.pix}
                    </ListGroupItem>
                  </ListGroup>
                </div>
              )}

              <div className="hstack gap-2 justify-content-center mb-0">
                <Row>
                  <FormGroup>
                    <Col md={12}>
                      <Label className="col-form-label d-flex">Tipo de pagamento</Label>
                      <Input
                        id="payment_type"
                        name="payment_type"
                        className="form-control"
                        type="select"
                        onChange={handleChangePaymentType}
                      >
                        <option value="">Selecione um tipo de pagamento</option>
                        <option value="DINHEIRO">DINHEIRO</option>
                        <option value="PIX">PIX</option>
                        <option value="TED">TED</option>
                        <option value="BOLETO">BOLETO</option>
                        <option value="CHEQUE">CHEQUE</option>
                        <option value="A_NEGOCIAR">A NEGOCIAR</option>
                      </Input>
                      {invalidPayment && (
                        <FormFeedback type="invalid" className="d-block text-start">
                          O tipo de pagamento é obrigatório!
                        </FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="mb-4">
                    <Col>
                      <Label className="col-form-label d-flex">Comprovante de pagamento</Label>
                      {!receiptFile ? (
                        <Input
                          id="receiptFile"
                          name="receiptFile"
                          className="form-control"
                          type="file"
                          onChange={handleFile}
                          value={receiptFile || ''}
                        />
                      ) : (
                        <div className="d-flex">
                          <Input value={receiptName} disabled />
                          <Button
                            color="danger"
                            className="ms-2"
                            id="removeFile"
                            onClick={() => setReceiptFile('')}
                          >
                            <i className="mdi mdi-close me-0"></i>
                            <UncontrolledTooltip placement="top" target="removeFile">
                              Remover arquivo
                            </UncontrolledTooltip>
                          </Button>
                        </div>
                      )}
                    </Col>
                  </FormGroup>

                  <Col md={12} className="d-flex justify-content-center">
                    <button
                      type="button"
                      disabled={!paymentType}
                      className={`btn btn-${actionButtonColor} me-2`}
                      onClick={handleClick}
                    >
                      {actionButtonText}
                    </button>
                    <button type="button" className="btn btn-secondary" onClick={onCloseClick}>
                      Cancelar
                    </button>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </ModalBody>
      </div>
    </Modal>
  );
};

export default ActionModal;
