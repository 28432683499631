import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@tanstack/react-query';

import { IWithRouter } from '@apptypes/WithRouter';
import TableContainer from '@common/TableContainer';
import withRouter from '@common/withRouter';
import { Page } from '@component/common/Page';
import { buildColumns } from '@modules/ProfitabilityGroup/components/Table/constants';
import { deleteByID, exportData, getAll } from '@modules/ProfitabilityGroup/services';
import { ColumnFiltersState } from '@tanstack/react-table';

const ProfitabilityGroup = ({ router, page, size, setSearchParams }: IWithRouter) => {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const { user }: any = useSelector((state: any) => state.Profile);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['getAllProfitabilityGroups', page, size, filters],
    queryFn: () => getAll(page, size, filters),
    initialData: { items: [], totalItems: 0 },
    retry: 1,
  });

  const deleteMutation = useMutation({
    mutationFn: () => {
      if (deleteProfitabilityGroupID) {
        return deleteByID(deleteProfitabilityGroupID);
      }

      return Promise.resolve();
    },
    onSuccess: () => {
      toast.success('Grupo de rentabilidade deletado com sucesso!', {
        theme: 'colored',
        autoClose: 1500,
      });

      setSearchParams({ page: '1' });
      setDeleteModal(false);
      refetch();
    },
  });

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteProfitabilityGroupID, setDeleteProfitabilityGroupID] = useState<
    string | undefined
  >();

  const handleDeleteProfitabilityGroupModal = (profitabilityGroupID: string) => {
    setDeleteProfitabilityGroupID(profitabilityGroupID);
    setDeleteModal(true);
  };

  const handleExportData = async () => {
    const fileUrl = await exportData(page, size, filters);

    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = `profitability-group-export.csv`;
    link.click();
  };

  return (
    <Page
      title="Grupo de rentabilidade"
      breadcrumb={{
        title: 'Grupo de rentabilidade',
        titleHref: '/profitability-groups',
        breadcrumbItem: 'Listar grupos de rentabilidades',
      }}
    >
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <TableContainer
                moduleName="profitabilityGroups"
                columns={buildColumns(user?.is_admin, handleDeleteProfitabilityGroupModal)}
                customPageSize={size}
                currentPage={page}
                data={data.items}
                loading={isFetching}
                totalItems={data.totalItems}
                customElement={
                  <Button type="button" color="primary" outline onClick={handleExportData}>
                    Exportar dados
                  </Button>
                }
                deleteModal={{
                  isOpen: deleteModal,
                  loading: isFetching,
                  onDeleteClick: deleteMutation.mutate,
                  onCloseClick: () => setDeleteModal(false),
                }}
                handleFilters={setFilters}
                setSearchParams={setSearchParams}
                refetch={refetch}
                isAddRegister
                isPagination
                isShowingPageLength
                isDelete
                handleAddRegisterClick={() => router.navigate('/profitability-groups/create')}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(ProfitabilityGroup);
