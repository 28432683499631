import * as Yup from 'yup';
import { TYPE_CPF, TYPE_VALID_CNPJ } from '@modules/Users/constants';
import { validateCPF } from './cpf';
import { validateCNPJ } from './cnpj';

export function validatePassword(password: string) {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(password);
}

export const yupInvalidCPF = () => {
  Yup.addMethod<any>(Yup.StringSchema, 'isValidCPF', function (message: string) {
    const self = this;
    return self
      .test('isValidCPF', message, function (value: string) {
        // @ts-expect-error
        const { path, createError } = this;

        value = value && value.replaceAll(/\D*/g, '');

        if (!validateCPF(value)) {
          return createError({
            path,
            message,
          });
        }

        return true;
      })
      .required(TYPE_CPF);
  });
};

export const yupInvalidCNPJ = () => {
  Yup.addMethod<any>(Yup.StringSchema, 'isValidCNPJ', function (message: string) {
    const self = this;
    return self
      .test('isValidCNPJ', message, function (value: string) {
        // @ts-expect-error
        const { path, createError } = this;

        value = value && value.replaceAll(/\D*/g, '');

        if (!validateCNPJ(value)) {
          return createError({
            path,
            message,
          });
        }

        return true;
      })
      .required(TYPE_VALID_CNPJ);
  });
};

export const yupInvalidPassword = (edit = false) => {
  Yup.addMethod<any>(Yup.StringSchema, 'isValidPassword', function (message: string) {
    const self = this;
    return self.test('isValidPassword', message, function (value: string) {
      if (!value && edit) {
        return true;
      }

      // @ts-expect-error
      const { path, createError } = this;

      if (!validatePassword(value)) {
        return createError({
          path,
          message,
        });
      }

      return true;
    });
  });
};
