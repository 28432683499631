import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import withRouter from '@common/withRouter';
import {
  CITY_MANDATORY,
  COUNTRY_MANDATORY,
  NEIGHBOURHOOD_MANDATORY,
  NUMBER_MANDATORY,
  REDIRECT_TIMEOUT,
  STATE_MANDATORY,
  STREET_MANDATORY,
  ZIPCODE_MANDATORY,
} from '@helpers/constants';
import { create } from '@modules/Client/submodules/Addresses/services';
import { IClientAdressesCreate } from '@modules/Client/types';
import ClientAddressesForm from '@modules/Client/submodules/Addresses/components/ClientAddressesForm';

const ClientAddressesCreate = ({ router }: IWithRouter) => {
  const { clientID } = useParams();
  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IClientAdressesCreate) => create(clientID as string, payload),
    onSuccess: () => {
      setTimeout(() => {
        router.navigate(`/clients/${clientID}/addresses`);
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      zipcode: '',
      country: '',
      state: '',
      city: '',
      neighbourhood: '',
      complement: '',
      street: '',
      number: 0,
    },
    validationSchema: Yup.object({
      zipcode: Yup.string().required(ZIPCODE_MANDATORY),
      state: Yup.string().required(STATE_MANDATORY),
      country: Yup.string().required(COUNTRY_MANDATORY),
      city: Yup.string().required(CITY_MANDATORY),
      neighbourhood: Yup.string().required(NEIGHBOURHOOD_MANDATORY),
      complement: Yup.string(),
      street: Yup.string().required(STREET_MANDATORY),
      number: Yup.number().required(NUMBER_MANDATORY),
    }),
    onSubmit: async (payload: IClientAdressesCreate) => mutate(payload),
  });

  return (
    <Page
      title="Cadastrar endereço do cliente"
      breadcrumb={{
        title: 'Endereços',
        titleHref: `/clients/${clientID}/addresses`,
        breadcrumbItem: 'Cadastrar endereço',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Criar novo endereço</CardTitle>
              <div className="p-2 mt-3">
                <ClientAddressesForm
                  loading={isPending}
                  validation={validation}
                  registrationError={error?.message}
                  showAlert={isSuccess}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(ClientAddressesCreate);
