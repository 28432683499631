import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import TableContainer from '@common/TableContainer';
import withRouter from '@common/withRouter';
import { buildColumns } from '@modules/Contract/components/Table/constants';
import { deleteByID, exportData, getAll } from '@modules/Contract/services';
import { ColumnFiltersState } from '@tanstack/react-table';

const Contract = ({ router, page, size, setSearchParams }: IWithRouter) => {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const { user }: any = useSelector((state: any) => state.Profile);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['contracts', page, size, filters],
    queryFn: () => getAll(page, size, undefined, filters),
    initialData: { items: [], totalItems: 0 },
    retry: 1,
  });

  const deleteMutation = useMutation({
    mutationFn: () => deleteByID(deleteContractID || ''),
    onSuccess: () => {
      toast.success('Contrato deletado com sucesso!', {
        theme: 'colored',
        autoClose: 1500,
      });
      refetch();
      setSearchParams({ page: '1' });
      setDeleteModal(false);
    },
  });

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteContractID, setDeleteContractID] = useState<string | undefined>();

  const handleDeleteContractModal = (contractID: string) => {
    setDeleteContractID(contractID);
    setDeleteModal(true);
  };

  const handleExportData = async () => {
    const fileUrl = await exportData(page, size, filters);

    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = `contracts-export.csv`;
    link.click();
  };

  return (
    <Page
      title="Contratos"
      breadcrumb={{
        title: 'Contratos',
        titleHref: '/contracts',
        breadcrumbItem: 'Listar contratos',
      }}
    >
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <TableContainer
                moduleName="contracts"
                innerJoinColumns={['client_code', 'partner_name', 'client_name']}
                columns={buildColumns(user?.is_admin, handleDeleteContractModal)}
                customElement={
                  <Button type="button" color="primary" outline onClick={handleExportData}>
                    Exportar dados
                  </Button>
                }
                customPageSize={size}
                currentPage={page}
                data={data.items}
                loading={isFetching}
                totalItems={data.totalItems}
                deleteModal={{
                  isOpen: deleteModal,
                  loading: isFetching,
                  onDeleteClick: deleteMutation.mutate,
                  onCloseClick: () => setDeleteModal(false),
                }}
                handleFilters={setFilters}
                setSearchParams={setSearchParams}
                refetch={refetch}
                isAddRegister
                isPagination
                isShowingPageLength
                isDelete
                handleAddRegisterClick={() => router.navigate('/contracts/create')}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(Contract);
