import React, { useCallback, FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { Card, CardBody, CardText } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

interface FileDropzoneProps {
  allowedFiles: string;
  onFileDrop: (file: { file: string; name: string; type: string; path: string }) => void;
}

const FileDropzone: FC<FileDropzoneProps> = ({ allowedFiles, onFileDrop }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onload = () => {
          let binaryStr = reader.result;
          if (typeof binaryStr === 'string') {
            binaryStr = binaryStr.split(',')[1]; // remove the content before the comma
            const fileType = file.type.split('/')[1].toUpperCase();
            onFileDrop({
              file: binaryStr,
              name: file.name,
              type: fileType,
              path: `${uuidv4()}.${fileType.toLocaleLowerCase()}`,
            });
          }
        };

        reader.readAsDataURL(file);
      });
    },
    [onFileDrop],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, application/pdf',
  });

  return (
    <Card
      {...getRootProps()}
      style={{
        cursor: 'pointer',
        maxWidth: '500px',
        height: '300px',
        border: '2px dashed #ddd',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CardBody
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <input {...getInputProps()} />
        <i className="mdi mdi-plus-circle-outline" style={{ fontSize: '48px' }}></i>
        {isDragActive ? (
          <CardText>Solte os arquivos aqui ...</CardText>
        ) : (
          <CardText>
            Arraste e solte alguns arquivos aqui, ou clique para selecionar arquivos
          </CardText>
        )}
        <CardText>Arquivos permitidos: {allowedFiles}</CardText>
      </CardBody>
    </Card>
  );
};

export default FileDropzone;
