import React from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentStep } from '@slices/partnerRegister/reducer';

const Steps = () => {
  const { currentStep, lastStepFinished } = useSelector((state: any) => state.PartnerRegister);
  const dispatch: any = useDispatch();

  const handleChangeStep = (step: number) => {
    dispatch(setCurrentStep(step));
  };

  return (
    <ul className="steps">
      <li
        className={classnames('steps__item', {
          'steps__item--active': currentStep === 1,
          'steps__item--finished': lastStepFinished >= 1,
        })}
      >
        <a href="#" className="steps__link" onClick={() => handleChangeStep(1)}>
          <div>Acessor</div>
        </a>
      </li>
      <li
        className={classnames('steps__item', {
          'steps__item--active': currentStep === 2,
          'steps__item--finished': lastStepFinished >= 2,
        })}
      >
        <a href="#" className="steps__link" onClick={() => handleChangeStep(2)}>
          <div>Documentos</div>
        </a>
      </li>
      <li
        className={classnames('steps__item', {
          'steps__item--active': currentStep === 3,
          'steps__item--finished': lastStepFinished >= 3,
        })}
      >
        <a href="#" className="steps__link" onClick={() => handleChangeStep(3)}>
          <div>Contatos</div>
        </a>
      </li>
      <li
        className={classnames('steps__item', {
          'steps__item--active': currentStep === 4,
          'steps__item--finished': lastStepFinished >= 4,
        })}
      >
        <a href="#" className="steps__link" onClick={() => handleChangeStep(4)}>
          <div>Endereços</div>
        </a>
      </li>
      <li
        className={classnames('steps__item', {
          'steps__item--active': currentStep === 5,
          'steps__item--finished': lastStepFinished >= 5,
        })}
      >
        <a href="#" className="steps__link" onClick={() => handleChangeStep(5)}>
          <div>Dados bancários</div>
        </a>
      </li>
    </ul>
  );
};

export default Steps;
