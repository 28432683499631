import React, { useState } from 'react';
import { IActionModal } from '@modules/Transactions/types';
import { Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap';

const ActionModal = ({
  show,
  actionDescription,
  actionButtonText,
  actionButtonColor = 'danger',
  transactionType,
  onActionClick,
  onCloseClick,
}: IActionModal) => {
  const [paymentType, setPaymentType] = useState('');
  const [invalidPayment, setInvalidPayment] = useState(false);

  const handleChangePaymentType = (e: any) => setPaymentType(e.target.value);

  const handleClick = () => {
    if (!paymentType && transactionType === 'APORTE') {
      setInvalidPayment(true);
      return;
    }

    onActionClick(paymentType);
    setInvalidPayment(false);
    setPaymentType('');
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
          <p className="text-muted font-size-16 mb-4">{actionDescription}</p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <Row>
              {transactionType === 'APORTE' && (
                <FormGroup>
                  <Col md={12}>
                    <Label className="col-form-label d-flex">Tipo de pagamento</Label>
                    <Input
                      id="payment_type"
                      name="payment_type"
                      className="form-control"
                      type="select"
                      onChange={handleChangePaymentType}
                    >
                      <option value="">Selecione um tipo de pagamento</option>
                      <option value="DINHEIRO">DINHEIRO</option>
                      <option value="PIX">PIX</option>
                      <option value="TED">TED</option>
                      <option value="BOLETO">BOLETO</option>
                      <option value="CHEQUE">CHEQUE</option>
                      <option value="A_NEGOCIAR">A NEGOCIAR</option>
                    </Input>
                    {invalidPayment && (
                      <FormFeedback type="invalid" className="d-block text-start">
                        O tipo de pagamento é obrigatório!
                      </FormFeedback>
                    )}
                  </Col>
                </FormGroup>
              )}

              <Col md={12} className="d-flex justify-content-center">
                <button
                  type="button"
                  disabled={!paymentType && transactionType === 'APORTE'}
                  className={`btn btn-${actionButtonColor} me-2`}
                  onClick={handleClick}
                >
                  {actionButtonText}
                </button>
                <button type="button" className="btn btn-secondary" onClick={onCloseClick}>
                  Cancelar
                </button>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default ActionModal;
