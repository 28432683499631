import React from 'react';
import classNames from 'classnames';
import { Row, flexRender } from '@tanstack/react-table';

export const Tbody = ({
  dragActiveColumn,
  dragOver,
  rows,
}: {
  dragActiveColumn?: number;
  dragOver?: number;
  rows: Array<Row<unknown>>;
}) => (
  <tbody>
    {rows.map((row: any) => (
      <tr key={row.id}>
        {row.getVisibleCells().map((cell, index) => (
          <td
            className={classNames(`${dragActiveColumn} ${index}`, {
              'table-draggable-active_tbody': dragActiveColumn === index,
              'table-draggle-hover': dragOver === index,
            })}
            colSpan={cell.colSpan}
            key={cell.id}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);
