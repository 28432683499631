import { getWithExpiry, setWithExpiry } from '@helpers/localStorage';
import {
  IPartnerAdressesCreate,
  IPartnerBankDataCreate,
  IPartnerContactsCreate,
  IPartnerCreate,
  IPartnerDocumentsCreate,
} from '@modules/Partners/types';
import { createSlice } from '@reduxjs/toolkit';

interface PartnerRegisterItem<T> {
  isFinished: boolean;
  data?: T;
}

interface PartnerRegisterState {
  stateLoaded: boolean;
  currentStep: number;
  lastStepFinished: number | null;
  PARTNER?: PartnerRegisterItem<IPartnerCreate>;
  ADDRESSES?: PartnerRegisterItem<IPartnerAdressesCreate[]>;
  BANK_DATAS?: PartnerRegisterItem<IPartnerBankDataCreate[]>;
  CONTACTS?: PartnerRegisterItem<IPartnerContactsCreate[]>;
  DOCUMENTS?: PartnerRegisterItem<IPartnerDocumentsCreate[]>;
}

export const initState: PartnerRegisterState = {
  stateLoaded: false,
  currentStep: 1,
  lastStepFinished: null,
  PARTNER: {
    isFinished: false,
    data: undefined,
  },
  DOCUMENTS: {
    isFinished: false,
    data: [],
  },
  CONTACTS: {
    isFinished: false,
    data: [],
  },
  ADDRESSES: {
    isFinished: false,
    data: [],
  },
  BANK_DATAS: {
    isFinished: false,
    data: [],
  },
};

const saveState = (state: PartnerRegisterState) => {
  const thirtyMinutes = 1000 * 60 * 30;
  setWithExpiry('partnerRegister', JSON.stringify(state), thirtyMinutes);
};

export const getPartnerRegisterState = (): PartnerRegisterState | null => {
  const state = getWithExpiry('partnerRegister');

  if (state) {
    return JSON.parse(state) as PartnerRegisterState;
  }

  return null;
};

const PartnerRegister = createSlice({
  name: 'PartnerRegister',
  initialState: initState,
  reducers: {
    setCurrentStep(state, action) {
      const lastStepFinished = Number(state.lastStepFinished) || 1;
      if (lastStepFinished + 1 >= action.payload) {
        state.currentStep = Number(action.payload) || 0;
      }
    },
    finishStep(state, action) {
      state.currentStep = Number(action.payload.currentStep) + 1;
      state.lastStepFinished = Number(action.payload.currentStep);
      state.stateLoaded = true;
      state[action.payload.step] = {
        ...state[action.payload.step],
        isFinished: true,
      };

      saveState(state);
    },
    setStepData(state, action) {
      state[action.payload.step] = {
        ...state[action.payload.step],
        data: action.payload.data,
      };
      state.stateLoaded = true;

      saveState(state);
    },
    setPartnerRegisterState(state, action) {
      state.stateLoaded = true;
      state.currentStep = action.payload.currentStep;
      state.lastStepFinished = action.payload.lastStepFinished;

      state.PARTNER = action.payload.PARTNER;
      state.DOCUMENTS = action.payload.DOCUMENTS;
      state.CONTACTS = action.payload.CONTACTS;
      state.ADDRESSES = action.payload.ADDRESSES;
      state.BANK_DATAS = action.payload.BANK_DATAS;
    },
    resetData(state) {
      state.stateLoaded = true;
      state.currentStep = initState.currentStep;
      state.lastStepFinished = initState.lastStepFinished;

      state.PARTNER = initState.PARTNER;
      state.DOCUMENTS = initState.DOCUMENTS;
      state.CONTACTS = initState.CONTACTS;
      state.ADDRESSES = initState.ADDRESSES;
      state.BANK_DATAS = initState.BANK_DATAS;

      localStorage.removeItem('partnerRegister');
    },
  },
});

export const { setCurrentStep, finishStep, setStepData, resetData, setPartnerRegisterState } =
  PartnerRegister.actions;
export default PartnerRegister.reducer;
