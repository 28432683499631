import instance from '@helpers/request/instance';

import { InstanceError } from '@apptypes/instance';
import { IPagination } from '@apptypes/pagination';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import { IPartnerAdressesCreate } from '@modules/Partners/types';
import { ColumnFiltersState } from '@tanstack/react-table';
import { generateToQueryString } from '@helpers/queryString';

export const getAll = async (
  partnerID: string,
  page: number,
  size: number,
  filterItems?: ColumnFiltersState,
): Promise<IPagination<IPartnerAdressesCreate>> => {
  try {
    const queryString = generateToQueryString({
      page,
      size,
      filterItems,
      otherParams: partnerID ? `&partner_id=${partnerID}` : '',
    });
    const { data } = await instance.get(`/contracts${queryString}`);
    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Contratos não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getPartners = async (
  partnerID: string,
  page: number,
): Promise<IPagination<IPartnerAdressesCreate>> => {
  try {
    const { data } = await instance.get(`/contracts?partner_id=${partnerID}&page=${page}&size=5`);
    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Contratos não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
