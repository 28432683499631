import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import TableContainer from '@common/TableContainer';
import withRouter from '@common/withRouter';
import ActionModal from '@modules/Transactions/components/ActionModal';
import { buildColumns } from '@modules/Transactions/components/Table/constants';
import { approveTransactionsByID, getAll } from '@modules/Transactions/services';
import { ColumnFiltersState } from '@tanstack/react-table';

const TransactionsPending = ({ page, size, setSearchParams }: IWithRouter) => {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const { user }: any = useSelector((state: any) => state.Profile);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['transactions', { page, size, filters }],
    queryFn: () => getAll(page, size, filters),
    initialData: { items: [], totalItems: 0 },
    retry: 1,
  });

  const approveMutation = useMutation({
    mutationFn: (payload: { transactionID: string; status: boolean; paymentType: string }) =>
      approveTransactionsByID(payload.transactionID, payload.status, payload.paymentType),
    onSuccess: () => {
      toast.success('Transação aprovada com sucesso!', {
        theme: 'colored',
        autoClose: 1500,
      });

      setSearchParams({ page: '1' });
      setActionModal(false);
      refetch();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: 2000,
        theme: 'colored',
      });
    },
  });

  const [actionModal, setActionModal] = useState<boolean>(false);
  const [transactionType, setTransactionType] = useState<any>(null);
  const [modalType, setModalType] = useState<string>('');
  const [transactionSelectedID, setTransactionSelectedID] = useState<any>(null);

  const handleTransactionSelectedModal = (
    transactionID: string,
    action: string,
    transactionType: string,
  ) => {
    setTransactionSelectedID(transactionID);
    setTransactionType(transactionType);
    setModalType(action);
    setActionModal(true);
  };

  const handleAcceptTransaction = async (paymentType: string) => {
    if (transactionSelectedID) {
      const status = modalType === 'accept';
      approveMutation.mutate({
        transactionID: transactionSelectedID,
        status,
        paymentType,
      });
    }
  };

  return (
    <Page
      title="Transações pendentes"
      breadcrumb={{
        title: 'Transações pendentes',
        titleHref: '/transactions',
        breadcrumbItem: 'Listar transações pendentes',
      }}
    >
      <ActionModal
        show={actionModal}
        actionDescription={
          transactionType === 'APORTE'
            ? 'Antes de aprovar o aporte, selecione do tipo de pagamento!'
            : 'Deseja aprovar a retirada?'
        }
        actionButtonText="Aprovar"
        actionButtonColor="success"
        transactionType={transactionType}
        onActionClick={handleAcceptTransaction}
        onCloseClick={() => setActionModal(false)}
      />

      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <TableContainer
                moduleName="transactions"
                columns={buildColumns(user?.is_admin, handleTransactionSelectedModal)}
                customPageSize={size}
                currentPage={page}
                data={data.items}
                loading={isFetching}
                totalItems={data.totalItems}
                handleFilters={setFilters}
                setSearchParams={setSearchParams}
                refetch={refetch}
                isPagination
                isShowingPageLength
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(TransactionsPending);
