import { combineReducers } from 'redux';

// Front
import LayoutReducer from './layouts/reducer';
import LoginReducer from './auth/login/reducer';
import AccountReducer from './auth/register/reducer';
import UsersReducer from './users/reducer';
import ProfileReducer from './auth/profile/reducer';
import ClientReducer from './clientRegister/reducer';
import PartnerReducer from './partnerRegister/reducer';

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  Users: UsersReducer,
  Profile: ProfileReducer,
  ClientRegister: ClientReducer,
  PartnerRegister: PartnerReducer,
});

export default rootReducer;
