import React from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import Spinners from '@common/Spinner';
import withRouter from '@common/withRouter';
import { CONTACT_TYPE_MANDATORY, REDIRECT_TIMEOUT, VALUE_MANDATORY } from '@helpers/constants';
import { getByID, update } from '@modules/Partners/submodules/Contacts/services';
import { IPartnerContactsCreate } from '@modules/Partners/types';
import PartnersContactsForm from '@modules/Partners/submodules/Contacts/components/PartnersContactForm';

const PartnersDocumentUpdate = ({ router }: IWithRouter) => {
  const { partnerID, contactID } = useParams();
  const { data, isFetching } = useQuery({
    queryKey: ['partnerContact', { contactID }],
    queryFn: () => getByID(contactID || ''),
    retry: 1,
  });

  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IPartnerContactsCreate) =>
      update(contactID as string, partnerID as string, payload),
    onSuccess: () => {
      setTimeout(() => {
        router.navigate(`/partners/${partnerID}/contacts`);
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: data?.type || '',
      value: data?.value || '',
      primary_contact: data?.primary_contact || false,
    },
    validationSchema: Yup.object({
      type: Yup.string().required(CONTACT_TYPE_MANDATORY),
      value: Yup.string().required(VALUE_MANDATORY),
      primary_contact: Yup.boolean(),
    }),
    onSubmit: async (payload: IPartnerContactsCreate) => mutate(payload),
  });

  return (
    <Page
      title="Editar contato do acesso"
      breadcrumb={{
        title: 'Contatos',
        titleHref: `/partners/${partnerID}/contacts`,
        breadcrumbItem: 'Editar contato',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Editar contato</CardTitle>
              {isFetching ? (
                <Spinners />
              ) : (
                <div className="p-2 mt-3">
                  <PartnersContactsForm
                    loading={isPending}
                    validation={validation}
                    registrationError={error?.message}
                    type="edit"
                    showAlert={isSuccess}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(PartnersDocumentUpdate);
