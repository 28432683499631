import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { deleteCookie, getCookie } from '@helpers/cookies';
import { AUTH_COOKIE_NAME, USER_ID_COOKIE_NAME } from '@helpers/constants';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import { getAuthUser } from '@slices/users/thunk';

const AuthProtected = (props: { children: any }) => {
  const dispatch: any = useDispatch();
  const { error } = useSelector((state: any) => state.Profile);

  useEffect(() => {
    (async function () {
      const userID = getCookie(USER_ID_COOKIE_NAME);
      if (userID) {
        const response = await getAuthUser({ userID });
        dispatch(response);
      }
    })();
  }, []);

  useEffect(() => {
    if (error?.response?.data?.status === ERROR_STATUS_MESSAGE.UNAUTHORIZED) {
      deleteCookie(USER_ID_COOKIE_NAME);
      window.location.href = '/login';
    }
  }, [error]);

  if (!getCookie(AUTH_COOKIE_NAME)) {
    return <Navigate to={{ pathname: '/login' }} />;
  }

  return <>{props.children}</>;
};

export default AuthProtected;
