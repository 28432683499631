import React from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import Spinners from '@common/Spinner';
import withRouter from '@common/withRouter';
import { DOCUMENT_TYPE_MANDATORY, REDIRECT_TIMEOUT, VALUE_MANDATORY } from '@helpers/constants';
import { getByID, update } from '@modules/Client/submodules/Documents/services';
import { getByID as getClientByID } from '@modules/Client/services';
import ClientDocumentForm from '@modules/Client/submodules/Documents/components/ClientDocumentForm';
import { IClientDocumentsCreate } from '@modules/Client/types';
import { TYPE_VALID_CNPJ, TYPE_VALID_CPF } from '@modules/Users/constants';
import { yupInvalidCNPJ, yupInvalidCPF } from '@helpers/validations';

const ClientDocumentUpdate = ({ router }: IWithRouter) => {
  yupInvalidCPF();
  yupInvalidCNPJ();

  const { clientID, documentID } = useParams();
  const { data, isFetching } = useQuery({
    queryKey: ['clientDocument', { documentID }],
    queryFn: () => getByID(documentID || ''),
    retry: 1,
  });

  const { data: client } = useQuery({
    queryKey: ['client', clientID],
    queryFn: () => getClientByID(clientID || ''),
  });

  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IClientDocumentsCreate) => update(documentID as string, payload),
    onSuccess: () => {
      setTimeout(() => {
        router.navigate(`/clients/${clientID}/documents`);
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: data?.type || '',
      value: data?.value || '',
    },
    validationSchema: Yup.object({
      type: Yup.string().required(DOCUMENT_TYPE_MANDATORY),
      value: Yup.string()
        .ensure()
        .when('type', {
          is: 'CPF',
          then: (Yup.string() as any).isValidCPF(TYPE_VALID_CPF),
        })
        .when('type', {
          is: 'CNPJ',
          then: (Yup.string() as any).isValidCNPJ(TYPE_VALID_CNPJ),
        })
        .required(VALUE_MANDATORY),
    }),
    onSubmit: async (payload: IClientDocumentsCreate) => mutate(payload),
  });

  return (
    <Page
      title="Editar documento do cliente"
      breadcrumb={{
        title: 'Documentos',
        titleHref: `/clients/${clientID}/documents`,
        breadcrumbItem: 'Editar documento',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Editar documento</CardTitle>
              {isFetching ? (
                <Spinners />
              ) : (
                <div className="p-2 mt-3">
                  <ClientDocumentForm
                    loading={isPending}
                    validation={validation}
                    registrationError={error?.message}
                    type="edit"
                    showAlert={isSuccess}
                    clientType={client?.type}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(ClientDocumentUpdate);
