import React from 'react';
import { Navigate } from 'react-router-dom';

// Dashboard
import Dashboard from '@pages/Dashboard';

// Authentication
import Login from '@pages/Authentication/login';
import Logout from '@pages/Authentication/Logout';

import Pages404 from '@pages/Utility/pages-404';

import { userRoutes } from '@modules/Users/routes';
import { profitabilityGroupRoutes } from '@modules/ProfitabilityGroup/routes';
import { calendarRoutes } from '@modules/Calendar/routes';
import { contractRoutes } from '@modules/Contract/routes';
import { transactionRoutes } from '@modules/Transactions/router';
import { pendingWithdrawalsRoutes } from '@modules/PendingWithdrawals/router';
import { clientRoutes } from '@modules/Client/routes';
import { partnersRoutes } from '@modules/Partners/routes';
import { reportsRoutes } from '@modules/Reports/routes';

const authProtectedRoutes = [
  { path: '/dashboard', component: <Dashboard /> },

  // Users
  ...userRoutes,

  // Clients
  ...clientRoutes,

  // Profitability Group
  ...profitabilityGroupRoutes,

  // Contracts
  ...contractRoutes,

  // Transactions
  ...transactionRoutes,

  // Pending Withdrawals
  ...pendingWithdrawalsRoutes,

  // Calendar
  ...calendarRoutes,

  // Partners
  ...partnersRoutes,

  // Reports
  ...reportsRoutes,

  { path: '/', exact: true, component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  { path: '/login', component: <Login /> },
  { path: '/logout', component: <Logout /> },
  { path: '/', component: <Navigate to="/dashboard" replace={true} /> },
  { path: '*', exact: true, component: <Pages404 /> },
];

export { authProtectedRoutes, publicRoutes };
