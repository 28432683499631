import { ColumnFiltersState } from '@tanstack/react-table';

export function generateToQueryString({
  page,
  size,
  filterItems,
  otherParams = '',
}: {
  page: number;
  size: number;
  filterItems?: ColumnFiltersState;
  otherParams?: string;
}) {
  let queryString = '';

  if (filterItems) {
    queryString = filterItems.reduce(
      (acc, item: Record<string, any>) => `${acc}${item.id}=${item.value}&`,
      '',
    );
  }

  return `?page=${page}&size=${size}&${queryString}${otherParams}`;
}

export function orderByQueryStringToArray(orderBy: string) {
  const matches = orderBy.match(/\[\[(.*?)\]\]/);
  if (!matches || matches.length < 2) {
    return [];
  }
  const innerString = matches[1];

  return innerString.split('],[').map((pair) => {
    // eslint-disable-next-line no-useless-escape
    const [id, order] = pair.replace(/[\[\]']/g, '').split(',');

    return {
      id,
      desc: order === 'DESC',
    };
  });
}
