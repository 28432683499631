import React from 'react';

import PaymentFiles from '@modules/Partners/submodules/PaymentFiles/pages';

export const paymentFilesRoutes = [
  {
    path: '/partners/:partnerID/payments/:partnerPaymentID/files',
    component: <PaymentFiles />,
  },
];
