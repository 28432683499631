import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  Input,
  Row,
  Table,
} from 'reactstrap';
import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import { useMutation } from '@tanstack/react-query';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { IDocumentIntegrate, IPartnerDocumentsCreate, IStep } from '@modules/Partners/types';

import { documentsIntegrateRegister } from '@modules/Partners/submodules/Documents/services';
import { TYPE_VALID_CNPJ, TYPE_VALID_CPF } from '@modules/Users/constants';
import { yupInvalidCNPJ, yupInvalidCPF } from '@helpers/validations';

const DocumentStep = ({
  data,
  partnerID,
  partnerType,
  incrementData,
  incrementStep,
}: IStep<IPartnerDocumentsCreate[]>) => {
  yupInvalidCPF();
  yupInvalidCNPJ();

  const { mutate } = useMutation({
    mutationFn: (payload: any) =>
      documentsIntegrateRegister(partnerID || '', payload, data?.length !== 0),
    onSuccess: (documents: any) => {
      incrementData(documents);

      toast.success('Documentos salvos com sucesso!', {
        theme: 'colored',
        autoClose: 750,
        onClose: () => incrementStep(),
      });
    },
    onError: () => {
      toast.error('Error ao salvar os documentos', {
        theme: 'colored',
        autoClose: 2000,
      });
    },
  });

  const newDocuments: string[][] = [];
  for (const documents in data) {
    const { type, value } = data[documents];
    newDocuments.push([type, value]);
  }

  const getFormFieldValue = (type: string) => {
    return data?.filter((item) => item.type === type)[0]?.value || '';
  };

  const documentTypes: Record<'F' | 'J', string[]> = {
    F: ['CPF', 'RG'],
    J: ['CNPJ', 'IE', 'IM'],
  };

  const getMask = (type?: string) => {
    switch (type) {
      case 'CPF':
        return '999.999.999-99';
      case 'CNPJ':
        return '99.999.999/9999-99';
      case 'IE':
        return '999.999.999.999';
      default:
        return '';
    }
  };

  const validationSchemaClientType = {
    F: Yup.object({
      CPF: (Yup.string() as any).isValidCPF(TYPE_VALID_CPF),
      RG: Yup.string().required('Campo obrigatório'),
    }),
    J: Yup.object({
      CNPJ: (Yup.string() as any).isValidCNPJ(TYPE_VALID_CNPJ),
      IE: Yup.string(),
      IM: Yup.string(),
    }),
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      CPF: getFormFieldValue('CPF'),
      CNPJ: getFormFieldValue('CNPJ'),
      RG: getFormFieldValue('RG'),
      IE: getFormFieldValue('IE'),
      IM: getFormFieldValue('IM'),
    },
    validationSchema: validationSchemaClientType[partnerType || 'F'],
    onSubmit: async (payload: IDocumentIntegrate) => {
      const documents = Object.entries(payload).map(([type, value]) => {
        const newData = data?.filter((item) => item.type === type)[0] || {};

        return {
          ...newData,
          type,
          value,
        };
      });

      mutate(documents);
    },
  });

  function handleChange(type: string, e: any) {
    if (['RG', 'IM'].includes(type)) {
      validation.setFieldValue(type, e.target.value);
      return;
    }

    const value = e.target.value.replace(/\D*/g, '');
    validation.setFieldValue(type, value);
  }

  return (
    <Row>
      <Col sm={12}>
        <Form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
          }}
        >
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Documentos do acessor</CardTitle>
              <Table className="p-2 mt-3">
                <thead>
                  <tr>
                    <th>Tipo de documento</th>
                    <th>Número do documento</th>
                  </tr>
                </thead>
                <tbody>
                  {documentTypes[partnerType || 'F'].map((type) => (
                    <tr key={type}>
                      <td className="d-flex align-items-center" style={{ height: 54 }}>
                        {type}
                      </td>
                      <td className="position-relative">
                        <InputMask
                          mask={getMask(type)}
                          name={type}
                          value={validation.values[type] || ''}
                          onChange={(e: any) => handleChange(type, e)}
                        >
                          <Input
                            type="text"
                            placeholder="Digite o número do documento"
                            invalid={!!(validation.touched[type] && validation.errors[type])}
                          />
                        </InputMask>
                        {validation.touched[type] && validation.errors[type] ? (
                          <FormFeedback style={{ position: 'absolute', bottom: -8 }} type="invalid">
                            {validation.errors[type] as any}
                          </FormFeedback>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>

          <div className="d-flex justify-content-end mb-4">
            <Button color="primary" type="submit">
              Salvar e próxima etapa
              <i className="mdi mdi-chevron-right"></i>
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default DocumentStep;
