import getChartColorsArray from '@component/common/ChartDynamicColor';
import { CustodyReportProps } from '../types';

export const custodyReportInitialData = {
  total_custody: {},
  custody_projection: {},
  recurring_withdrawals: {},
  withdrawals_relocation: {},
  withdrawals: {},
  contracts: {
    recurring: {},
    not_recurring: {},
  },
};

export const getGraphData = (data: CustodyReportProps) => {
  const typesOptions = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: 'item',
      formatter: `{a} <br/>{b} : {c} ({d}%)`,
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      data: ['Retirada recorrentes', 'Retiradas não recorrentes'],
      textStyle: {
        color: ['#8791af'],
      },
    },
    color: getChartColorsArray(
      '["--bs-primary","--bs-danger", "--bs-warning","--bs-info", "--bs-success"]',
    ),
    series: [
      {
        name: 'Modalidade de retiradas',
        type: 'pie',
        radius: '55%',
        center: ['50%', '60%'],
        data: [
          { value: Number(data.contracts.recurring.count), name: 'Retirada recorrentes' },
          { value: Number(data.contracts.not_recurring.count), name: 'Retiradas não recorrentes' },
        ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };

  const valueOptions = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : R$ {c} ({d}%)',
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      data: ['Valor em retirada recorrentes', 'Valor em retiradas não recorrentes'],
      textStyle: {
        color: ['#8791af'],
      },
    },
    color: getChartColorsArray(
      '["--bs-success","--bs-info", "--bs-warning","--bs-info", "--bs-success"]',
    ),
    series: [
      {
        name: 'Valor em modalidade de retiradas',
        type: 'pie',
        radius: '55%',
        center: ['50%', '60%'],
        data: [
          { value: Number(data.contracts.recurring.value), name: 'Valor em retirada recorrentes' },
          {
            value: Number(data.contracts.not_recurring.value),
            name: 'Valor em retiradas não recorrentes',
          },
        ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };

  return { typesOptions, valueOptions };
};
