import { IStepTable } from '@modules/Client/types';
import React from 'react';
import { Table } from 'reactstrap';

export const StepTable = ({ columns, rows }: IStepTable) => (
  <Table>
    <thead>
      <tr>
        {columns.map((column) => (
          <th key={column}>{column}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {rows &&
        rows?.map((row, index) => (
          <tr key={index}>
            {row.map((column) => (
              <td key={column}>{column}</td>
            ))}
          </tr>
        ))}
    </tbody>
  </Table>
);
