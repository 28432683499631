import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

export const buildColumns = (isAdmin: boolean, onDeleteModal: (data: string) => void) => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('description', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/profitability-groups/${row.original.id}`} className="text-dark">
            {getValue().length >= 20 ? getValue().substring(0, 20) + '...' : getValue()}
          </Link>
        </h5>
      ),
      header: 'Descrição',
      size: 10,
    }),
    columnHelper.accessor('percentage', {
      cell: ({ getValue }: any) => (
        <p className="text-muted mb-0">{getValue()?.replace('.', ',')}%</p>
      ),
      header: 'Porcentagem',
      size: 5,
    }),
    columnHelper.accessor('deposit_business_days', {
      cell: ({ getValue }: any) => <p className="text-muted mb-0">{getValue()}</p>,
      header: 'Dias úteis para depósito',
      size: 10,
    }),
    columnHelper.accessor('withdrawal_execution_day', {
      cell: ({ getValue }: any) => <p className="text-muted mb-0">{getValue()}</p>,
      header: 'Dias execução do saque',
      size: 10,
    }),
    columnHelper.accessor('withdrawal_calendar_days', {
      cell: ({ getValue }: any) => <p className="text-muted mb-0">{getValue()}</p>,
      header: 'Dias corridos para retirada',
      size: 10,
    }),
    columnHelper.accessor('type', {
      cell: ({ getValue }: any) => (
        <>
          {getValue() === 'JS' && (
            <Badge pill className="font-size-12" color="primary">
              Juros Simples
            </Badge>
          )}
          {getValue() === 'JC' && (
            <Badge pill className="font-size-12" color="primary">
              Juros Compostos
            </Badge>
          )}
          {getValue() === 'JCS' && (
            <Badge pill className="font-size-12" color="primary">
              Juros Compostos Semanal
            </Badge>
          )}
          {getValue() === 'JCQ' && (
            <Badge pill className="font-size-12" color="primary">
              Juros Compostos Quinzenal
            </Badge>
          )}
          {getValue() === 'JCM' && (
            <Badge pill className="font-size-12" color="primary">
              Juros Compostos Mensal
            </Badge>
          )}
        </>
      ),
      header: 'Tipo',
      size: 10,
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'JS', label: 'Juros Simples' },
          { value: 'JC', label: 'Juros Compostos' },
          { value: 'JCS', label: 'Juros Compostos Semanal' },
          { value: 'JCQ', label: 'Juros Compostos Quinzenal' },
          { value: 'JCM', label: 'Juros Compostos Mensal' },
        ],
      },
    }),
    columnHelper.accessor('active', {
      cell: ({ getValue }: any) => (
        <>
          {getValue() ? (
            <Badge pill className="font-size-12" color="success">
              Ativo
            </Badge>
          ) : (
            <Badge pill className="font-size-12" color="danger">
              Inativo
            </Badge>
          )}
        </>
      ),
      header: 'Status',
      size: 10,
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'true', label: 'Sim' },
          { value: 'false', label: 'Não' },
        ],
      },
    }),
    columnHelper.accessor('created_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Data de criação',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('updated_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Última atualização',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
  ];
};
