import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import TableContainer from 'components/common/TableContainer';
import { buildColumns } from '@modules/Partners/submodules/Contracts/components/Table/constants';
import { getAll } from '@modules/Partners/submodules/Contracts/services';
import { IRouter } from '@apptypes/WithRouter';
import { ColumnFiltersState } from '@tanstack/react-table';

interface IPartnerContracts {
  router: IRouter;
  partnerID?: string;
  page: number;
  size: number;
  setSearchParams: (params: any) => void;
}

const PartnerContracts = ({
  router,
  partnerID,
  page,
  size,
  setSearchParams,
}: IPartnerContracts) => {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const { user } = useSelector((state: any) => state.Profile);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['partnerContracts', partnerID, page, size, filters],
    queryFn: () => getAll(partnerID || '', page, size, filters),
    initialData: { items: [], totalItems: 0 },
    retry: 1,
  });

  return (
    <div className="d-flex clients-content">
      <Helmet>
        <title>Contratos do acessor | SGI</title>
      </Helmet>
      <TableContainer
        moduleName="partnersContracts"
        innerJoinColumns={['client_code', 'client_name', 'partner_name']}
        columns={buildColumns(user.is_admin, partnerID || '')}
        customPageSize={size}
        currentPage={page}
        data={data.items}
        loading={isFetching}
        totalItems={data.totalItems}
        handleFilters={setFilters}
        setSearchParams={setSearchParams}
        refeatch={refetch}
        isPagination
        isShowingPageLength
      />
    </div>
  );
};

export default PartnerContracts;
