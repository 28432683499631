import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  user: {},
  metadata: {},
  users: [],
  totalItems: 0,
  error: '',
  loading: false,
  wasEdited: false,
};

const Users = createSlice({
  name: 'Users',
  initialState,
  reducers: {
    apiError(state, action) {
      state.user = {};
      state.users = [];
      state.error = action.payload;
      state.loading = false;
    },
    getByID(state, action) {
      state.error = '';
      state.loading = false;
      state.user = action.payload;
      state.metadata = action.payload.metadata;
      state.wasEdited = false;
    },
    getAll(state, action) {
      state.error = '';
      state.loading = false;
      state.users = action.payload.items;
      state.totalItems = action.payload.totalItems;
      state.wasEdited = false;
    },
    updateByID(state, action) {
      state.error = '';
      state.loading = false;
      state.wasEdited = true;
    },
    setLoading(state, action) {
      state.error = '';
      state.loading = action.payload;
    },
    clearUser(state) {
      state.error = '';
      state.user = {};
      state.wasEdited = false;
    },
  },
});

export const { apiError, clearUser, getAll, getByID, setLoading, updateByID } = Users.actions;
export default Users.reducer;
