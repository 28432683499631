import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IPartnerCreate } from '@modules/Partners/types';
import { REDIRECT_TIMEOUT } from '@helpers/constants';
import PartnersForm from '@modules/Partners/components/PartnersForm';
import { update } from '@modules/Partners/services';
import { PERCENTAGE_MANDATORY } from '@modules/ProfitabilityGroup/constants';

const PartnersInfo = ({ partner, partnerID }: { partner?: IPartnerCreate; partnerID?: string }) => {
  document.title = 'Editar Acessor | SGI';

  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IPartnerCreate) => update(partnerID || '', payload),
    onSuccess: () => {
      setTimeout(() => {
        window.location.reload();
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      type: partner?.type || '',
      name: partner?.name || '',
      profession: partner?.profession || '',
      gender: partner?.gender || '',
      percentage: partner?.percentage || 0,
      active: partner?.active || true,
      observation: partner?.observation || '',
    },
    validationSchema: Yup.object({
      type: Yup.string(),
      name: Yup.string().required(PERCENTAGE_MANDATORY),
      profession: Yup.string(),
      gender: Yup.string(),
      percentage: Yup.number().required(PERCENTAGE_MANDATORY),
      active: Yup.string().required(),
      observation: Yup.string(),
    }),
    onSubmit: async (payload: IPartnerCreate) => mutate(payload),
  });

  return (
    <PartnersForm
      loading={isPending}
      validation={validation}
      registrationError={error?.message}
      showAlert={isSuccess}
      type="edit"
    />
  );
};

export default PartnersInfo;
