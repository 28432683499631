import { moneyTransform, transformPercentage } from '@helpers/transforms/money';
import React from 'react';
import { Badge, Card, CardBody } from 'reactstrap';
import { percentageBadge } from './utils';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface CustodyValueProps {
  value?: string;
  notProcessed?: string;
  percentage?: string;
  isLoading: boolean;
}

export const CustodyValue = ({ value, notProcessed, percentage, isLoading }: CustodyValueProps) => (
  <Card>
    <CardBody style={{ minHeight: 171 }}>
      <div className="d-flex align-items-center mb-3">
        <div className="avatar-xs me-3">
          {isLoading ? (
            <Skeleton circle height="100%" containerClassName="avatar-skeleton" />
          ) : (
            <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
              <i className="bx bx-money" />
            </span>
          )}
        </div>
        <h5 className="font-size-14 mb-0">
          {isLoading ? <Skeleton width={150} /> : 'Valor em custódia'}
        </h5>
      </div>
      <div className="text-muted mt-4">
        {isLoading ? (
          <Skeleton count={2} />
        ) : (
          <>
            <h4 className="mb-1">
              {moneyTransform(Number(value))}
              {Number(value) >= 0 ? (
                <i className="mdi mdi-chevron-up ms-1 text-success" />
              ) : (
                <i className="mdi mdi-chevron-down ms-1 text-danger" />
              )}
            </h4>
            <p className="text-danger mb-2">
              <Badge className="badge-soft-danger font-size-10 me-2">Não processado</Badge>
              {moneyTransform(Number(notProcessed))}
            </p>
            <div className="d-flex">
              <Badge className={`badge-soft-${percentageBadge(Number(percentage))} font-size-12`}>
                {transformPercentage(Number(percentage))}
              </Badge>
              <span className="ms-2 text-truncate">Do período anterior</span>
            </div>
          </>
        )}
      </div>
    </CardBody>
  </Card>
);
