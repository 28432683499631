import React from 'react';

import ContractsUpdate from '@modules/Contract/pages/update';

export const contractExtractRoutes = [
  {
    path: '/contracts/:contractID/contracts-extracts',
    component: <ContractsUpdate activePath="contracts-extracts" />,
  },
];
