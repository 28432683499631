import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';

import TableActions from '@common/TableActions';

export const buildColumns = (
  isAdmin: boolean,
  clientID: string,
  onDeleteModal: (data: string) => void,
) => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('zipcode', {
      header: 'CEP',
      size: 5,
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/clients/${clientID}/addresses/${row.original.id}`} className="text-dark">
            {getValue()}
          </Link>
        </h5>
      ),
    }),
    columnHelper.accessor('country', {
      header: 'País',
      size: 5,
      cell: (data: any) => <p className="text-muted mb-0">{data.getValue()}</p>,
    }),
    columnHelper.accessor('state', {
      header: 'Estado',
      size: 5,
      cell: (data: any) => <p className="text-muted mb-0">{data.getValue()}</p>,
    }),
    columnHelper.accessor('city', {
      header: 'Cidade',
      size: 10,
      cell: (data: any) => <p className="text-muted mb-0">{data.getValue()}</p>,
    }),
    columnHelper.accessor('neighbourhood', {
      header: 'Bairro',
      size: 10,
      cell: (data: any) => <p className="text-muted mb-0">{data.getValue()}</p>,
    }),
    columnHelper.accessor('street', {
      header: 'Rua',
      size: 20,
      cell: (data: any) => <p className="text-muted mb-0">{data.getValue()}</p>,
    }),
    columnHelper.accessor('number', {
      header: 'Número',
      size: 5,
      cell: (data: any) => <p className="text-muted mb-0">{data.getValue()}</p>,
      meta: {
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('complement', {
      header: 'Complemento',
      size: 10,
      cell: (data: any) => <p className="text-muted mb-0">{data.getValue()}</p>,
    }),
    columnHelper.accessor('actions', {
      header: 'Ação',
      enableSorting: false,
      size: 10,
      meta: {
        disableFilter: true,
      },
      cell: ({ row }: any) => (
        <TableActions
          isAdmin={isAdmin}
          editLink={`/clients/${clientID}/addresses/${row.original.id}`}
          itemID={row.original.id}
          onDeleteModal={onDeleteModal}
        />
      ),
    }),
  ];
};
