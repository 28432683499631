import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import PartnerForm from '@modules/Partners/components/PartnersForm';
import { NAME_MANDATORY } from '@modules/Client/constants';
import { create } from '@modules/Partners/services';
import { IPartnerCreate, IStep } from '@modules/Partners/types';
import { PERCENTAGE_MANDATORY } from '@modules/ProfitabilityGroup/constants';

const PartnerStep = ({ data, incrementData, incrementStep }: IStep<IPartnerCreate>) => {
  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IPartnerCreate) => create(payload),
    onSuccess: (data: IPartnerCreate) => {
      incrementStep();
      incrementData(data);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: data?.type ?? 'F',
      name: data?.name ?? '',
      profession: data?.profession ?? '',
      gender: data?.gender ?? '',
      percentage: data?.percentage || 0,
      active: data?.active ?? true,
      observation: data?.observation ?? '',
    },
    validationSchema: Yup.object({
      type: Yup.string(),
      name: Yup.string().required(NAME_MANDATORY),
      profession: Yup.string(),
      gender: Yup.string(),
      percentage: Yup.number().required(PERCENTAGE_MANDATORY),
      active: Yup.string().required(),
      observation: Yup.string(),
    }),
    onSubmit: async (payload: IPartnerCreate) => {
      if (!data?.id) {
        mutate(payload);
        return;
      }

      incrementStep();
    },
  });

  return (
    <Row>
      <Col sm={12}>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Dados do Acessor</CardTitle>
            <div className="p-2 mt-3">
              <PartnerForm
                loading={isPending}
                validation={validation}
                registrationError={error?.message}
                showAlert={isSuccess}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default PartnerStep;
