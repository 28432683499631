import { InstanceError } from '@apptypes/instance';
import { IPagination } from '@apptypes/pagination';
import { generateToQueryString } from '@helpers/queryString';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/instance';
import { IPartnerDocumentsCreate } from '@modules/Partners/types';
import { ColumnFiltersState } from '@tanstack/react-table';

export const getAll = async (
  partnerID: string,
  page = 1,
  size = 10,
  filterItems?: ColumnFiltersState,
): Promise<IPagination<IPartnerDocumentsCreate>> => {
  try {
    const queryString = generateToQueryString({
      page,
      size,
      filterItems,
      otherParams: partnerID ? `&partner_id=${partnerID}` : '',
    });
    const { data } = await instance.get(`/partner-documents${queryString}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Documentos não encontrados',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getByID = async (partnerDocumentID: string): Promise<IPartnerDocumentsCreate> => {
  try {
    const { data } = await instance.get(`/partner-documents/${partnerDocumentID}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Documento não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const create = async (
  partnerID: string,
  payload: IPartnerDocumentsCreate,
): Promise<IPartnerDocumentsCreate> => {
  try {
    const { data } = await instance.post(`/partner-documents?partner_id=${partnerID}`, {
      ...payload,
      partner_id: partnerID,
    });

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar o documento',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Documento não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const update = async (
  partnerDocumentID: string,
  payload: IPartnerDocumentsCreate,
): Promise<IPartnerDocumentsCreate> => {
  try {
    const { data } = await instance.put(`/partner-documents/${partnerDocumentID}`, payload);

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível atualizar o documento',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Documento não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const deleteByID = async (partnerDocumentID: string): Promise<void> => {
  try {
    await instance.delete(`/partner-documents/${partnerDocumentID}`);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Documento não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const documentsIntegrateRegister = async (
  partnerID: string,
  payload: any,
  isUpdate: boolean,
): Promise<any> => {
  try {
    const newPayload = payload.filter((item: any) => item.value);
    const requests = newPayload.map((item: any) => {
      if (isUpdate && item.id) {
        return update(item.id, item);
      }

      return create(partnerID, item);
    });

    return await Promise.all(requests);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar os documentos',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Documentos não encontrados',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
