import React from 'react';
import { Alert, Badge, Table } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';

import { moneyTransform } from '@helpers/transforms/money';
import { IContractCreate, IExtract } from '@modules/Contract/types';
import { IClientDocumentsCreate } from '@modules/Client/types';
import { paymentValues } from '@modules/Contract/submodules/Transactions/components/Table/constants';

const getTypeColor = {
  APORTE: 'dark',
  APORTE_LUCRO: 'primary',
  CA: 'primary',
  RETIRADA: 'dark',
  CR: 'danger',
  RETIRADA_LUCRO: 'danger',
  prejuizo: 'danger-2',
  L: 'success',
};

const getType = {
  APORTE: 'Aporte',
  RETIRADA: 'Solicitação da retirada',
  CA: 'Confirmação de aporte',
  CR: 'Confirmação da solicitação da retirada',
  L: 'Lucro',
  RETIRADA_LUCRO: 'Retirada do lucro',
  APORTE_LUCRO: 'Aporte do lucro',
  prejuizo: 'Prejuízo',
};

interface IExtractTable {
  extract: IExtract;
  initialDate: Date;
  finalDate: Date;
  contract: IContractCreate;
  clientDocument: IClientDocumentsCreate[];
}

const ExtractTable = ({
  extract,
  initialDate,
  finalDate,
  contract,
  clientDocument,
}: IExtractTable) => {
  if (Object.keys(extract).length === 0) return null;

  return (
    <>
      {!(extract?.items?.length > 0) ? (
        <Alert color="danger">
          Registro não encontrado para o período: de {moment(initialDate).format('DD/MM/YYYY')} até{' '}
          {moment(finalDate).format('DD/MM/YYYY')}
        </Alert>
      ) : (
        <Table className="table align-middle table-nowrap">
          <tbody>
            {contract && (
              <tr>
                <td colSpan={4} className="text-start">
                  <Badge
                    pill
                    color="primary"
                    className="font-size-14 extract__identification-number"
                  >
                    #{contract.identification_number}
                  </Badge>
                  <span className="font-size-14 fw-light ms-3">{contract.client?.name}</span>
                  {clientDocument.length > 0 && (
                    <>
                      <span className="font-size-14 ms-3">{clientDocument[0].type}</span>
                      <span className="font-size-14 ms-1">{clientDocument[0].value}</span>
                    </>
                  )}
                  <span className="font-size-14 ms-3">
                    {contract.profitability_group?.description}
                  </span>
                  <span className="font-size-14 ms-1">
                    {contract.profitability_group?.percentage.replace('.', ',')}%
                  </span>
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={2} className="text-start border-0">
                {initialDate && finalDate && (
                  <p className="font-size-14 fw-normal">
                    Período:{' '}
                    <span className="fw-medium">
                      {moment(initialDate).format('DD/MM/YYYY')} até{' '}
                      {moment(finalDate).format('DD/MM/YYYY')}
                    </span>
                  </p>
                )}
              </td>
              <td colSpan={2} className="text-end">
                <p className="font-size-14">
                  Saldo inicial do período:
                  <span className="text-success fw-bold ms-1">
                    {moneyTransform(extract.initial_value)}
                  </span>
                </p>
                <p className="font-size-14">
                  Saldo final do período:
                  <span className="text-success fw-bold ms-1">
                    {moneyTransform(extract.final_value)}
                  </span>
                </p>
              </td>
            </tr>
            {extract.items?.map((item, index: number) => (
              <tr key={index} className="border-bottom">
                <td
                  className={classnames('text-center border-end border-end-1 pt-0 pb-0', {
                    'border-top': index === 0,
                  })}
                >
                  <div>
                    <h5 className="fw-light font-size-14 mb-0">
                      {moment(item.date).format('DD/MM/YYYY')}
                    </h5>
                  </div>
                </td>
                <td
                  className={classnames('border-end border-end-1 pt-0 pb-0', {
                    'border-top': index === 0,
                  })}
                >
                  {item?.records?.map((record: any, i: number) => (
                    <div
                      className={classnames(
                        'row d-flex flex-row justify-content-between align-items-center pt-1 pb-1 border-top border-1',
                        {
                          'border-top-0': i === 0,
                        },
                      )}
                      key={i}
                    >
                      <div className="text-start col-3">
                        <h5
                          className={classnames('fw-medium font-size-14 mb-0', {
                            'text-body-tertiary': ['APORTE', 'RETIRADA'].includes(record.type),
                            'text-primary': record.type === 'CA' || record.type === 'APORTE_LUCRO',
                            'text-success': record.type === 'L' && record.value > 0,
                            'text-danger':
                              ['CR', 'RETIRADA_LUCRO'].includes(record.type),
                            'text-danger-2': record.value <= 0
                          })}
                        >
                          {['CR', 'RETIRADA', 'RETIRADA_LUCRO'].includes(record.type) ? '-' : ''}
                          {moneyTransform(record.value)}
                        </h5>
                      </div>
                      <div className="text-center col-6">
                        <p
                          className={`fw-normal font-size-12 text-${
                            getTypeColor[record.value > 0 ? record.type : 'prejuizo']
                          }`}
                        >
                          {record.value > 0 ? getType[record.type] : getType['prejuizo']}
                        </p>
                      </div>
                      <div className="text-center d-flex justify-content-center col-3">
                        {record.payment_type ? (
                          <p className={`fw-normal font-size-12 text-${getTypeColor[record.type]}`}>
                            {paymentValues[record.payment_type]}
                          </p>
                        ) : (
                          <p>-</p>
                        )}
                      </div>
                    </div>
                  ))}
                </td>
                <td className={classnames('pt-0 pb-0', { 'border-top': index === 0 })}>
                  <div className="text-end">
                    <h5 className="fw-bold font-size-14 text-success mb-0">
                      {moneyTransform(item.final_value)}
                    </h5>
                  </div>
                </td>
                <td
                  className={classnames('pt-0 pb-0 extracts__real-value-column', {
                    'border-top': index === 0,
                  })}
                >
                  <div className="text-end">
                    <h5 className="fw-bold font-size-14 text-muted mb-0">
                      {moneyTransform(item.real_value)}
                    </h5>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default ExtractTable;
