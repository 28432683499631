import React from 'react';
import ContractsUpdate from '@modules/Contract/pages/update';

import TransactionsCreate from '@modules/Contract/submodules/Transactions/pages/create';
import TransactionsUpdate from '@modules/Contract/submodules/Transactions/pages/update';

export const transactionRoutes = [
  {
    path: '/contracts/:contractID/contracts-transactions',
    component: <ContractsUpdate activePath="contracts-transactions" />,
  },
  {
    path: '/contracts/:contractID/contracts-transactions/create/:clientID',
    component: <TransactionsCreate activePath="contracts-transactions" />,
  },
  {
    path: '/contracts/:contractID/contracts-transactions/:transactionID/:clientID',
    component: <TransactionsUpdate activePath="contracts-transactions" />,
  },
];
