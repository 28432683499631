import { InstanceError } from '@apptypes/instance';
import { IUserCreate, IUser } from '@modules/Users/types';
import { UNEXPECTED_ERROR } from '@helpers/constants';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/instance';
import { INVALID_EMAIL, INVALID_PASSWORD } from '@modules/Users/constants';
import { IPagination } from '@apptypes/pagination';
import { ColumnFiltersState } from '@tanstack/react-table';
import { generateToQueryString } from '@helpers/queryString';

export const getByID = async (id: string): Promise<IUser> => {
  try {
    const { data } = await instance.get(`/users/${id}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Usuários não encontrado',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para visualizar o usuário',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getAll = async (
  page = 1,
  size = 10,
  filterItems?: ColumnFiltersState,
): Promise<IPagination<IUser>> => {
  try {
    const queryString = generateToQueryString({ page, size, filterItems });
    const { data } = await instance.get(`/users${queryString}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Usuários não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const create = async (payload: IUserCreate): Promise<IUser> => {
  try {
    const { data } = await instance.post('/sign-up', payload);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar o usuário',
      [ERROR_STATUS_MESSAGE.EMAIL_INVALID]: INVALID_EMAIL,
      [ERROR_STATUS_MESSAGE.PASSWORD_INVALID]: INVALID_PASSWORD,
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: UNEXPECTED_ERROR,
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const update = async (userID: string, payload: IUserCreate): Promise<IUser> => {
  try {
    const { data } = await instance.put(`/users/${userID}`, {
      name: payload.name,
      password: payload.password,
    });
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível editar o usuários',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Usuários não encontrado',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para editar o usuário',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const updateMetadata = async (userID: string, metadata: unknown): Promise<IUser> => {
  try {
    const { data } = await instance.put(`/users/${userID}/metadata`, {
      metadata,
    });
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível editar os metadados do usuário',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Usuários não encontrado',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]:
        'Você não tem permissão para editar os metadados do usuário',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const deleteByID = async (userID: string) => {
  try {
    const { data } = await instance.delete(`/users/${userID}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível deletar o usuários',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para deletar o usuário',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
