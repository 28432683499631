import React from 'react';
import CalendarList from '@modules/Calendar/pages';
import Calendar from '@modules/Calendar/pages/calendar';
import CalendarCreate from '@modules/Calendar/pages/create';
import CalendarUpdate from '@modules/Calendar/pages/update';

export const calendarRoutes = [
  { path: '/calendar', component: <Calendar /> },
  { path: '/calendar/list', component: <CalendarList /> },
  { path: '/calendar/create', component: <CalendarCreate /> },
  { path: '/calendar/:calendarID', component: <CalendarUpdate /> },
];
