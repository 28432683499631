import React from 'react';

import Clients from '@modules/Client/pages';
import ClientCreate from '@modules/Client/pages/create';
import ClientRegister from '@modules/Client/pages/register';
import ClientUpdate from '@modules/Client/pages/update';

import { clientAddressesRoutes } from '@modules/Client/submodules/Addresses/routes';
import { clientBankDatasRoutes } from '@modules/Client/submodules/BankDatas/routes';
import { clientDocumentsRoutes } from '@modules/Client/submodules/Documents/routes';
import { clientContactsRoutes } from '@modules/Client/submodules/Contacts/routes';
import { clientContractsRoutes } from '../submodules/Contracts/routes';

export const clientRoutes = [
  // Clients
  { path: '/clients', component: <Clients /> },
  { path: '/clients/create', component: <ClientCreate /> },
  { path: '/clients/register', component: <ClientRegister /> },
  { path: '/clients/:clientID', component: <ClientUpdate /> },

  // Addresses
  ...clientAddressesRoutes,

  // Bank Data
  ...clientBankDatasRoutes,

  // Documents
  ...clientDocumentsRoutes,

  // Contacts
  ...clientContactsRoutes,

  // Contracts
  ...clientContractsRoutes,
];
