import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import TableActions from '@component/common/TableActions';
import { Badge } from 'reactstrap';

export const buildColumns = (isAdmin: boolean, onDeleteModal: (data: string) => void) => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('description', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/calendar/${row.original.id}`} className="text-dark">
            {getValue()}
          </Link>
        </h5>
      ),
      header: 'Data',
      size: 10,
    }),
    columnHelper.accessor('calendar_date', {
      cell: ({ getValue }: any) => (
        <p className="text-muted mb-0">{moment(getValue()).format('DD/MM/YYYY')}</p>
      ),
      header: 'Data',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('recurrent', {
      cell: ({ getValue }: any) => (
        <>
          {getValue() ? (
            <Badge color="success" className="font-size-12" pill>
              Sim
            </Badge>
          ) : (
            <Badge color="danger" className="font-size-12" pill>
              Não
            </Badge>
          )}
        </>
      ),
      header: 'Data recorrente',
      size: 10,
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'true', label: 'Sim' },
          { value: 'false', label: 'Não' },
        ],
      },
    }),
    columnHelper.accessor('user.name', {
      cell: ({ getValue }: any) => <>{getValue()}</>,
      header: 'Nome',
      size: 15,
      meta: {
        isJoinColumn: true,
      },
    }),
    columnHelper.accessor('user.email', {
      cell: ({ getValue }: any) => <>{getValue()}</>,
      header: 'Email',
      size: 25,
      meta: {
        isJoinColumn: true,
      },
    }),
    columnHelper.accessor('created_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Data de criação',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('updated_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Última atualização',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }: any) => (
        <TableActions
          isAdmin={isAdmin}
          editLink={`/calendar/${row.original.id}`}
          itemID={row.original.id}
          onDeleteModal={onDeleteModal}
        />
      ),
      header: 'Ação',
      enableSorting: false,
      size: 10,
      meta: {
        disableFilter: true,
      },
    }),
  ];
};
