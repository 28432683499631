import { IProfitabilityGroupForm } from '@modules/ProfitabilityGroup/types';
import React from 'react';
import { Alert, Input, Label, Form, FormFeedback, FormGroup, Col, Row, FormText } from 'reactstrap';

export const ProfitabilityGroupForm = ({
  loading,
  registrationError,
  showAlert,
  type = 'create',
  validation,
}: IProfitabilityGroupForm) => {
  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {showAlert ? (
        <Alert color="success">{type === 'create' ? 'Cadastrado' : 'Editado'} com sucesso.</Alert>
      ) : null}

      {registrationError && registrationError ? (
        <Alert color="danger">{registrationError}</Alert>
      ) : null}

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Descrição</Label>
        <Col lg={10}>
          <Input
            id="description"
            name="description"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.description || ''}
            className="form-control"
            disabled={type === 'edit'}
            placeholder="Digite uma Descrição"
            type="text"
            invalid={!!(validation.touched.description && validation.errors.description)}
          />
          {validation.touched.description && validation.errors.description ? (
            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Porcentagem</Label>
        <Col lg={10}>
          <Input
            id="percentage"
            name="percentage"
            className="form-control"
            placeholder="Digite uma porcentagem"
            type="number"
            disabled={type === 'edit'}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.percentage || ''}
            invalid={!!(validation.touched.percentage && validation.errors.percentage)}
          />
          {validation.touched.percentage && validation.errors.percentage ? (
            <FormFeedback type="invalid">{validation.errors.percentage}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Número de dias úteis para o deposito</Label>
        <Col lg={10}>
          <Input
            id="deposit_business_days"
            name="deposit_business_days"
            className="form-control"
            placeholder="Digite quantos dias úteis para o deposito"
            type="number"
            disabled={type === 'edit'}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.deposit_business_days}
            invalid={
              !!(
                validation.touched.deposit_business_days && validation.errors.deposit_business_days
              )
            }
          />
          {validation.touched.deposit_business_days && validation.errors.deposit_business_days ? (
            <FormFeedback type="invalid">{validation.errors.deposit_business_days}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Dias para o processamento do saque</Label>
        <Col lg={10}>
          <Input
            id="withdrawal_execution_day"
            name="withdrawal_execution_day"
            className="form-control"
            placeholder="Dias para o processamento do saque"
            type="number"
            disabled={type === 'edit'}
            help="Dias para o processamento do saque"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.withdrawal_execution_day}
            invalid={
              !!(
                validation.touched.withdrawal_execution_day &&
                validation.errors.withdrawal_execution_day
              )
            }
          />
          <FormText>A partir da data calculada o saldo será modificado</FormText>

          {validation.touched.withdrawal_execution_day &&
          validation.errors.withdrawal_execution_day ? (
            <FormFeedback type="invalid">{validation.errors.withdrawal_execution_day}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Número de dias corridos para o saque</Label>
        <Col lg={10}>
          <Input
            id="withdrawal_calendar_days"
            name="withdrawal_calendar_days"
            className="form-control"
            placeholder="Digite quantos dias para o saque"
            type="number"
            disabled={type === 'edit'}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.withdrawal_calendar_days}
            invalid={
              !!(
                validation.touched.withdrawal_calendar_days &&
                validation.errors.withdrawal_calendar_days
              )
            }
          />
          {validation.touched.withdrawal_calendar_days &&
          validation.errors.withdrawal_calendar_days ? (
            <FormFeedback type="invalid">{validation.errors.withdrawal_calendar_days}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Tipo</Label>
        <Col lg={10}>
          <Input
            id="type"
            name="type"
            className="form-control"
            type="select"
            disabled={type === 'edit'}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.type || ''}
            invalid={!!(validation.touched.type && validation.errors.type)}
          >
            <option value="JS">Juros Simples</option>
            <option value="JC">Juros Compostos</option>
            <option value="JCS">Juros Compostos Semanal</option>
            <option value="JCQ">Juros Compostos Quinzenal</option>
            <option value="JCM">Juros Compostos Mensal</option>
          </Input>
          {validation.touched.type && validation.errors.type ? (
            <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Ativo</Label>
        <Col lg={10}>
          <Input
            id="active"
            name="active"
            className="form-control"
            type="select"
            disabled={type === 'edit'}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={String(validation.values.active) || ''}
            invalid={!!(validation.touched.active && validation.errors.active)}
          >
            <option value="">Selecione uma opção</option>
            <option value="true">Ativado</option>
            <option value="false">Desativado</option>
          </Input>
          {validation.touched.active && validation.errors.active ? (
            <FormFeedback type="invalid">{validation.errors.active}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      {type !== 'edit' && (
        <Row className="justify-content-end">
          <Col lg={10}>
            <button className="btn btn-primary btn-block" disabled={loading} type="submit">
              {loading ? (
                <>Salvando...</>
              ) : (
                `${type === 'create' ? 'Cadastrar' : 'Editar'} grupo de rentabilidade`
              )}
            </button>
          </Col>
        </Row>
      )}
    </Form>
  );
};
