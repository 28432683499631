import React from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Row, Col, CardBody, Card, CardTitle } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IUserCreate } from '@modules/Users/types';
import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@component/common/Page';
import Spinners from '@common/Spinner';
import withRouter from '@common/withRouter';
import { REDIRECT_TIMEOUT } from '@helpers/constants';
import { yupInvalidCPF, yupInvalidPassword } from '@helpers/validations';
import {
  CONFIRM_PASSWORD_REQUIRED,
  INVALID_PASSWORD,
  PASSWORD_MUST_EQUALS,
  TYPE_EMAIL,
  TYPE_USERNAME,
  TYPE_VALID_CPF,
  TYPE_VALID_EMAIL,
} from '@modules/Users/constants';
import { UserForm } from '@modules/Users/components';
import { getByID, update } from '@modules/Users/services';

const UserUpdate = ({ router }: IWithRouter) => {
  yupInvalidCPF();
  yupInvalidPassword(true);

  const { userID } = useParams();
  const { data, isLoading } = useQuery({
    queryKey: ['getUserByID', userID],
    queryFn: () => getByID(userID || ''),
    retry: 1,
  });

  const { error, isSuccess, mutate } = useMutation({
    mutationFn: (payload: IUserCreate) => {
      if (userID) {
        return update(userID, payload);
      }

      return Promise.reject();
    },
    onSuccess: () => {
      setTimeout(() => {
        router.navigate('/users');
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      cpf: data?.cpf ?? '',
      email: data?.email ?? '',
      name: data?.name ?? '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      cpf: (Yup.string() as any).isValidCPF(TYPE_VALID_CPF),
      email: Yup.string().email(TYPE_VALID_EMAIL).required(TYPE_EMAIL),
      name: Yup.string().required(TYPE_USERNAME),
      password: (Yup.string() as any).isValidPassword(INVALID_PASSWORD),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], PASSWORD_MUST_EQUALS)
        .when('password', (password) => {
          return password
            ? Yup.string()
                .oneOf([Yup.ref('password'), null], PASSWORD_MUST_EQUALS)
                .required(CONFIRM_PASSWORD_REQUIRED)
            : Yup.string();
        }),
    }),
    onSubmit: async (payload: IUserCreate) => mutate(payload),
  });

  return (
    <Page
      title="Editar Usuário"
      breadcrumb={{
        title: 'Usuários',
        titleHref: '/users',
        breadcrumbItem: 'Editar Usuário',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Editar Usuário</CardTitle>
              <div className="p-2 mt-3">
                {isLoading ? (
                  <Spinners />
                ) : (
                  <UserForm
                    loading={isLoading}
                    validation={validation}
                    registrationError={error?.message}
                    showAlert={isSuccess}
                    type="edit"
                  />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(UserUpdate);
