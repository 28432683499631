import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Card, CardBody, CardTitle, Col, Modal, ModalBody, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import Spinners from '@common/Spinner';
import withRouter from '@common/withRouter';
import { getAll as getAllDocuments } from '@modules/Client/submodules/Documents/services';
import { Details } from '@modules/Contract/components/Details';
import Tabs from '@modules/Contract/components/Tabs';
import { generateContract, getByID } from '@modules/Contract/services';
import ContractsInfo from '@modules/Contract/components/Info';
import Extracts from '@modules/Contract/submodules/Extract/pages';
import Transactions from '@modules/Contract/submodules/Transactions/pages';

import logoLight from '@assets/images/logo-light.png';
import ContractFiles from '../submodules/Files/pages';
import { toast } from 'react-toastify';

const ContractUpdate = ({
  activePath,
  router,
  page,
  size,
  searchParams,
  setSearchParams,
}: IWithRouter & { activePath: string }) => {
  const { contractID } = useParams();

  const { data, isFetching } = useQuery({
    queryKey: ['contract', contractID],
    queryFn: () => getByID(contractID || ''),
  });

  const { data: documents } = useQuery({
    queryKey: ['clientDocuments', data?.client_id, page, size],
    queryFn: () => getAllDocuments(data?.client_id || '', page, size),
    initialData: { items: [], totalItems: 0 },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: ({ amount, contractDate }: { amount?: string; contractDate?: string }) =>
      generateContract(contractID as string, amount, contractDate),
    onSuccess: () => {
      toast.success('Contrato gerado com sucesso!', {
        theme: 'colored',
        autoClose: 1500,
        onClose: () => {
          window.location.reload();
        },
      });
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: 2000,
        theme: 'colored',
      });
    },
  });

  const [activeTab, toggleTab] = useState<string>(activePath ?? 'contracts');

  useEffect(() => {
    if (contractID && activeTab) {
      const clientPath = searchParams.get('client_id')
        ? `?client_id=${searchParams.get('client_id')}`
        : '';
      const pathname = activeTab === 'contracts' ? '' : activeTab;
      const route = `/contracts/${contractID}/${pathname}${clientPath}`;

      if (route !== router.location.pathname) {
        router.navigate(`/contracts/${contractID}/${pathname}${clientPath}`);
      }
    }
  }, [activeTab, contractID, searchParams.get('client_id')]);

  useEffect(() => {
    toggleTab(!activePath ? 'contracts' : activePath);
  }, [activePath]);

  const getPageName: Record<string, string> = {
    'contracts-transactions': 'Transações',
    'contracts-extracts': 'Extrato',
    default: 'Editar contratos',
  };

  const handleClick = () => {
    if (data?.client_id) {
      router.navigate(`/clients/${data?.client_id}/contracts`);
    }
  };

  const handleGenerateContract = (amount?: string, contractDate?: string) => {
    mutate({ amount, contractDate });
  };

  const clientDocument = documents.items.filter((item: any) => ['CPF', 'CNPJ'].includes(item.type));

  return (
    <Page
      title="Contratos"
      breadcrumb={{
        title: searchParams.get('client_id') ? 'Contratos do cliente' : 'Contratos',
        titleHref: searchParams.get('client_id')
          ? `/clients/${searchParams.get('client_id')}/contracts`
          : '/contracts',
        breadcrumbItem: 'Editar Contrato',
      }}
    >
      {isPending && (
        <Modal isOpen={isPending} centered={true}>
          <div className="modal-content">
            <ModalBody className="px-4 py-5 text-center">
              <Spinners />
              <p className="text-muted font-size-16 mt-4">
                Aguarde o contrato está sendo reprocessado...
              </p>
            </ModalBody>
          </div>
        </Modal>
      )}
      <Row>
        <Col lg={12}>
          <Details
            identificationNumber={data?.identification_number ?? ''}
            clientName={data?.client?.name ?? ''}
            clientDocument={clientDocument}
            profitabilityGroupDescription={data?.profitability_group?.description}
            profitabilityGroupPercentage={data?.profitability_group?.percentage.replace('.', ',')}
            partnerName={data?.partner?.name}
            partnerID={data?.partner_id}
            contractID={contractID}
            recurringWithdrawal={data?.recurring_withdrawal}
            handleGenerateContract={handleGenerateContract}
            generateContractPending={isPending}
          />

          <Card>
            <Tabs handleClick={handleClick} activeTab={activeTab} toggleTab={toggleTab} />
            <CardBody>
              <CardTitle className="mb-4">
                <div className="logo-pdf">
                  <img src={logoLight} alt="logo" width="150" />
                </div>
                {getPageName[activePath || 'default']}
              </CardTitle>
              <div className="mt-3">
                {isFetching && activeTab === 'contracts' ? (
                  <Spinners />
                ) : (
                  <>
                    {activeTab === 'contracts' && (
                      <ContractsInfo
                        router={router}
                        contractID={contractID || ''}
                        contract={data}
                      />
                    )}
                    {activeTab === 'contracts-transactions' && (
                      <Transactions
                        router={router}
                        page={page}
                        size={size}
                        setSearchParams={setSearchParams}
                        contractID={contractID}
                        clientID={data?.client_id}
                      />
                    )}
                    {activeTab === 'contracts-files' && (
                      <ContractFiles
                        router={router}
                        page={page}
                        size={size}
                        setSearchParams={setSearchParams}
                        contractID={contractID}
                        clientID={data?.client_id}
                      />
                    )}
                    {activeTab === 'contracts-extracts' && (
                      <Extracts
                        contract={data}
                        contractID={contractID || ''}
                        clientDocument={clientDocument}
                      />
                    )}
                  </>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(ContractUpdate);
