interface SearchItems {
  label: string;
  url: string;
}

export const searchItems: SearchItems[] = [
  {
    label: 'Dashboard',
    url: '/dashboard',
  },
  {
    label: 'Transações pendentes',
    url: '/transactions',
  },
  {
    label: 'Retiradas pendentes',
    url: '/pending-withdrawals',
  },
  {
    label: 'Usuários',
    url: '/users',
  },
  {
    label: 'Cadastrar usuários',
    url: '/users/create',
  },
  {
    label: 'Clientes',
    url: '/clients',
  },
  {
    label: 'Cadastrar clientes',
    url: '/clients/create',
  },
  {
    label: 'Grupo de rentabilidade',
    url: '/profitability-groups',
  },
  {
    label: 'Cadastro de grupo de rentabilidade',
    url: '/profitability-groups/create',
  },
  {
    label: 'Contratos',
    url: '/contracts',
  },
  {
    label: 'Cadastrar de contratos',
    url: '/contracts/create',
  },
  {
    label: 'Calendário de datas',
    url: '/calendar',
  },
  {
    label: 'Listar datas',
    url: '/calendar/list',
  },
  {
    label: 'Cadastrar datas',
    url: '/calendar/create',
  },
];
