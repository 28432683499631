import React from 'react';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import withRouter from '@common/withRouter';
import Steps from '../components/Steps';
import PartnerStep from '../components/Steps/PartnerStep';
import {
  IPartnerAdressesCreate,
  IPartnerBankDataCreate,
  IPartnerContactsCreate,
  IPartnerCreate,
  IPartnerDocumentsCreate,
} from '../types';
import DocumentStep from '../components/Steps/DocumentStep';
import ContactStep from '../components/Steps/ContactStep';
import AddressStep from '../components/Steps/AddressStep';
import BankDataStep from '../components/Steps/BankDataStep';
import SuccessStep from '../components/Steps/SuccessStep';
import { useDispatch, useSelector } from 'react-redux';
import {
  finishStep,
  getPartnerRegisterState,
  resetData,
  setPartnerRegisterState,
  setStepData,
} from '@slices/partnerRegister/reducer';
import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap';

const PartnersRegister = ({ router }: IWithRouter) => {
  const {
    stateLoaded,
    currentStep,
    PARTNER: partner,
    CONTACTS,
    DOCUMENTS,
    ADDRESSES,
    BANK_DATAS,
  } = useSelector((state: any) => state.PartnerRegister);
  const dispatch: any = useDispatch();

  const partnerRegisterState = getPartnerRegisterState();

  return (
    <Page
      title="Cadastrar acessor"
      breadcrumb={{
        title: 'Acessores',
        titleHref: '/partners',
        breadcrumbItem: 'Cadastrar acessor',
      }}
    >
      {partnerRegisterState && !stateLoaded ? (
        <Modal isOpen={true} centered={true}>
          <div className="modal-content">
            <ModalHeader>Existe um cadastro em andamento, deseja continuar?</ModalHeader>
            <ModalFooter>
              <Button
                color="success"
                onClick={() => dispatch(setPartnerRegisterState(partnerRegisterState))}
              >
                Sim
              </Button>{' '}
              <Button color="secondary" onClick={() => dispatch(resetData())}>
                Não
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      ) : (
        <>
          <Steps />

          {currentStep === 1 && (
            <PartnerStep
              data={partner.data}
              incrementStep={() => dispatch(finishStep({ currentStep, step: 'PARTNER' }))}
              incrementData={(data: IPartnerCreate) =>
                dispatch(setStepData({ step: 'PARTNER', data }))
              }
            />
          )}

          {currentStep === 2 && (
            <DocumentStep
              data={DOCUMENTS?.data}
              incrementStep={() => dispatch(finishStep({ currentStep, step: 'DOCUMENTS' }))}
              incrementData={(data: IPartnerDocumentsCreate[]) =>
                dispatch(setStepData({ step: 'DOCUMENTS', data }))
              }
              partnerID={partner.data?.id || ''}
              partnerType={partner.data?.type || ''}
            />
          )}

          {currentStep === 3 && (
            <ContactStep
              data={CONTACTS?.data}
              incrementStep={() => dispatch(finishStep({ currentStep, step: 'CONTACTS' }))}
              incrementData={(data: IPartnerContactsCreate[]) =>
                dispatch(setStepData({ step: 'CONTACTS', data }))
              }
              partnerID={partner.data?.id || ''}
            />
          )}

          {currentStep === 4 && (
            <AddressStep
              data={ADDRESSES?.data}
              incrementStep={() => dispatch(finishStep({ currentStep, step: 'ADDRESSES' }))}
              incrementData={(data: IPartnerAdressesCreate[]) =>
                dispatch(setStepData({ step: 'ADDRESSES', data }))
              }
              partnerID={partner.data?.id || ''}
            />
          )}

          {currentStep === 5 && (
            <BankDataStep
              data={BANK_DATAS?.data}
              incrementStep={() => dispatch(finishStep({ currentStep, step: 'BANK_DATAS' }))}
              incrementData={(data: IPartnerBankDataCreate[]) =>
                dispatch(setStepData({ step: 'BANK_DATAS', data }))
              }
              partnerID={partner?.data?.id || ''}
            />
          )}

          {currentStep === 6 && <SuccessStep router={router} />}
        </>
      )}
    </Page>
  );
};

export default withRouter(PartnersRegister);
