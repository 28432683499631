import { moneyTransform } from '@helpers/transforms/money';
import React from 'react';
import { Badge, Card, CardBody } from 'reactstrap';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface CustodyWithdrawProps {
  value?: string;
  notProcessed?: string;
  processed?: string;
  isLoading: boolean;
}

export const CustodyWithdraw = ({
  value,
  processed,
  notProcessed,
  isLoading,
}: CustodyWithdrawProps) => (
  <Card>
    <CardBody style={{ minHeight: 171 }}>
      <div className="d-flex align-items-center mb-3">
        <div className="avatar-xs me-3">
          {isLoading ? (
            <Skeleton circle width="32px" height="32px" containerClassName="avatar-skeleton" />
          ) : (
            <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
              <i className="bx bx-money" />
            </span>
          )}
        </div>
        <h5 className="font-size-14 mb-0">{isLoading ? <Skeleton width={136} /> : 'Retiradas'}</h5>
      </div>
      <div className="text-muted mt-4">
        {isLoading ? (
          <Skeleton count={2} />
        ) : (
          <>
            <h4 className="mb-1">
              {moneyTransform(Number(value))}
              {Number(value) >= 0 ? (
                <i className="mdi mdi-chevron-up ms-1 text-success" />
              ) : (
                <i className="mdi mdi-chevron-down ms-1 text-danger" />
              )}
            </h4>
            <p className="text-danger mb-2">
              <Badge className="badge-soft-danger font-size-10 me-2">Não processado</Badge>
              {moneyTransform(Number(notProcessed))}
            </p>
            <div className="d-flex">
              <Badge className="badge-soft-success font-size-10 me-2">Total processado</Badge>
              {moneyTransform(Number(processed))}
            </div>
          </>
        )}
      </div>
    </CardBody>
  </Card>
);
