import { InstanceError } from '@apptypes/instance';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/instance';
import { ITransactionsFilesCreate } from '@modules/Contract/types';

import { IPagination } from '@apptypes/pagination';
import { ColumnFiltersState } from '@tanstack/react-table';
import { generateToQueryString } from '@helpers/queryString';
import { getCookie } from '@helpers/cookies';
import { AUTH_COOKIE_NAME } from '@helpers/constants';
import { CreateContractTransactionFile } from '@modules/Contract/submodules/TransactionsFiles/types';

export const getAll = async (
  contractTransactionID: string,
  page: number,
  size: number,
  filterItems?: ColumnFiltersState,
): Promise<IPagination<ITransactionsFilesCreate>> => {
  try {
    const queryString = generateToQueryString({
      page,
      size,
      filterItems,
      otherParams: contractTransactionID ? `&contract_transaction_id=${contractTransactionID}` : '',
    });
    const { data } = await instance.get(`/contracts-transactions-files${queryString}`);
    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'transações não encontradas',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const create = async (
  contractTransactionID: string,
  payload: CreateContractTransactionFile,
): Promise<CreateContractTransactionFile> => {
  try {
    const { data } = await instance.post('/contracts-transactions-files', {
      ...payload,
      contract_transaction_id: contractTransactionID,
    });

    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível realizar o upload do arquivo',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'transação não encontrada',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const deleteByID = async (transactionID: string) => {
  try {
    const { data } = await instance.delete(`/contracts-transactions-files/${transactionID}`);

    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível deletar a transação',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para deletar a transação',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const fileOpen = async (fileID: string, filename: string): Promise<any> => {
  try {
    const file = await fetch(
      `${process.env.BASE_URL}/contracts-transactions-files/${fileID}/download`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: getCookie(AUTH_COOKIE_NAME),
        } as any,
      },
    );

    const response = await file.arrayBuffer();
    const fileBlog = new Blob([response], { type: 'application/pdf' });

    return URL.createObjectURL(fileBlog);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível buscar o arquivo',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'arquivo não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const fileDownload = async (fileID: string, filename: string): Promise<any> => {
  fetch(`${process.env.BASE_URL}/contracts-transactions-files/${fileID}/download`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: getCookie(AUTH_COOKIE_NAME),
    } as any,
  })
    .then((resp) => resp.arrayBuffer())
    .then((resp) => {
      const file = new Blob([resp], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      const link = document.createElement('a');
      link.href = fileURL;
      link.download = filename;
      link.click();
    })
    .catch((error) => {
      const errorMessage: Record<number, string> = {
        [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível buscar o arquivo',
        [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'arquivo não encontrado',
        [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
      };

      throw new Error(
        errorMessage[(error as InstanceError).status] ??
          errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
      );
    });
};
