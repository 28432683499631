import React, { useState } from 'react';
import { Badge, Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import * as Yup from 'yup';

import { IPartnerContactsCreate, IStep } from '@modules/Partners/types';

import { CONTACT_TYPE_MANDATORY, VALUE_MANDATORY } from '@helpers/constants';
import { create } from '@modules/Partners/submodules/Contacts/services';
import { StepTable } from '../StepTable';
import { AddRegister } from '../AddRegister';
import PartnersContactForm from '@modules/Partners/submodules/Contacts/components/PartnersContactForm';

const ContactStep = ({
  data,
  partnerID,
  incrementData,
  incrementStep,
}: IStep<IPartnerContactsCreate[]>) => {
  const [enableAdd, setEnableAdd] = useState(false);

  const { mutate, error, isPending, isSuccess, reset } = useMutation({
    mutationFn: (payload: IPartnerContactsCreate) => create(partnerID || '', payload),
    onSuccess: (contact: IPartnerContactsCreate) => {
      setEnableAdd(false);
      validation.resetForm();
      reset();

      const newContacts = Array.isArray(data) ? data : [];
      incrementData([...newContacts, contact]);
    },
  });

  const newContacts: any[][] = [];
  for (const documents in data) {
    const { type, value, primary_contact: primaryContact } = data[documents];

    const primaryContactBadge = primaryContact ? (
      <Badge color="success" className="font-size-12" pill>
        Sim
      </Badge>
    ) : (
      <Badge color="danger" className="font-size-12" pill>
        Não
      </Badge>
    );

    newContacts.push([type, value, primaryContactBadge]);
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: '',
      value: '',
      primary_contact: false,
    },
    validationSchema: Yup.object({
      type: Yup.string().required(CONTACT_TYPE_MANDATORY),
      value: Yup.string().required(VALUE_MANDATORY),
      primery_contact: Yup.boolean(),
    }),
    onSubmit: async (payload: IPartnerContactsCreate) => mutate(payload),
  });

  const handleAdd = (e: React.FormEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setEnableAdd(true);
  };

  return (
    <Row>
      <Col sm={12}>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Contatos do acessor</CardTitle>
            <div className="p-2 mt-3">
              {data && (
                <StepTable
                  columns={['Tipo de contato', 'Valor', 'Contato Primário']}
                  rows={newContacts}
                />
              )}
              <AddRegister handleClick={handleAdd} label="contato" />
            </div>
          </CardBody>
        </Card>

        {enableAdd && (
          <Card>
            <CardBody>
              <CardTitle className="pb-4">Adicionar contato</CardTitle>

              <PartnersContactForm
                loading={isPending}
                validation={validation}
                registrationError={error?.message}
                showAlert={isSuccess}
              />
            </CardBody>
          </Card>
        )}

        {data && data?.length > 0 && (
          <div className="d-flex justify-content-end mb-4">
            <Button color="primary" onClick={() => incrementStep()}>
              Próxima etapa
              <i className="mdi mdi-chevron-right"></i>
            </Button>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default ContactStep;
