import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Offcanvas, OffcanvasBody } from 'reactstrap';
import SimpleBar from 'simplebar-react';

import { changeLayoutMode } from 'slices/thunk';

import { LAYOUT_MODE_TYPES } from '../constants/layout';
import { Link } from 'react-router-dom';

interface sidebar {
  show: boolean;
  toggleCanvas: any;
}

const RightSidebar = ({ toggleCanvas, show }: sidebar) => {
  const html = document.getElementsByTagName('html')[0];
  const dispatch = useDispatch<any>();
  const { layoutModeType } = useSelector((state: any) => state.Layout);

  return (
    <React.Fragment>
      <Offcanvas
        isOpen={show}
        toggle={toggleCanvas}
        direction={html.hasAttribute('dir') ? 'start' : 'end'}
        className="right-bar border-0"
      >
        <OffcanvasBody className="p-0">
          <SimpleBar style={{ height: '900px' }}>
            <div className="rightbar-title px-3 py-4">
              <Link className="right-bar-toggle float-end" to="/dashboard" onClick={toggleCanvas}>
                <i className="mdi mdi-close noti-icon"></i>
              </Link>
              <h5 className="m-0">Settings</h5>
            </div>
            <hr className="my-0" />
            <div className="p-4">
              <div className="radio-toolbar">
                <span className="mb-2 d-block">Selecione o tema</span>
                <input
                  type="radio"
                  id="radioLight"
                  name="radioLight"
                  value={LAYOUT_MODE_TYPES.LIGHT}
                  checked={layoutModeType === LAYOUT_MODE_TYPES.LIGHT}
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      localStorage.setItem('layoutMode', e.target.value);
                      dispatch(changeLayoutMode(e.target.value));
                    }
                  }}
                />
                <label className="me-1" htmlFor="radioLight">
                  Claro
                </label>
                <input
                  type="radio"
                  id="radioDark"
                  name="radioDark"
                  value={LAYOUT_MODE_TYPES.DARK}
                  checked={layoutModeType === LAYOUT_MODE_TYPES.DARK}
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      localStorage.setItem('layoutMode', e.target.value);
                      dispatch(changeLayoutMode(e.target.value));
                    }
                  }}
                />
                <label htmlFor="radioDark">Escuro</label>
              </div>
            </div>
          </SimpleBar>
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
};

export default RightSidebar;
