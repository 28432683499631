import React from 'react';

import ClientUpdate from '@modules/Client/pages/update';
import ClientDocumentsCreate from '@modules/Client/submodules/Documents/pages/create';
import ClientDocumentsUpdate from '@modules/Client/submodules/Documents/pages/update';

export const clientDocumentsRoutes = [
  { path: '/clients/:clientID/documents', component: <ClientUpdate activePath="documents" /> },
  {
    path: '/clients/:clientID/documents/create',
    component: <ClientDocumentsCreate activePath="documents" />,
  },
  {
    path: '/clients/:clientID/documents/:documentID',
    component: <ClientDocumentsUpdate activePath="documents" />,
  },
];
