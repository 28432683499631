import { updateMetadata } from '@slices/thunk';
import { Column, ColumnOrderState } from '@tanstack/react-table';
import { DragEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useDragAndDrop = (moduleName?: string) => {
  const { user }: any = useSelector((state: any) => state.Profile);
  const dispatch: any = useDispatch();

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [enableOrderColumn, setOrderColumn] = useState(false);
  const [columnBeingDragged, setColumnBeingDragged] = useState<number | undefined>(undefined);
  const [dragOver, setDragOver] = useState<number | undefined>(undefined);

  const onDragStart = (e: DragEvent<HTMLElement>): void => {
    setColumnBeingDragged(Number(e.currentTarget.dataset.columnIndex));
  };

  const onDragOver = (e: DragEvent<HTMLElement>): void => {
    e.preventDefault();

    const columnIndex = Number(e.currentTarget.dataset.columnIndex);
    if (columnIndex !== columnBeingDragged && columnBeingDragged !== undefined) {
      setDragOver(columnIndex);
    }
  };

  const onDragLeave = (e: DragEvent<HTMLElement>): void => {
    e.preventDefault();
    setDragOver(undefined);
  };

  const onDrop = (
    e: DragEvent<HTMLElement>,
    getVisibleLeafColumns: () => Array<Column<unknown, unknown>>,
  ): void => {
    e.preventDefault();

    if (columnBeingDragged === undefined) {
      return;
    }

    const newPosition = Number(e.currentTarget.dataset.columnIndex);
    const currentCols = getVisibleLeafColumns().map((c) => c.id);
    const colToBeMoved = currentCols.splice(columnBeingDragged, 1);

    currentCols.splice(newPosition, 0, colToBeMoved[0]);
    setColumnOrder(currentCols);
    setColumnBeingDragged(undefined);
    setDragOver(undefined);
  };

  const updateMetadatByUserID = async (columnOrderState?: ColumnOrderState) => {
    if (!moduleName || !columnOrderState) {
      return;
    }

    const metadata = user.metadata || {};
    const response = await updateMetadata(user.id, {
      ...metadata,
      [moduleName]: {
        ...(metadata[moduleName] || {}),
        columnOrder: columnOrderState,
      },
    });

    dispatch(response);
  };

  useEffect(() => {
    if (user?.id) {
      updateMetadatByUserID(columnOrder);
    }
  }, [columnOrder]);

  useEffect(() => {
    if (user?.id && user?.metadata && moduleName) {
      setColumnOrder(user?.metadata[moduleName]?.columnOrder);
    }
  }, [user?.id]);

  return {
    columnBeingDragged,
    columnOrder,
    dragOver,
    enableOrderColumn,
    onDragLeave,
    onDragOver,
    onDragStart,
    onDrop,
    setColumnOrder,
    setOrderColumn,
  };
};
