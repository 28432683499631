import React from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Row, Col, CardBody, Card, CardTitle } from 'reactstrap';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import withRouter from 'components/common/withRouter';

import { Page } from '@common/Page';
import Spinners from '@common/Spinner';
import { REDIRECT_TIMEOUT } from '@helpers/constants';
import CalendarForm from '@modules/Calendar/components/CalendarForm';
import { getByID, update } from '@modules/Calendar/services';
import { getByID as getUserByID } from '@modules/Users/services';
import { ICalendarCreate } from '@modules/Calendar/types';

const CalendarUpdate = ({ router }: any) => {
  const { calendarID } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ['calendar', calendarID],
    queryFn: () => getByID(calendarID || ''),
    retry: 1,
  });

  const { data: user, isFetching: userLoading } = useQuery({
    queryKey: ['user', data?.user_id],
    queryFn: () => getUserByID(data?.user_id || ''),
    retry: 1,
  });

  const { error, isSuccess, mutate } = useMutation({
    mutationFn: (payload: ICalendarCreate) => {
      const userID = user?.id;

      if (calendarID && userID) {
        return update(calendarID, userID, payload);
      }

      return Promise.reject();
    },
    onSuccess: () => {
      setTimeout(() => {
        router.navigate('/calendar/list');
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      calendarDate: moment(data?.calendar_date || new Date()).toISOString() ?? '',
      description: data?.description ?? '',
      recurrent: data?.recurrent ?? false,
    },
    validationSchema: Yup.object({
      calendarDate: Yup.string().required('Campo obrigatório'),
      description: Yup.string().required('Campo obrigatório'),
      recurrent: Yup.boolean().required('Campo obrigatório'),
    }),
    onSubmit: async (payload: ICalendarCreate) => mutate(payload),
  });

  return (
    <Page
      title="Editar Data"
      breadcrumb={{
        title: 'Calendário',
        titleHref: '/calendar',
        breadcrumbItem: 'Editar Data',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Editar Data</CardTitle>
              <div className="p-2 mt-3">
                {isLoading ? (
                  <Spinners />
                ) : (
                  <CalendarForm
                    loading={isLoading && userLoading}
                    validation={validation}
                    registrationError={error?.message}
                    showAlert={isSuccess}
                    user={user}
                    type="edit"
                  />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(CalendarUpdate);
