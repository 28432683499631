import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Row, Col, CardBody, Card, CardTitle } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import withRouter from '@common/withRouter';
import { REDIRECT_TIMEOUT } from '@helpers/constants';
import ClientForm from '@modules/Client/components/ClientForm';
import {
  CLIENT_TYPE_MANDATORY,
  CORPORATE_REASON_MAX_CHARACTERS,
  MATIRAL_STATUS_MAX_CHARACTERS,
  NAME_MANDATORY,
  NAME_MAX_CHARACTERS,
  NATIONALITY_MAX_CHARACTERS,
  OBSERVATION_MAX_CHARACTERS,
  PROFESSION_MAX_CHARACTERS,
} from '@modules/Client/constants';
import { create } from '@modules/Client/services';
import { IClientCreate } from '@modules/Client/types';

const ContractCreate = ({ router }: IWithRouter) => {
  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IClientCreate) => create(payload),
    onSuccess: () => {
      setTimeout(() => {
        router.navigate('/clients');
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: 'F',
      corporate_reason: '',
      document: '',
      state_registration: '',
      municipal_registration: '',
      gender: '',
      marital_status: '',
      name: '',
      nationality: '',
      service_type: '',
      observation: '',
      profession: '',
    },
    validationSchema: Yup.object({
      type: Yup.string().required(CLIENT_TYPE_MANDATORY),
      name: Yup.string().required(NAME_MANDATORY).max(200, NAME_MAX_CHARACTERS),
      corporate_reason: Yup.string()
        .ensure()
        .when('type', {
          is: 'J',
          then: Yup.string().max(200, CORPORATE_REASON_MAX_CHARACTERS).required(),
        }),
      marital_status: Yup.string()
        .ensure()
        .when('type', {
          is: 'F',
          then: Yup.string().max(80, MATIRAL_STATUS_MAX_CHARACTERS),
        }),
      profession: Yup.string()
        .ensure()
        .when('type', {
          is: 'F',
          then: Yup.string().max(100, PROFESSION_MAX_CHARACTERS),
        }),
      nationality: Yup.string()
        .ensure()
        .when('type', {
          is: 'F',
          then: Yup.string().max(80, NATIONALITY_MAX_CHARACTERS),
        }),
      gender: Yup.string().ensure().when('type', {
        is: 'F',
        then: Yup.string(),
      }),
      observation: Yup.string().max(250, OBSERVATION_MAX_CHARACTERS),
    }),
    onSubmit: async (payload: IClientCreate) => mutate(payload),
  });

  return (
    <Page
      title="Cadastrar cliente"
      breadcrumb={{
        title: 'Clientes',
        titleHref: '/clients',
        breadcrumbItem: 'Cadastrar cliente',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Criar Novo Cliente</CardTitle>
              <div className="p-2 mt-3">
                <ClientForm
                  loading={isPending}
                  validation={validation}
                  registrationError={error?.message}
                  showAlert={isSuccess}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(ContractCreate);
