import React from 'react';
import { Row, Col, CardBody, Card, CardTitle } from 'reactstrap';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IWithRouter } from '@apptypes/WithRouter';
import { IProfitabilityGroupCreate } from '@modules/ProfitabilityGroup/types';
import withRouter from '@common/withRouter';
import { Page } from '@component/common/Page';
import { REDIRECT_TIMEOUT } from '@helpers/constants';
import { ProfitabilityGroupForm } from '@modules/ProfitabilityGroup/components';
import {
  DEPOSIT_BUSINESS_DAYS_MANDATORY,
  DESCRIPTION_MANDATORY,
  PERCENTAGE_MANDATORY,
  PROFITABILITY_GROUP_TYPE_MANDATORY,
  WITHDRAWAL_CALENDAR_DAYS_MANDATORY,
  WITHDRAWAL_EXECUTION_DAY_MANDATORY,
} from '@modules/ProfitabilityGroup/constants';
import { create } from '@modules/ProfitabilityGroup/services';

const ProfitabilityGroupCreate = ({ router }: IWithRouter) => {
  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IProfitabilityGroupCreate) => create(payload),
    onSuccess: () => {
      setTimeout(() => {
        router.navigate('/profitability-groups');
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      description: '',
      percentage: 0,
      deposit_business_days: 0,
      withdrawal_execution_day: 0,
      withdrawal_calendar_days: 0,
      type: 'JS',
      active: false,
    },
    validationSchema: Yup.object({
      description: Yup.string().required(DESCRIPTION_MANDATORY),
      percentage: Yup.string().required(PERCENTAGE_MANDATORY),
      deposit_business_days: Yup.number().required(DEPOSIT_BUSINESS_DAYS_MANDATORY),
      withdrawal_execution_day: Yup.number().required(WITHDRAWAL_EXECUTION_DAY_MANDATORY),
      withdrawal_calendar_days: Yup.number().required(WITHDRAWAL_CALENDAR_DAYS_MANDATORY),
      type: Yup.string().required(PROFITABILITY_GROUP_TYPE_MANDATORY),
      active: Yup.string(),
    }),
    onSubmit: async (payload: IProfitabilityGroupCreate) => mutate(payload),
  });

  return (
    <Page
      title="Criar Grupo de rentabilidade"
      breadcrumb={{
        title: 'Grupos de Rentabilidade',
        titleHref: '/profitability-groups',
        breadcrumbItem: 'Novo Grupo de rentabilidade',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Criar Novo Grupo de rentabilidade</CardTitle>
              <div className="p-2 mt-3">
                <ProfitabilityGroupForm
                  loading={isPending}
                  validation={validation}
                  registrationError={error?.message}
                  showAlert={isSuccess}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(ProfitabilityGroupCreate);
