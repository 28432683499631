import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import TableContainer from '@common/TableContainer';
import { buildColumns } from '@modules/Partners/submodules/BankDatas/components/Table/constants';
import { getAll, deleteByID } from '@modules/Partners/submodules/BankDatas/services';
import { IRouter } from '@apptypes/WithRouter';
import { ColumnFiltersState } from '@tanstack/react-table';

interface IBankDataDocument {
  router: IRouter;
  partnerID?: string;
  page: number;
  size: number;
  setSearchParams: (params: any) => void;
}

const PartnersBankDatas = ({
  router,
  partnerID,
  page,
  size,
  setSearchParams,
}: IBankDataDocument) => {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const { user } = useSelector((state: any) => state.Profile);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['partnerBankDatas', partnerID, page, size, filters],
    queryFn: () => getAll(partnerID || '', page, size, filters),
    initialData: { items: [], totalItems: 0 },
    retry: 1,
  });

  const deleteMutation = useMutation({
    mutationFn: () => deleteByID(deleteBankDataPartnerID || ''),
    onSuccess: () => {
      toast.success('Dados bancário do acessor deletado com sucesso!', {
        theme: 'colored',
        autoClose: 1500,
      });

      refetch();
      setSearchParams({ page: '1' });
      setDeleteModal(false);
    },
  });

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteBankDataPartnerID, setDeleteBankDataPartnerID] = useState<string | undefined>();

  const handleDeletePartnerModal = (partnerID: string) => {
    setDeleteBankDataPartnerID(partnerID);
    setDeleteModal(true);
  };

  return (
    <div className="d-flex clients-content">
      <Helmet>
        <title>Dados bancários do acessor | SGI</title>
      </Helmet>
      <TableContainer
        moduleName="partnersBankDatas"
        columns={buildColumns(user.is_admin, partnerID || '', handleDeletePartnerModal)}
        customPageSize={size}
        currentPage={page}
        data={data.items}
        loading={isFetching}
        totalItems={data.totalItems}
        deleteModal={{
          isOpen: deleteModal,
          loading: isFetching,
          onDeleteClick: deleteMutation.mutate,
          onCloseClick: () => setDeleteModal(false),
        }}
        handleFilters={setFilters}
        setSearchParams={setSearchParams}
        refeatch={refetch}
        isAddRegister
        isDelete
        isPagination
        isShowingPageLength
        handleAddRegisterClick={() => router.navigate(`/partners/${partnerID}/bank-data/create`)}
      />
    </div>
  );
};

export default PartnersBankDatas;
