import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { Row, Col, CardBody, Card, CardTitle } from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { ICalendarCreate } from '@modules/Calendar/types';
import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@component/common/Page';
import withRouter from '@common/withRouter';
import { REDIRECT_TIMEOUT } from '@helpers/constants';
import CalendarForm from '@modules/Calendar/components/CalendarForm';
import { create } from '@modules/Calendar/services';

const CalendarCreate = ({ router, searchParams }: IWithRouter) => {
  const { user } = useSelector((state: any) => state.Profile);
  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: ICalendarCreate) => create(payload, user.id),
    onSuccess: () => {
      setTimeout(() => {
        router.navigate('/calendar/list');
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      calendarDate: '',
      description: '',
      recurrent: false,
    },
    validationSchema: Yup.object({
      calendarDate: Yup.string().required('Campo obrigatório'),
      description: Yup.string().required('Campo obrigatório'),
      recurrent: Yup.boolean().required('Campo obrigatório'),
    }),
    onSubmit: async (payload: ICalendarCreate) => mutate(payload),
  });

  useEffect(() => {
    if (searchParams.get('date')) {
      validation.setFieldValue('calendarDate', searchParams.get('date'));
    }
  }, [searchParams.get('date')]);

  return (
    <Page
      title="Cadastrar Data"
      breadcrumb={{
        title: 'Calendário',
        titleHref: '/calendar',
        breadcrumbItem: 'Cadastrar Data',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Cadastrar nova data</CardTitle>
              <div className="p-2 mt-3">
                <CalendarForm
                  validation={validation}
                  loading={isPending}
                  registrationError={error?.message}
                  showAlert={isSuccess}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(CalendarCreate);
