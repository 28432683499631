import React from 'react';

import PartnersUpdate from '@modules/Partners/pages/update';
import PartnersBankDatasCreate from '@modules/Partners/submodules/BankDatas/pages/create';
import PartnersBankDatasUpdate from '@modules/Partners/submodules/BankDatas/pages/update';

export const partnerBankDatasRoutes = [
  { path: '/partners/:partnerID/bank-data', component: <PartnersUpdate activePath="bank-data" /> },
  {
    path: '/partners/:partnerID/bank-data/create',
    component: <PartnersBankDatasCreate activePath="bank-data" />,
  },
  {
    path: '/partners/:partnerID/bank-data/:bankDataID',
    component: <PartnersBankDatasUpdate activePath="bank-data" />,
  },
];
