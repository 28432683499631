export * from './addresses';
export * from './bankData';
export * from './documents';
export * from './countries';

export const UNEXPECTED_ERROR = 'Erro inesperado';

export const AUTH_COOKIE_NAME = 'x-auth';
export const USER_ID_COOKIE_NAME = 'x-auth-user-id';
export const REDIRECT_TIMEOUT = 750;
