import { InstanceError } from '@apptypes/instance';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/instance';
import { IClientBankDataCreate } from '../../../types';
import { ColumnFiltersState } from '@tanstack/react-table';
import { generateToQueryString } from '@helpers/queryString';

export const getAll = async (
  clientID: string,
  page: number,
  size: number,
  filterItems?: ColumnFiltersState,
) => {
  try {
    const queryString = generateToQueryString({
      page,
      size,
      filterItems,
      otherParams: clientID ? `&client_id=${clientID}` : '',
    });
    const { data } = await instance.get(`/client-bank-data${queryString}`);
    return data;
  } catch (err) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Dados bancários não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(err as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getByID = async (clientBankDataID: string) => {
  try {
    const { data } = await instance.get(`/client-bank-data/${clientBankDataID}`);
    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Dados bancário não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const create = async (clientID: string, payload: IClientBankDataCreate) => {
  try {
    const { data } = await instance.post('/client-bank-data', {
      type: payload.bank_data_type,
      agency: payload.agency,
      account: payload.account,
      bank_number: payload.bank_number,
      institution: payload.institution,
      pix: payload.pix,
      client_id: clientID,
    });

    return data;
  } catch (err) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar os dados bancários',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Dados bancários não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(err as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const update = async (clientBankDataID: string, payload: IClientBankDataCreate) => {
  try {
    const { data } = await instance.put(`/client-bank-data/${clientBankDataID}`, payload);

    return data;
  } catch (err) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível atualizar os dados bancários',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Dados bancários não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(err as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const deleteByID = async (clientBankDataID: string) => {
  try {
    await instance.delete(`/client-bank-data/${clientBankDataID}`);
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Dados bancários não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
