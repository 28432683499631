import React from 'react';
import ProfitabilityGroup from '@modules/ProfitabilityGroup/pages';
import ProfitabilityGroupCreate from '@modules/ProfitabilityGroup/pages/create';
import ProfitabilityGroupUpdate from '@modules/ProfitabilityGroup/pages/update';

export const profitabilityGroupRoutes = [
  { path: '/profitability-groups', component: <ProfitabilityGroup /> },
  { path: '/profitability-groups/create', component: <ProfitabilityGroupCreate /> },
  { path: '/profitability-groups/:profitabilityGroupID', component: <ProfitabilityGroupUpdate /> },
];
