import { InstanceError } from '@apptypes/instance';
import { IPagination } from '@apptypes/pagination';
import { generateToQueryString } from '@helpers/queryString';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/instance';
import { ICalendar, ICalendarCreate } from '@modules/Calendar/types';
import { ColumnFiltersState } from '@tanstack/react-table';

export const getAll = async (
  page = 1,
  size = 10,
  filterItems?: ColumnFiltersState,
): Promise<IPagination<ICalendar>> => {
  try {
    const queryString = generateToQueryString({ page, size, filterItems });
    const { data } = await instance.get(`/calendar${queryString}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Datas não encontradas',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };
    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getByID = async (calendarID: string): Promise<ICalendar> => {
  try {
    const { data } = await instance.get(`/calendar/${calendarID}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Data não encontrado',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para visualizar a data',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const create = async (payload: ICalendarCreate, userID: string): Promise<ICalendar> => {
  try {
    const body = {
      calendar_date: payload.calendarDate,
      user_id: userID,
      description: payload.description,
      recurrent: payload.recurrent,
      type: 'BLOCK',
    };

    const { data } = await instance.post('/calendar', body);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar a data',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
      [ERROR_STATUS_MESSAGE.BLOCK_CURRENT_MONTH_YEAR]: 'Não é possível bloquear datas no mês atual',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const update = async (
  calendarID: string,
  userID: string,
  payload: ICalendarCreate,
): Promise<ICalendar> => {
  try {
    const body = {
      calendar_date: payload.calendarDate.substring(0, 10),
      user_id: userID,
      description: payload.description,
      recurrent: payload.recurrent,
      type: 'BLOCK',
    };

    const { data } = await instance.put(`/calendar/${calendarID}`, body);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível editar a data',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Data não encontrado',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para editar a data',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const deleteByID = async (calendarID: string) => {
  try {
    const { data } = await instance.delete(`/calendar/${calendarID}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível deletar o grupo de rentabilidade',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]:
        'Você não tem permissão para deletar o grupo de rentabilidade',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
