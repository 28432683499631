import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import moment from 'moment';

import TableActions from '@common/TableActions';

export const buildColumns = (isAdmin: boolean, onDeleteModal: (data: string) => void) => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('code', {
      cell: ({ row, getValue }: any) => (
        <Badge color="primary" className="font-size-12" pill>
          <Link to={`/clients/${row.original.id}`} className="text-light">
            #{getValue()}
          </Link>
        </Badge>
      ),
      header: 'ID do cliente',
      size: 7,
      meta: {
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('type', {
      cell: ({ row, getValue }: any) => (
        <Badge color="primary" className="font-size-12" pill>
          <Link to={`/clients/${row.original.id}`} className="text-light">
            {getValue() === 'J' ? 'Jurídica' : 'Física'}
          </Link>
        </Badge>
      ),
      header: 'Tipo de pessoa',
      size: 15,
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'J', label: 'Jurídica' },
          { value: 'F', label: 'Física' },
        ],
      },
    }),
    columnHelper.accessor('service_type', {
      cell: ({ row, getValue }: any) => (
        <>
          {getValue() && getValue() !== 'NENHUM' && (
            <Badge color="primary" className="font-size-12" pill>
              <Link to={`/clients/${row.original.id}`} className="text-light">
                {getValue() === 'BALCAO' && 'Balcão'}
                {getValue() === 'IN_HOME' && 'In Home'}
              </Link>
            </Badge>
          )}
        </>
      ),
      header: 'Tipo de atendimento',
      size: 15,
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'BALCAO', label: 'Balcão' },
          { value: 'IN_HOME', label: 'In Home' },
        ],
      },
    }),
    columnHelper.accessor('name', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/clients/${row.original.id}`} className="text-dark">
            {getValue()}
          </Link>
        </h5>
      ),
      header: 'Nome',
      size: 60,
    }),
    columnHelper.accessor('created_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Data de criação',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('updated_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Última atualização',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('contracts', {
      cell: ({ row }: any) => (
        <div className="d-flex gap-3">
          <Link
            to={`/clients/${row.original.id}/contracts`}
            className="btn btn-outline-primary btn-sm"
          >
            <i className="mdi mdi-eye font-size-14" id="edittooltip" /> Contratos
          </Link>
        </div>
      ),
      header: 'Contratos',
      size: 5,
      meta: {
        disableFilter: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }: any) => (
        <TableActions
          isAdmin={isAdmin}
          editLink={`/clients/${row.original.id}/`}
          itemID={row.original.id}
          onDeleteModal={onDeleteModal}
        />
      ),
      header: 'Ação',
      enableSorting: false,
      size: 10,
      meta: {
        disableFilter: true,
      },
    }),
  ];
};
