import React from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Row, Col, CardBody, Card, CardTitle } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import withRouter from '@common/withRouter';
import { REDIRECT_TIMEOUT } from '@helpers/constants';
import ContractForm from '@modules/Contract/components/ContractForm';
import { CLIENT_MANDATORY, PROFITABILITY_GROUP_MANDATORY } from '@modules/Contract/constants';
import { create } from '@modules/Contract/services';
import { IContractCreate, IContractCreateForm } from '@modules/Contract/types';
import { getByID as getClientByID } from '@modules/Client/services';

const ContractCreate = ({ router, searchParams }: IWithRouter) => {
  const { data: client } = useQuery({
    queryKey: ['client', searchParams.get('client_id')],
    queryFn: () => getClientByID(searchParams.get('client_id') || ''),
  });

  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IContractCreate) => create(payload),
    onSuccess: () => {
      setTimeout(() => {
        const path = searchParams.get('client_id')
          ? `/clients/${searchParams.get('client_id')}/contracts`
          : '/contracts';

        router.navigate(path);
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      client: {
        value: client?.id ?? '',
        label: client?.name ?? '',
      },
      profitabilityGroup: {
        value: '',
        label: '',
      },
      active: '',
      recurring_withdrawal: '',
      description: '',
    },
    validationSchema: Yup.object({
      client: Yup.object().required(CLIENT_MANDATORY),
      profitabilityGroup: Yup.object().required(PROFITABILITY_GROUP_MANDATORY),
      description: Yup.string(),
    }),
    onSubmit: async (payload: IContractCreateForm) => {
      mutate({
        client_id: payload.client.value,
        profitability_group_id: payload.profitabilityGroup.value,
        active: payload.active === 'true',
        recurring_withdrawal: payload.recurring_withdrawal === 'true',
        description: payload.description,
      });
    },
  });

  return (
    <Page
      title="Contratos"
      breadcrumb={{
        title: searchParams.get('client_id') ? 'Contratos do cliente' : 'Contratos',
        titleHref: searchParams.get('client_id')
          ? `/clients/${searchParams.get('client_id')}/contracts`
          : '/contracts',
        breadcrumbItem: 'Novo Contrato',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Criar Novo Contrato</CardTitle>
              <div className="p-2 mt-3">
                <ContractForm
                  isLoading={isPending}
                  validation={validation}
                  registrationError={error?.message}
                  showAlert={isSuccess}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(ContractCreate);
