import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import ClientForm from '@modules/Client/components/ClientForm';
import {
  CLIENT_TYPE_MANDATORY,
  CORPORATE_REASON_MAX_CHARACTERS,
  MATIRAL_STATUS_MAX_CHARACTERS,
  NAME_MANDATORY,
  NAME_MAX_CHARACTERS,
  NATIONALITY_MAX_CHARACTERS,
  OBSERVATION_MAX_CHARACTERS,
  PROFESSION_MAX_CHARACTERS,
} from '@modules/Client/constants';
import { create } from '@modules/Client/services';
import { IClientCreate, IStep } from '@modules/Client/types';

const ClientStep = ({ data, incrementData, incrementStep }: IStep<IClientCreate>) => {
  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IClientCreate) => create(payload),
    onSuccess: (data: IClientCreate) => {
      incrementStep();
      incrementData(data);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: data?.type ?? 'F',
      corporate_reason: data?.corporate_reason ?? '',
      document: data?.document ?? '',
      state_registration: data?.state_registration ?? '',
      municipal_registration: data?.marital_status ?? '',
      gender: data?.gender ?? '',
      marital_status: data?.marital_status ?? '',
      name: data?.name ?? '',
      nationality: data?.nationality ?? '',
      observation: data?.observation ?? '',
      service_type: data?.service_type ?? '',
      profession: data?.profession ?? '',
    },
    validationSchema: Yup.object({
      type: Yup.string().required(CLIENT_TYPE_MANDATORY),
      name: Yup.string().required(NAME_MANDATORY).max(200, NAME_MAX_CHARACTERS),
      corporate_reason: Yup.string()
        .ensure()
        .when('type', {
          is: 'J',
          then: Yup.string().max(200, CORPORATE_REASON_MAX_CHARACTERS).required(),
        }),
      marital_status: Yup.string()
        .ensure()
        .when('type', {
          is: 'F',
          then: Yup.string().max(80, MATIRAL_STATUS_MAX_CHARACTERS),
        }),
      profession: Yup.string()
        .ensure()
        .when('type', {
          is: 'F',
          then: Yup.string().max(100, PROFESSION_MAX_CHARACTERS),
        }),
      nationality: Yup.string()
        .ensure()
        .when('type', {
          is: 'F',
          then: Yup.string().max(80, NATIONALITY_MAX_CHARACTERS),
        }),
      gender: Yup.string().ensure().when('type', {
        is: 'F',
        then: Yup.string(),
      }),
      observation: Yup.string().max(250, OBSERVATION_MAX_CHARACTERS),
    }),
    onSubmit: async (payload: IClientCreate) => {
      if (!data?.id) {
        mutate(payload);
        return;
      }

      incrementStep();
    },
  });

  return (
    <Row>
      <Col sm={12}>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Dados do Cliente</CardTitle>
            <div className="p-2 mt-3">
              <ClientForm
                loading={isPending}
                validation={validation}
                registrationError={error?.message}
                showAlert={isSuccess}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ClientStep;
