import { InstanceError } from '@apptypes/instance';
import { IPagination } from '@apptypes/pagination';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/instance';
import { ColumnFiltersState } from '@tanstack/react-table';
import { generateToQueryString } from '@helpers/queryString';

export const getAll = async (
  partnerID: string,
  page: number,
  size: number,
  filterItems?: ColumnFiltersState,
): Promise<IPagination<any>> => {
  try {
    const queryString = generateToQueryString({
      page,
      size,
      filterItems,
      otherParams: partnerID ? `&partner_id=${partnerID}` : '',
    });
    const { data } = await instance.get(`/partner-payment${queryString}`);

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível buscar os pagamentos do acessor',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'arquivos não encontrados',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
