import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import TableContainer from '@common/TableContainer';
import { buildColumns } from '@modules/Client/submodules/BankDatas/components/Table/constants';
import { getAll, deleteByID } from '@modules/Client/submodules/BankDatas/services';
import { IRouter } from '@apptypes/WithRouter';
import { ColumnFiltersState } from '@tanstack/react-table';

interface IBankDataDocument {
  router: IRouter;
  clientID?: string;
  page: number;
  size: number;
  setSearchParams: (params: any) => void;
}

const ClientBankDatas = ({ router, clientID, page, size, setSearchParams }: IBankDataDocument) => {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const { user } = useSelector((state: any) => state.Profile);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['clientBankDatas', clientID, page, size, filters],
    queryFn: () => getAll(clientID || '', page, size, filters),
    initialData: { items: [], totalItems: 0 },
    retry: 1,
  });

  const deleteMutation = useMutation({
    mutationFn: () => deleteByID(deleteBankDataClientID || ''),
    onSuccess: () => {
      toast.success('Dados bancário do cliente deletado com sucesso!', {
        theme: 'colored',
        autoClose: 1500,
      });

      refetch();
      setSearchParams({ page: '1' });
      setDeleteModal(false);
    },
  });

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteBankDataClientID, setDeleteBankDataClientID] = useState<string | undefined>();

  const handleDeleteClientModal = (clientID: string) => {
    setDeleteBankDataClientID(clientID);
    setDeleteModal(true);
  };

  return (
    <div className="d-flex clients-content">
      <Helmet>
        <title>Dados bancários do cliente | SGI</title>
      </Helmet>
      <TableContainer
        moduleName="clientBankDatas"
        columns={buildColumns(user.is_admin, clientID || '', handleDeleteClientModal)}
        customPageSize={size}
        currentPage={page}
        data={data.items}
        loading={isFetching}
        totalItems={data.totalItems}
        deleteModal={{
          isOpen: deleteModal,
          loading: isFetching,
          onDeleteClick: deleteMutation.mutate,
          onCloseClick: () => setDeleteModal(false),
        }}
        handleFilters={setFilters}
        setSearchParams={setSearchParams}
        refeatch={refetch}
        isAddRegister
        isDelete
        isPagination
        isShowingPageLength
        handleAddRegisterClick={() => router.navigate(`/clients/${clientID}/bank-data/create`)}
      />
    </div>
  );
};

export default ClientBankDatas;
