import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Form, Row, Input, Label, FormFeedback, Alert } from 'reactstrap';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import CarouselPage from './CarouselPage';

import { loginUser, resetLoginMsgFlag } from '@slices/auth/login/thunk';

const Login = () => {
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const dispatch: any = useDispatch();

  //meta title
  document.title = 'Login | SGI';

  const { error, loading } = useSelector((state: any) => state.Login);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Campo Usuário é obrigatório!'),
      password: Yup.string().required('Campo Senha é obrigatório!'),
    }),
    onSubmit: async (values: any) => {
      const response = await loginUser(values);
      dispatch(response);
    },
  });

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(resetLoginMsgFlag());
      }, 3000);
    }
  }, [dispatch, error]);

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <div className="sgi-logo">
                          <h1 className="sgi-logo__title">SGI</h1>
                          <h2 className="sgi-logo__subtitle">Sistema de Gestão Interno</h2>
                        </div>
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Bem-vindo!</h5>
                        <p className="text-muted">Preenche o formulário para acesso</p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          {error ? <Alert color="danger">{error}</Alert> : null}

                          <div className="mb-3">
                            <Label className="form-label">Usuário</Label>
                            <Input
                              name="username"
                              className="form-control"
                              placeholder="Digite o usuário"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ''}
                              invalid={
                                !!(validation.touched.username && validation.errors.username)
                              }
                            />
                            {validation.touched.username && validation.errors.username ? (
                              <FormFeedback type="invalid">
                                {validation.errors.username}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Senha</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                value={validation.values.password || ''}
                                type={passwordShow ? 'text' : 'password'}
                                placeholder="Digite a senha"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  !!(validation.touched.password && validation.errors.password)
                                }
                              />
                              <button
                                onClick={() => setPasswordShow(!passwordShow)}
                                className="btn btn-light "
                                type="button"
                                id="password-addon"
                              >
                                <i className="mdi mdi-eye-outline"></i>
                              </button>
                            </div>
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="justify-content-center btn btn-primary btn-block"
                              disabled={loading}
                              type="submit"
                            >
                              {loading ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                <>Entrar</>
                              )}
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p>© {new Date().getFullYear()} SGI.</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
