export const ERROR_STATUS_MESSAGE: Record<string, string> = {
  BAD_REQUEST: 'BAD_REQUEST',
  METHOD_NOT_ALLOWED: 'METHOD_NOT_ALLOWED',
  NOT_FOUND: 'NOT_FOUND',
  UNAUTHORIZED: 'UNAUTHORIZED',
  CREDENTIALS_INVALID: 'CREDENTIALS_INVALID',
  INTERNAL_SERVER: 'INTERNAL_SERVER',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  VALUE_CANNOT_BE_NEGATIVE_OR_ZERO: 'VALUE_CANNOT_BE_NEGATIVE_OR_ZERO',
  TOTAL_VALUE_CANNOT_BE_NEGATIVE: 'TOTAL_VALUE_CANNOT_BE_NEGATIVE',
  TRANSACTION_ALREADY_APPROVED: 'TRANSACTION_ALREADY_APPROVED',
  EMAIL_INVALID: 'EMAIL_INVALID',
  PASSWORD_INVALID: 'PASSWORD_INVALID',
  BLOCK_CURRENT_MONTH_YEAR: 'BLOCK_CURRENT_MONTH_YEAR',
  CANNOT_UPDATE_CONTRACT_TRANSACTION: 'CANNOT_UPDATE_CONTRACT_TRANSACTION',
  TRANSACTION_DATE_GREATER_THAN_EFFECTIVE_DATE: 'TRANSACTION_DATE_GREATER_THAN_EFFECTIVE_DATE',
  PRIMARY_CONTACT_ALREADY_EXISTS: 'PRIMARY_CONTACT_ALREADY_EXISTS',

  CLIENT_DOES_NOT_HAVE_CONTACTS: 'CLIENT_DOES_NOT_HAVE_CONTACTS',
  CLIENT_DOES_NOT_HAVE_ADDRESSES: 'CLIENT_DOES_NOT_HAVE_ADDRESSES',
  CLIENT_DOES_NOT_HAVE_DOCUMENTS: 'CLIENT_DOES_NOT_HAVE_DOCUMENTS',
  CLIENT_DOES_NOT_HAVE_EMAIL_OR_PHONE: 'CLIENT_DOES_NOT_HAVE_EMAIL_OR_PHONE',
  CLIENT_DOES_NOT_HAVE_CPF_OR_RG: 'CLIENT_DOES_NOT_HAVE_CPF_OR_RG',
  CLIENT_DOES_NOT_HAVE_CNPJ_OR_IE_OR_IM: 'CLIENT_DOES_NOT_HAVE_CNPJ_OR_IE_OR_IM',
};

export const ERROR_STATUS_CODE: Record<string, number> = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  INTERNAL_SERVER: 500,
};
