import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Row, Col, CardBody, Card, CardTitle } from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { IUserCreate } from '@modules/Users/types';
import { IWithRouter } from '@apptypes/WithRouter';
import withRouter from '@common/withRouter';
import { Page } from '@component/common/Page';
import { yupInvalidCPF, yupInvalidPassword } from '@helpers/validations';
import { REDIRECT_TIMEOUT } from '@helpers/constants';
import {
  CONFIRM_PASSWORD_REQUIRED,
  INVALID_PASSWORD,
  PASSWORD_MUST_EQUALS,
  TYPE_EMAIL,
  TYPE_PASSWORD,
  TYPE_USERNAME,
  TYPE_VALID_CPF,
  TYPE_VALID_EMAIL,
} from '@modules/Users/constants';
import { create } from '@modules/Users/services';
import { UserForm } from '@modules/Users/components';

const UserCreate = ({ router }: IWithRouter) => {
  yupInvalidCPF();
  yupInvalidPassword();

  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IUserCreate) => create(payload),
    onSuccess: () => {
      setTimeout(() => {
        router.navigate('/users');
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      cpf: '',
      email: '',
      name: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      cpf: (Yup.string() as any).isValidCPF(TYPE_VALID_CPF),
      email: Yup.string().email(TYPE_VALID_EMAIL).required(TYPE_EMAIL),
      name: Yup.string().required(TYPE_USERNAME),
      password: (Yup.string() as any).isValidPassword(INVALID_PASSWORD).required(TYPE_PASSWORD),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], PASSWORD_MUST_EQUALS)
        .required(CONFIRM_PASSWORD_REQUIRED),
    }),
    onSubmit: (payload: IUserCreate) => mutate(payload),
  });

  return (
    <Page
      title="Criar Usuário"
      breadcrumb={{
        title: 'Usuários',
        titleHref: '/users',
        breadcrumbItem: 'Novo Usuário',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Criar Novo Usuário</CardTitle>
              <div className="p-2 mt-3">
                <UserForm
                  validation={validation}
                  loading={isPending}
                  registrationError={error?.message}
                  showAlert={isSuccess}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(UserCreate);
