import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import TableContainer from 'components/common/TableContainer';
import { buildColumns } from '@modules/Partners/submodules/Clients/components/Table/constants';
import { IRouter } from '@apptypes/WithRouter';
import { getClientsByPartnerID } from '@modules/Partners/services';

interface IPartnerClients {
  router: IRouter;
  partnerID?: string;
  page: number;
  size: number;
  setSearchParams: (params: any) => void;
}

const PartnerClients = ({ partnerID, page, size, setSearchParams }: IPartnerClients) => {
  const { user } = useSelector((state: any) => state.Profile);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['partnerClients', partnerID],
    queryFn: () => getClientsByPartnerID(partnerID || ''),
    initialData: [],
    retry: 1,
  });

  return (
    <div className="d-flex clients-content">
      <Helmet>
        <title>Contratos do acessor | SGI</title>
      </Helmet>
      <TableContainer
        moduleName="partnersClients"
        columns={buildColumns(user.is_admin, partnerID || '')}
        customPageSize={size}
        currentPage={page}
        data={data}
        loading={isFetching}
        totalItems={data?.length || 0}
        setSearchParams={setSearchParams}
        refeatch={refetch}
        isPagination
        isShowingPageLength
      />
    </div>
  );
};

export default PartnerClients;
