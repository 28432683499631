import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@component/common/Page';
import withRouter from '@component/common/withRouter';
import LinkPartnerForm from '@modules/Contract/submodules/Partner/components/LinkPartnerForm';
import { IContractCreate } from '@modules/Contract/types';
import { getByID } from '@modules/Contract/services';
import Spinners from '@component/common/Spinner';
import { Detail } from '@modules/Contract/submodules/Partner/components/Detail';
import moment from 'moment';
import Tabs from '@modules/Partners/components/Tabs';

const DetailLinkPartner = ({ router }: IWithRouter & IContractCreate) => {
  const { contractID, partnerID } = useParams();

  const { data, isFetching, error } = useQuery({
    queryKey: ['contract', contractID],
    queryFn: () => getByID(contractID || ''),
    retry: 1,
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      partner: {
        value: data?.partner_id,
        label: data?.partner?.name,
      },
      expirationDate:
        moment(data?.expiration_partner_at).format('YYYY-MM-DD') ||
        moment().add(6, 'months').format('YYYY-MM-DD'),
      linkedAt: moment(data?.linked_partner_at).format('YYYY-MM-DD') || '',
      recurringWithdrawal: data?.recurring_withdrawal ? 'true' : 'false',
      partnerPercentage: Number(data?.partner_percentage || 0),
    },
    validationSchema: Yup.object({
      partner: Yup.object().required(),
      expirationDate: Yup.string().required(),
    }),
    onSubmit: () => {},
  });

  const toogleTab = (activeTab: string) => {
    router.navigate(`/partners/${partnerID}/${activeTab}`);
  };

  return (
    <Page
      title="Vincular acessor ao contrato"
      breadcrumb={{
        title: 'Contratos',
        titleHref: `/contracts`,
        breadcrumbItem: 'Vincular acessor ao contato',
      }}
    >
      <Row>
        <Col lg={12}>
          {isFetching ? (
            <Spinners />
          ) : (
            <>
              <Detail
                identificationNumber={data?.identification_number || ''}
                profitabilityGroupDescription={data?.profitability_group?.description || ''}
                profitabilityGroupPercentage={data?.profitability_group?.percentage || ''}
                contractID={contractID || ''}
              />
              <Card>
                <Tabs activeTab="contracts" toggleTab={toogleTab} />
                <CardBody>
                  <CardTitle className="mb-4">Vincular acessor ao contrato</CardTitle>
                  <div className="p-2 mt-3">
                    <LinkPartnerForm
                      loading={isFetching}
                      validation={validation}
                      registrationError={error?.message}
                      showAlert={false}
                      type="edit"
                    />
                  </div>
                </CardBody>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(DetailLinkPartner);
