import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import TableContainer from '@common/TableContainer';
import withRouter from '@common/withRouter';
import { buildColumns } from '@modules/Client/components/Table/constants';
import { deleteByID, exportData, getAll } from '@modules/Client/services';
import { ColumnFiltersState } from '@tanstack/react-table';

const Clients = ({ router, page, size, setSearchParams }: IWithRouter) => {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteClientID, setDeleteClientID] = useState<string | undefined>();

  const { user }: any = useSelector((state: any) => state.Profile);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['clients', page, size, filters],
    queryFn: () => getAll(page, size, filters),
    initialData: { items: [], totalItems: 0 },
    retry: 1,
  });

  const deleteMutation = useMutation({
    mutationFn: () => deleteByID(deleteClientID || ''),
    onSuccess: () => {
      toast.success('Cliente deletado com sucesso!', {
        theme: 'colored',
        autoClose: 1500,
      });
      refetch();
      setSearchParams({ page: '1' });
      setDeleteModal(false);
    },
  });

  const handleDeleteClientModal = (clientID: string) => {
    setDeleteClientID(clientID);
    setDeleteModal(true);
  };

  const handleExportData = async () => {
    const fileUrl = await exportData(page, size, filters);

    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = `clients-export.csv`;
    link.click();
  };

  return (
    <Page
      title="Clientes"
      breadcrumb={{
        title: 'Clientes',
        titleHref: '/clients',
        breadcrumbItem: 'Listar clientes',
      }}
    >
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <TableContainer
                moduleName="clients"
                columns={buildColumns(user?.is_admin, handleDeleteClientModal)}
                customPageSize={size}
                currentPage={page}
                data={data.items}
                loading={isFetching}
                totalItems={data.totalItems}
                customElement={
                  <Button type="button" color="primary" outline onClick={handleExportData}>
                    Exportar dados
                  </Button>
                }
                deleteModal={{
                  isOpen: deleteModal,
                  loading: isFetching,
                  onDeleteClick: deleteMutation.mutate,
                  onCloseClick: () => setDeleteModal(false),
                }}
                handleFilters={setFilters}
                setSearchParams={setSearchParams}
                refetch={refetch}
                isAddRegister
                isPagination
                isShowingPageLength
                isDelete
                handleAddRegisterClick={() => router.navigate('/clients/create')}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(Clients);
