import React from 'react';

import PartnersUpdate from '@modules/Partners/pages/update';
import PartnersDocumentsCreate from '@modules/Partners/submodules/Documents/pages/create';
import PartnersDocumentsUpdate from '@modules/Partners/submodules/Documents/pages/update';

export const partnerDocumentsRoutes = [
  { path: '/partners/:partnerID/documents', component: <PartnersUpdate activePath="documents" /> },
  {
    path: '/partners/:partnerID/documents/create',
    component: <PartnersDocumentsCreate activePath="documents" />,
  },
  {
    path: '/partners/:partnerID/documents/:documentID',
    component: <PartnersDocumentsUpdate activePath="documents" />,
  },
];
