import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Modal, ModalBody, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { ColumnFiltersState } from '@tanstack/react-table';
import { useMutation, useQuery } from '@tanstack/react-query';

import { IWithRouter } from '@apptypes/WithRouter';
import FileDropzone from '@common/FileDropzone';
import { Page } from '@common/Page';
import Spinners from '@common/Spinner';
import TableContainer from '@common/TableContainer';
import withRouter from '@common/withRouter';
import { buildColumns } from '@modules/Partners/submodules/PaymentFiles/components/Table/constants';
import { create, deleteByID, getAll } from '@modules/Partners/submodules/PaymentFiles/services';
import { CreatePartnerPaymentFile } from '@modules/Partners/submodules/PaymentFiles/types';

const PaymentFiles = ({ router, page, size, setSearchParams }: IWithRouter) => {
  const { partnerID, partnerPaymentID } = useParams();

  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const [upload, setUpload] = useState<boolean>(false);
  const [uploadFile, setUploadFile] = useState<any>();
  const [file, setFile] = useState<any>();
  const [fileType, setFileType] = useState<any>(null);
  const { user }: any = useSelector((state: any) => state.Profile);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['payment', partnerPaymentID, page, size, filters],
    queryFn: () => getAll(partnerPaymentID as string, page, size, filters),
    initialData: { items: [], totalItems: 0 },
    retry: 1,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (payload: CreatePartnerPaymentFile) =>
      create((partnerPaymentID as string) || '', payload),
    onSuccess: () => {
      toast.success('Upload realizado com sucesso!', {
        theme: 'colored',
        autoClose: 1500,
      });

      refetch();

      setUpload(false);
      setUploadFile(undefined);
    },
    onError: () => {
      toast.error('Não foi possível realizar o upload do arquivo!', {
        theme: 'colored',
        autoClose: 3000,
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: () => deleteByID(deleteFileID || ''),
    onSuccess: () => {
      toast.success('Arquivo deletado com sucesso!', {
        theme: 'colored',
        autoClose: 1500,
      });
      refetch();
      setSearchParams({ page: '1' });
      setDeleteModal(false);
    },
  });

  const handleUpload = () => {
    mutate({
      partner_payment_id: partnerPaymentID,
      ...uploadFile,
    });
  };

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteFileID, setDeleteFileID] = useState<string | undefined>();

  const handleDeleteFilesModal = (fileID: string) => {
    setDeleteFileID(fileID);
    setDeleteModal(true);
  };

  return (
    <Page
      title="Contratos"
      breadcrumb={{
        title: 'Pagamentos do acessor',
        titleHref: `/partners/${partnerID}/payments`,
        breadcrumbItem: 'Listar comprovantes do acessor',
      }}
    >
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <TableContainer
                moduleName="partner-payment-files"
                columns={buildColumns(user?.is_admin, setFile, setFileType, handleDeleteFilesModal)}
                customPageSize={size}
                currentPage={page}
                data={data.items}
                loading={isFetching}
                totalItems={data.totalItems}
                deleteModal={{
                  isOpen: deleteModal,
                  loading: isFetching,
                  onDeleteClick: deleteMutation.mutate,
                  onCloseClick: () => setDeleteModal(false),
                }}
                handleFilters={setFilters}
                setSearchParams={setSearchParams}
                refetch={refetch}
                isAddRegister
                isPagination
                isShowingPageLength
                isDelete
                handleAddRegisterClick={() => {
                  setUpload(true);
                  setUploadFile(undefined);
                }}
              />

              {upload && (
                <Modal isOpen={upload} toggle={() => setUpload(false)} centered={true}>
                  <ModalBody className="px-4 py-5 text-center">
                    <button
                      type="button"
                      onClick={() => setUpload(false)}
                      className="btn-close position-absolute end-0 top-0 m-3"
                    ></button>

                    {uploadFile ? (
                      <>
                        <div className="font-size-16">{uploadFile.name}</div>

                        {isPending ? (
                          <div className="d-flex mt-4 flex-column align-items-center justify-content-center">
                            <Spinners />
                            <p className="mt-2">Realizando upload...</p>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center mt-4">
                            <Button color="danger" outline onClick={() => setUploadFile(undefined)}>
                              Cancelar
                            </Button>
                            <Button color="primary" className="ms-4" onClick={handleUpload}>
                              Realizar upload
                            </Button>
                          </div>
                        )}
                      </>
                    ) : (
                      <FileDropzone allowedFiles="PDF" onFileDrop={setUploadFile} />
                    )}
                  </ModalBody>
                </Modal>
              )}

              {file && (
                <Modal
                  className="pdf-modal"
                  isOpen={file}
                  toggle={() => setFile(null)}
                  centered={true}
                >
                  <ModalBody className="px-4 py-5 text-center">
                    <button
                      type="button"
                      onClick={() => setFile(null)}
                      className="btn-close position-absolute end-0 top-0 m-3"
                    ></button>
                    {fileType.toUpperCase() === 'PDF' ? (
                      <iframe
                        title="pdf"
                        src={file}
                        style={{ height: '700px', width: '100%' }}
                      ></iframe>
                    ) : (
                      <img
                        src={file}
                        alt="Imagem"
                        style={{ maxHeight: '700px', maxWidth: '100%' }}
                      />
                    )}
                  </ModalBody>
                </Modal>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(PaymentFiles);
