import React from 'react';
import PartnersAddressesCreate from '@modules/Partners/submodules/Addresses/pages/create';
import PartnersAddressesUpdate from '@modules/Partners/submodules/Addresses/pages/update';
import PartnersUpdate from '@modules/Partners/pages/update';

export const partnerAddressesRoutes = [
  { path: '/partners/:partnerID/addresses', component: <PartnersUpdate activePath="addresses" /> },
  {
    path: '/partners/:partnerID/addresses/create',
    component: <PartnersAddressesCreate activePath="addresses" />,
  },
  {
    path: '/partners/:partnerID/addresses/:addressID',
    component: <PartnersAddressesUpdate activePath="addresses" />,
  },
];
