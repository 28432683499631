import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import moment from 'moment';

import TableActions from '@common/TableActions';

export const buildColumns = (
  isAdmin: boolean,
  partnerID: string,
  onDeleteModal: (data: string) => void,
) => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('zipcode', {
      header: 'CEP',
      size: 5,
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/partners/${partnerID}/addresses/${row.original.id}`} className="text-dark">
            {getValue()}
          </Link>
        </h5>
      ),
    }),
    columnHelper.accessor('country', {
      header: 'País',
      size: 5,
      cell: (data: any) => <p className="text-muted mb-0">{data.getValue()}</p>,
    }),
    columnHelper.accessor('state', {
      header: 'Estado',
      size: 5,
      cell: (data: any) => <p className="text-muted mb-0">{data.getValue()}</p>,
    }),
    columnHelper.accessor('city', {
      header: 'Cidade',
      size: 10,
      cell: (data: any) => <p className="text-muted mb-0">{data.getValue()}</p>,
    }),
    columnHelper.accessor('neighbourhood', {
      header: 'Bairro',
      size: 10,
      cell: (data: any) => <p className="text-muted mb-0">{data.getValue()}</p>,
    }),
    columnHelper.accessor('street', {
      header: 'Rua',
      size: 20,
      cell: (data: any) => <p className="text-muted mb-0">{data.getValue()}</p>,
    }),
    columnHelper.accessor('number', {
      header: 'Número',
      size: 5,
      cell: (data: any) => <p className="text-muted mb-0">{data.getValue()}</p>,
      meta: {
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('complement', {
      header: 'Complemento',
      size: 10,
      cell: (data: any) => <p className="text-muted mb-0">{data.getValue()}</p>,
    }),
    columnHelper.accessor('created_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Data de criação',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('updated_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Última atualização',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('actions', {
      header: 'Ação',
      enableSorting: false,
      size: 10,
      cell: ({ row }: any) => (
        <TableActions
          isAdmin={isAdmin}
          editLink={`/partners/${partnerID}/addresses/${row.original.id}`}
          itemID={row.original.id}
          onDeleteModal={onDeleteModal}
        />
      ),
      meta: {
        disableFilter: true,
      },
    }),
  ];
};
