import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Spinners from './Spinner';

interface IDeleteModal {
  show?: boolean;
  loading?: boolean;
  onDeleteClick?: () => void;
  onCloseClick?: () => void;
}

const DeleteModal = ({ show, loading = false, onDeleteClick, onCloseClick }: IDeleteModal) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          />
          {loading ? (
            <>
              <Spinners />
              <p className="text-muted font-size-16 mt-4">
                O registro está sendo deletado, aguarde...
              </p>
            </>
          ) : (
            <>
              <div className="avatar-sm mb-4 mx-auto">
                <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                  <i className="mdi mdi-trash-can-outline"></i>
                </div>
              </div>
              <p className="text-muted font-size-16 mb-4">
                Você vai deletar esse registro, tem certeza disso?
              </p>

              <div className="hstack gap-2 justify-content-center mb-0">
                <button type="button" className="btn btn-danger" onClick={onDeleteClick}>
                  Deletar Agora!
                </button>
                <button type="button" className="btn btn-primary" onClick={onCloseClick}>
                  Cancelar
                </button>
              </div>
            </>
          )}
        </ModalBody>
      </div>
    </Modal>
  );
};

export default DeleteModal;
