import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  user: {},
  users: [],
  error: null,
  loading: false,
  wasEdited: false,
};

const Profile = createSlice({
  name: 'Users',
  initialState,
  reducers: {
    apiError(state, action) {
      state.user = {};
      state.users = [];
      state.error = action.payload;
      state.loading = false;
    },
    getAuthProfile(state, action) {
      state.error = null;
      state.loading = false;
      state.user = action.payload;
      state.wasEdited = false;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    clearAuthProfile(state) {
      state.user = {};
      state.wasEdited = false;
    },
    updateUserMetadata(state, action) {
      state.user = {
        ...state.user,
        metadata: action.payload,
      };
    },
  },
});

export const { apiError, clearAuthProfile, getAuthProfile, setLoading, updateUserMetadata } =
  Profile.actions;
export default Profile.reducer;
