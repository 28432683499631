import React from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IRouter } from '@apptypes/WithRouter';
import Spinners from '@common/Spinner';
import { REDIRECT_TIMEOUT } from '@helpers/constants';
import ContractForm from '@modules/Contract/components/ContractForm';
import { CLIENT_MANDATORY, PROFITABILITY_GROUP_MANDATORY } from '@modules/Contract/constants';
import { update } from '@modules/Contract/services';
import { getByID as getClientByID } from '@modules/Client/services';
import { getByID as getProfitabilityGroupByID } from '@modules/ProfitabilityGroup/services';
import { IContractCreate, IContractCreateForm } from '@modules/Contract/types';

const ContractsInfo = ({
  router,
  contract,
  contractID,
}: {
  contractID: string;
  contract?: IContractCreate;
  router: IRouter;
}) => {
  const { data: profitabilityGroup, isFetching: profitabilityGroupLoading } = useQuery({
    queryKey: ['profitabilityGroups', contract?.profitability_group_id],
    queryFn: () => getProfitabilityGroupByID(contract?.profitability_group_id || ''),
    retry: 1,
  });

  const { data: client, isFetching: clientLoading } = useQuery({
    queryKey: ['client', contract?.client_id],
    queryFn: () => getClientByID(contract?.client_id || ''),
    retry: 1,
  });

  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IContractCreate) => update(contractID, payload),
    onSuccess: () => {
      setTimeout(() => {
        router.navigate(`/contracts/${contractID}`);
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      client: {
        value: client?.id || '',
        label: client?.name || '',
      },
      profitabilityGroup: {
        value: profitabilityGroup?.id || '',
        label: `${profitabilityGroup?.description} - ${
          profitabilityGroup?.percentage
            ? String(profitabilityGroup?.percentage).replace('.', ',')
            : 0
        }%`,
      },
      active: String(contract?.active),
      recurring_withdrawal: String(contract?.recurring_withdrawal),
      description: contract?.description || '',
    },
    validationSchema: Yup.object({
      client: Yup.object().required(CLIENT_MANDATORY),
      profitabilityGroup: Yup.object().required(PROFITABILITY_GROUP_MANDATORY),
      describe: Yup.string(),
    }),
    onSubmit: async (payload: IContractCreateForm) => {
      mutate({
        client_id: payload.client.value,
        profitability_group_id: payload.profitabilityGroup.value,
        active: payload.active === 'true',
        recurring_withdrawal: payload.recurring_withdrawal === 'true',
        description: payload.description,
      });
    },
  });

  const isLoading = isPending || clientLoading || profitabilityGroupLoading;

  return (
    <>
      {isLoading ? (
        <Spinners />
      ) : (
        <ContractForm
          validation={validation}
          registrationError={error}
          showAlert={isSuccess}
          type="edit"
        />
      )}
    </>
  );
};

export default ContractsInfo;
