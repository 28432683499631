import React from 'react';
import ClientAddressesCreate from '@modules/Client/submodules/Addresses/pages/create';
import ClientAddressesUpdate from '@modules/Client/submodules/Addresses/pages/update';
import ClientUpdate from '@modules/Client/pages/update';

export const clientAddressesRoutes = [
  { path: '/clients/:clientID/addresses', component: <ClientUpdate activePath="addresses" /> },
  {
    path: '/clients/:clientID/addresses/create',
    component: <ClientAddressesCreate activePath="addresses" />,
  },
  {
    path: '/clients/:clientID/addresses/:addressID',
    component: <ClientAddressesUpdate activePath="addresses" />,
  },
];
